import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";

const DisclaimerModal = ({ disclaimer, setDisclaimer }: any) => {
  const history = useHistory();

  const handleClose = () => {
    setDisclaimer(false);
    localStorage.clear();
    history.push("/signin");
  };

  return (
    <>
      <Dialog
        open={disclaimer}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "If an account exists, you will receive email to reset the password."
          }
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisclaimerModal;
