import { server_url } from "../../types/constants";
import axios from "axios";
import { initialStateSurvey } from "../../redux/slices/surveySlice";

export async function loadFilterQuestionsApi(
  access_token: string,
  uuid: string
) {
  const url = `${server_url}/survey/filter/${uuid}/`;
  try {
    const response = await axios.get<initialStateSurvey>(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
