import { server_url } from "../../types/constants";
import axios from "axios";

export async function changeStatusSurveyApi(
  access_token: string,
  uuid: string,
  survey_status: string
) {
  const url = `${server_url}/survey/${uuid}/`;
  try {
    const response = await axios.post(
      url,
      {
        survey_status: survey_status,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
