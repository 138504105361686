import React, { useEffect, useState } from "react";
import { ReportChildProps } from "../ReportChildProps.props";
import { TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableContainer: {},
}));

const TotalInfoTable = (props: ReportChildProps) => {
  const classes = useStyles();
  const { reports, domainAndQuestionCount } = props;
  const [domainAndQuestionCount1, setDomainAndQuestionCount] = useState(
    new Map<string, number>()
  );
  const domainArrayCount: number[] = [];
  const domainsArray: string[] = [];
  useEffect(() => {
    setDomainAndQuestionCount(domainAndQuestionCount);
  }, [reports]);
  domainAndQuestionCount1.forEach((value, key) => {
    domainArrayCount.push(value);
    domainsArray.push(key);
  });
  return (
    <TableContainer
      className={classes.tableContainer}
      key={`domainQuestiontable`}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Domain name</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Total controls</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Compliant</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Non-compliant</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domainsArray.map((domain, index) => (
            <TableRow key={`${domain}${index}totable`}>
              <TableCell>{domain}</TableCell>
              <TableCell align="center">{domainArrayCount[index]}</TableCell>
              <TableCell align="center">
                {
                  reports.filter(
                    (report) =>
                      report.domain === domain && report.response === "FI"
                  ).length
                }
              </TableCell>
              <TableCell align="center">
                {reports.filter(
                  (report) =>
                    report.domain === domain && report.response === "PI"
                ).length +
                  reports.filter(
                    (report) =>
                      report.domain === domain && report.response === "NI"
                  ).length}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TotalInfoTable;
