import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { downloadDocument } from "../../api/documentManagement/downloadDocument";

export const documentDownloadStatus = createAsyncThunk(
  "/deletedocumentStatus",
  async ({file_name, user_id}: any, thunkAPI) => {
    try {
      return await downloadDocument(file_name,user_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const downloadDoc = createSlice({
  name: "docDownload",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(documentDownloadStatus.fulfilled, (state, { payload }) => {
      state.value = '';
      
    });
  },
});
export default downloadDoc.reducer;