import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useAppDispatch } from "../../redux/store";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { newRiskGroupAdd } from "../../redux/slices/addRiskGroupSlice";
import AddDialog from "../UsersProfile/AddDialog";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 500,
    paddingLeft: 70,
    paddingRight: 70,
    paddingTop: 50,
    paddingBottom: 50,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  textFieldTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#2C2C2C",
  },
  textFieldSmall: {
    flex: 1,
    maxWidth: 250,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  btnDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 55,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 59,
    // maxWidth: 237,
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 59,
    // maxWidth: 237,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AddRiskGroup = ({ addGroupState, setAddGroupState }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const access_token = localStorage.getItem("Access_Token");

  const [riskGroupName, setriskGroupName] = useState("");
  const [errorState, setErrorState] = useState(false);

  const handleCloseModal = () => {
    setAddGroupState(false);
  };

  const handleCancel = () => {
    setAddGroupState(false);
    setriskGroupName("");
  };

  const handleValidate = () => {
    if (!riskGroupName) {
      setErrorState(true);
      setErrorState(false);
    } else {
      const payload = {
        name: riskGroupName,
        access_token: access_token,
      };
      if (payload) {
        dispatch(newRiskGroupAdd(payload));
      }
      setAddGroupState(false);
      setriskGroupName("");
      setErrorState(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={addGroupState}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.titleCenter}>
              <Typography className={classes.profileTitle}>
                Add New Risk Group
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.textFieldTitle}>
                Risk Group Name
              </Typography>
              <TextField
                className={classes.textFieldSmall}
                required
                id="outlined-required"
                label="Enter risk name"
                variant="outlined"
                onChange={(e) => setriskGroupName(e.target.value)}
                error={errorState}
                helperText={errorState ? "Please fill this field" : null}
              />
            </div>
            <div className={classes.btnDiv}>
              <Button className={classes.saveBtn} onClick={handleValidate}>
                SAVE
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancel}>
                CANCEL
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddRiskGroup;
