import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { editControl } from "../../api/remediation/editControl";

export const controlEdit = createAsyncThunk(
  "control/editControl",
  async (
    {
      access_token,
      id,
      risk,
      control_group_name,
      control_name,
      mitigation,
      Status,
      due_date,
      control_owner,
      closed_date,
      comments,
      justification,
    }: any,
    thunkAPI
  ) => {
    try {
      return await editControl(
        access_token,
        id,
        risk,
        control_group_name,
        control_name,
        mitigation,
        Status,
        due_date,
        control_owner,
        closed_date,
        comments,
        justification
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  Status: string;
  closed_date: string;
  comments: string;
  control_group_name: string;
  control_name: string;
  control_owner: number;
  created_date: string;
  due_date: string;
  id: number;
  justification: string;
  mitigation: string;
  risk: number;
  risk_name: string;
  risk_rating: string;
  risk_response: string;
  control_owner_name: string;
  risk_uuid: string;
};

const initialState: initialStateUsersList = {
  Status: "",
  closed_date: "",
  comments: "",
  control_group_name: "",
  control_name: "",
  control_owner: 0,
  created_date: "",
  due_date: "",
  id: 0,
  justification: "",
  mitigation: "",
  risk: 0,
  risk_name: "",
  risk_rating: "",
  risk_response: "",
  control_owner_name: "",
  risk_uuid: "",
};

const editControlEdit = createSlice({
  name: "editControl",
  initialState,
  reducers: {
    controlEditInit: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(controlEdit.fulfilled, (state, { payload }) => {
      state.closed_date = payload.closed_date;
      state.comments = payload.comments;
      state.control_group_name = payload.con;
      state.control_name = payload.control_name;
      state.control_owner = payload.control_owner;
      state.created_date = payload.created_date;
      state.due_date = payload.due_date;
      state.id = payload.id;
      state.justification = payload.justification;
      state.mitigation = payload.mitigation;
      state.risk = payload.risk;
      state.risk_name = payload.risk_name;
      state.risk_rating = payload.risk_rating;
      state.risk_response = payload.risk_response;
      state.control_owner_name = payload.control_owner_name;
      state.risk_uuid = payload.risk_uuid;
      state.Status = payload.Status;
    });
  },
});

export const { controlEditInit } = editControlEdit.actions;

export default editControlEdit.reducer;
