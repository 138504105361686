import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addSoaUser } from "../../api/addUser/addNewSoaUser";

export const newSoaUser = createAsyncThunk(
  "/rm/adduser/",
  async (
    {
      access_token,
      first_name,
      last_name,
      title
    }: any,
    thunkAPI
  ) => {
    try {
      return await addSoaUser(
        access_token,
        first_name,
        last_name,
        title
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateAddSoaUser = {
//   id: number;
};

const initialState: initialStateAddSoaUser = {
//   id: 0,
};

const SoaUserCreate = createSlice({
  name: "addSoaUser",
  initialState,
  reducers: {
    resetAddSoaUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newSoaUser.fulfilled, (state, { payload }) => {
    //   state.id = payload.id;
    });
  },
});

export const { resetAddSoaUser } = SoaUserCreate.actions;

export default SoaUserCreate.reducer;
