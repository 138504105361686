import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { usersListFetch } from "../../redux/slices/usersListSlice";
import { RootState } from "../../redux/rootReducer";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableRowComponent from "./TableRow";
import HeaderProfile from "../../layout/HeaderProfile";
import AddDialog from "./AddDialog";
import { userslist as IusersList } from "../../redux/slices/usersListSlice";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    maxWidth: "100%",
  },
  root: {
    background: "#FFFFFF",
    marginBottom: 100,
  },
  title: {
    flexGrow: 1,
  },
  body: {
    marginTop: 60,
    marginBottom: 40,
    marginRight: 100,
    marginLeft: 100,
  },
  formControl: {
    padding: theme.spacing(1, 0, 0, 0),
    margin: theme.spacing(1, 2, 1, 2),
    minWidth: 200,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: 700,
    marginBottom: 30,
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 50,
    alignItems: "center",
  },
  filterDiv: {
    display: "flex",
    alignItems: "center",
  },
  inputLabel: {
    margin: theme.spacing(0, 0, 0, 0),
    fontSize: 16,
  },
  select: {
    height: 40,
    fontSize: 16,
    borderRadius: 8,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid lightgrey",
      padding: theme.spacing(1.5, 3, 1.5, 2),
      fontWeight: "bold",
      fontSize: 18,
    },
    body: {
      fontSize: 16,
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      padding: theme.spacing(1, 1, 1, 1),
    },
  })
)(TableCell);

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 40,
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const UsersProfile = () => {
  const classes = useStyles();
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const usersList = useSelector(
    (state: RootState) => state.usersList.allUsersList
  );
  const userInfoUpdated = useSelector(
    (state: RootState) => state.updateUserInfo
  );

  const updateUserList = useSelector((state: RootState) => state.addNewUser);

  const logstate = useSelector((state: RootState) => state);

  const [userRole, setUserRole] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [editState, setEditState] = useState(false);
  const [filteredUserList, setFilteredUserList] = useState<IusersList[]>([]);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("first_name");

  type Order = "asc" | "desc";

  interface Data {
    first_name: string;
    last_name: string;
    title: string;
    email: string;
    role: string;
    is_active: boolean;
  }

  useEffect(() => {
    dispatch(usersListFetch({ access_token }));
  }, [
    userInfoUpdated.user_id,
    userInfoUpdated.title,
    userInfoUpdated.role,
    userInfoUpdated.is_active,
  ]);

  useEffect(() => {
    dispatch(usersListFetch({ access_token }));
  }, [updateUserList.email]);

  useEffect(() => {
    dispatch(usersListFetch({ access_token }));
  }, []);

  useEffect(() => {
    setFilteredUserList(usersList);
  }, [usersList]);

  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserRole(event.target.value as string);
    if (event.target.value) {
      const temp = usersList.filter(
        (value) => value.role === event.target.value
      );
      if (temp.length && userStatus === "Active") {
        const final = temp.filter((value) => value.is_active === true);
        setFilteredUserList(final);
      } else if (temp.length && userStatus === "Disabled") {
        const final = temp.filter((value) => value.is_active === false);
        setFilteredUserList(final);
      } else {
        setFilteredUserList(temp);
      }
    } else {
      if (userStatus === "Active") {
        const temp = usersList.filter((value) => value.is_active === true);
        setFilteredUserList(temp);
      } else if (userStatus === "Disabled") {
        const temp = usersList.filter((value) => value.is_active === false);
        setFilteredUserList(temp);
      } else {
        setFilteredUserList(usersList);
      }
    }
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserStatus(event.target.value as string);
    if (event.target.value === "Active") {
      const temp = usersList.filter((value) => value.is_active === true);
      if (temp.length && userRole) {
        const final = temp.filter((value) => value.role === userRole);
        setFilteredUserList(final);
      } else if (temp.length) {
        setFilteredUserList(temp);
      }
    } else if (event.target.value === "Disabled") {
      const temp = usersList.filter((value) => value.is_active === false);
      if (temp.length && userRole) {
        const final = temp.filter((value) => value.role === userRole);
        setFilteredUserList(final);
      } else if (temp.length) {
        setFilteredUserList(temp);
      }
    } else {
      if (userRole) {
        const temp = usersList.filter((value) => value.role === userRole);
        setFilteredUserList(temp);
      } else {
        setFilteredUserList(usersList);
      }
    }
  };

  const handleAddNewUser = () => {
    setEditState(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    order: Order;
    orderBy: string;
  }

  interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    { id: "first_name", numeric: false, label: "First Name" },
    { id: "last_name", numeric: false, label: "Second Name" },
    { id: "title", numeric: false, label: "Title" },
    { id: "email", numeric: false, label: "Email address" },
    { id: "role", numeric: false, label: "Access level" },
    { id: "is_active", numeric: false, label: "Account status" },
    // { id: "", numeric: false, label: "" },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              align={"left"}
              // padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell></StyledTableCell>
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <HeaderProfile />
          <div className={classes.body}>
            <Typography className={classes.profileTitle}>
              Users profile
            </Typography>

            <div className={classes.filterContainer}>
              <div className={classes.filterDiv}>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    marginRight: 20,
                    marginTop: 8,
                  }}
                >
                  Filter by
                </Typography>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.inputLabel}>Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={userStatus}
                    onChange={handleChangeStatus}
                    label="Status"
                    className={classes.select}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Disabled"}>Disabled</MenuItem>
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.inputLabel}>Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-
                        
                    le-select-outlined"
                    value={userRole}
                    onChange={handleChangeRole}
                    label="Role"
                    className={classes.select}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    <MenuItem value={"Administrator"}>Administrator</MenuItem>
                    <MenuItem value={"Risk manager"}>Risk manager</MenuItem>
                    <MenuItem value={"Risk owner"}>Risk owner</MenuItem>
                    <MenuItem value={"Control owner"}>Control owner</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <ColorButton
                variant="contained"
                color="primary"
                onClick={() => handleAddNewUser()}
              >
                Add new user
              </ColorButton>
            </div>

            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {filteredUserList && filteredUserList.length
                    ? stableSort(
                        filteredUserList,
                        getComparator(order, orderBy)
                      ).map((row, key) => (
                        <TableRowComponent
                          row={row}
                          key={key}
                          userInfoUpdated={userInfoUpdated}
                        />
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ marginLeft: 100 }}>
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => handleAddNewUser()}
            >
              Add new user
            </ColorButton>
          </div>
          <AddDialog setEditState={setEditState} editState={editState} />
        </div>
      </ThemeProvider>
    </>
  );
};

export default UsersProfile;
