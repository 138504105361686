import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRiskAdded } from "../../api/risk/riskAdded";

export const riskAddedStatus = createAsyncThunk(
  "/getRiskAddedStatus",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getRiskAdded(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateRiskAdded = {
  new_risk_added: boolean;
};

const initialState: initialStateRiskAdded = {
  new_risk_added: false,
};

const riskStatusAdd = createSlice({
  name: "newRiskAddedStatus",
  initialState,
  reducers: {
    resetRiskAddedStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(riskAddedStatus.fulfilled, (state, { payload }) => {
      state.new_risk_added = payload.new_risk_added;
    });
  },
});

export const { resetRiskAddedStatus } = riskStatusAdd.actions;

export default riskStatusAdd.reducer;
