import { server_url } from "../../types/constants";
import axios from "axios";

export async function addSoaUser(access_token:string,
    first_name: string,
    last_name: string, title: string,) {
  const url = `${server_url}/rm/adduser/`;
  try {
    const response = await axios({
      method: "post",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data:{
        title: title,
        first_name: first_name,
        last_name: last_name,
      }
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
