import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as LinkRouter, useHistory, useParams } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../assets/4CISO.png";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DrawerComponent from "./DrawerComponent";

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: theme.spacing(11),
    maxWidth: theme.spacing(11),
    marginTop: theme.spacing(2),
  },
  logo: {
    flex: 1,
  },
  header: {
    flex: 1,
  },
  menuButton: {
    maxHeight: theme.spacing(11),
    maxWidth: theme.spacing(11),
  },
  icon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    color: "#1071BA",
  },
}));

const HeaderProfile = ({ isFinishedFirstCore, appBar }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  return (
    <>
      <div>
        <div className={classes.header}>
          <Toolbar>
            <div className={classes.logo}>
              {!appBar && (
                <img style={{cursor:"pointer"}} src={logo} alt="Logo" className={classes.image} onClick={()=>history.push("/")} />
              )}
            </div>
            {history.location.pathname !== "/signin" &&
              history.location.pathname !== "/signin/" &&
              history.location.pathname !== "/signup" &&
              history.location.pathname !== "/signup/" && (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon className={classes.icon} />
                </IconButton>
              )}
          </Toolbar>
        </div>
        <div>
          <DrawerComponent
            open={open}
            handleClose={handleClose}
            isFinishedFirstCore={isFinishedFirstCore}
            appBar={true}
            useOutsideAlerter={useOutsideAlerter}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderProfile;
