import { server_url } from "../../types/constants";
import axios from "axios";

export async function createToken(email: string) {
  const url = `${server_url}/authentication/create_token`;
  try {
    const response = await axios({
      method: "post",
      url: url,
      data: {
        email: email,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function addUser(
  email: string,
  username: string,
  first_name: string,
  last_name: string,
  password: string,
  organization: string,
  title: string,
  role: string,
  isAllowedPromotion: boolean,
  registration_token: string,
  is_active: boolean,
  client_id: number
) {
  const url = `${server_url}/authentication/register/`;
  try {
    const response = await axios({
      method: "post",
      url: url,
      data: {
        email: email,
        username: username,
        first_name: first_name,
        last_name: last_name,
        password: password,
        organization: organization,
        title: title,
        role: role,
        isAllowedPromotion: isAllowedPromotion,
        registration_token: registration_token,
        is_active: is_active,
        client_id,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
