import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link as LinkRouter, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Copyright from "./Copyright";
import { signUpUser } from "../redux/slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../redux/store";
import * as yup from "yup";
import lineBackground from "../assets/lineBackground.svg";
import { RegistrationToken } from "../types/survey";

const useStyles = makeStyles((theme) => ({
  divContainer: {
    backgroundImage: `url(${lineBackground})`,
    backgroundRepeat: "no-repeat",
    minHeight: "74vh",
  },
  signInContainer: {
    marginTop: theme.spacing(8),
    background: "rgba(255, 255, 255, 0.6)",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderRadius: "8px",
    border: "1px solid white",
  },
  signUpText: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  checkbox: {
    alignSelf: "flex-start",
    padding: "2px 10px 10px 10px",
  },
  accountText: {
    marginTop: theme.spacing(3),
  },
}));
const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(10, "Password should be of minimum 10 characters length")
    .required("Password is required")
    .matches(/[a-z]+/, "Password must contain one lowercase character")
    .matches(/[A-Z]+/, "Password must contain one uppercase character")
    .matches(/[@$!%*#?&]+/, "Password must contain one special character")
    .matches(/\d+/, "Password must contain one number"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function SignUp(props: any) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { registration_token } = useParams<RegistrationToken>();

  const query = props.location.search;
  const urlParams = new URLSearchParams(query);
  const userEmail: string = urlParams.get("email") || "";
  const client_id: string = urlParams.get("client_id") || "";

  const formik = useFormik({
    initialValues: {
      email: userEmail,
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      organization: "",
      title: "",
      role: "Administrator",
      isAllowedPromotion: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        signUpUser({
          email: values.email,
          password: values.password,
          first_name: values.firstName,
          last_name: values.lastName,
          organization: values.organization,
          title: values.title,
          role: values.role,
          client_id: parseInt(client_id),
          isAllowedPromotion: values.isAllowedPromotion,
          isTemporary: false,
          registration_token: registration_token,
        })
      )
        .then(unwrapResult)
        .then((response: any) => {
          if (
            typeof response !== "string" &&
            response.message &&
            (response.message.includes("rejected") ||
              response.message.includes("failed") ||
              response.message.includes("error"))
          ) {
            enqueueSnackbar("Email is invalid", {
              autoHideDuration: 1500,
              variant: "error",
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (response.isNew) {
              history.push("/welcome-page/");
            } else {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          if (error === undefined) {
            enqueueSnackbar("Network error", {
              autoHideDuration: 1500,
              variant: "error",
            });
          } else if (error.email) {
            for (let i = 0; i < error.email.length; i++) {
              enqueueSnackbar(error.email[i], {
                autoHideDuration: 1500,
                variant: "error",
              });
            }
          } else if (error.username) {
            for (let i = 0; i < error.username.length; i++) {
              enqueueSnackbar(error.username[i], {
                autoHideDuration: 1500,
                variant: "error",
              });
            }
          }
        });
    },
  });
  return (
    <>
      <div className={classes.divContainer}>
        <Container
          component="main"
          maxWidth="sm"
          className={classes.signInContainer}
        >
          <CssBaseline />
          <div className={classes.paper}>
            <Typography variant="h4" className={classes.signUpText}>
              <Box fontWeight={800}>Create your account</Box>
            </Typography>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    autoComplete="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoComplete="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="organization"
                    label="Organization"
                    autoComplete="organization"
                    value={formik.values.organization}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.organization &&
                      Boolean(formik.errors.organization)
                    }
                    helperText={
                      formik.touched.organization && formik.errors.organization
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    // className={classes.rolefield}
                  >
                    <InputLabel>Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      required
                      disabled
                      // onChange={formik.handleChange}
                      label="Role"
                      value={formik.values.role}
                      // className={classes.dropDownSelect}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                    >
                      <MenuItem value={"Administrator"}>Administrator</MenuItem>
                      <MenuItem value={"Risk manager"}>Risk manager</MenuItem>
                      <MenuItem value={"Risk owner"}>Risk owner</MenuItem>
                      <MenuItem value={"Control owner"}>Control owner</MenuItem>
                    </Select>
                    {formik.touched.title && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.title}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    type="email"
                    autoComplete="email"
                    disabled
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    // autoComplete="current-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    // autoComplete="current-password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="isAllowedPromotion"
                        value={formik.values.isAllowedPromotion}
                        onChange={formik.handleChange}
                        className={classes.checkbox}
                      />
                    }
                    label="We hate spam and we will not spam your mailbox. Subscribe if you want to stay informed about news and changes related to the application"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    className={classes.accountText}
                  >
                    {"Already have an account?   "}
                    <LinkRouter to="/signin">{"Sign In"}</LinkRouter>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </>
  );
}
