import { server_url } from "../../types/constants";
import axios from "axios";

export async function getDocument(
  access_token: string,
  applicability: string,
  type: string
) {
  //  const { } = docCredential.payload;
  //  console.log(access_token,"aaaaaaa");
  const url = `${server_url}/rm/filter_main/doc/?applicability=${applicability}&type=${type}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}
