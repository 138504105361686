import { server_url } from "../../types/constants";
import axios from "axios";

export async function updateProfile(
  access_token: string,
  title: string,
  password: string,
  is_admin: boolean,
  id: string
) {
  const url = `${server_url}/authentication/update-user-profile/${id}/`;
  try {
    const response = await axios({
      method: "put",
      url: url,
      data: {
        title: title,
        password: password,
        is_admin: is_admin,
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
