import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUser } from "../../api/addUser/addUser";

export const newUserAdd = createAsyncThunk(
  "user/addUser",
  async (
    {
      email,
      username,
      first_name,
      last_name,
      password,
      organization,
      title,
      role,
      isAllowedPromotion,
      registration_token,
      is_active,
      client_id,
    }: any,
    thunkAPI
  ) => {
    try {
      return await addUser(
        email,
        username,
        first_name,
        last_name,
        password,
        organization,
        title,
        role,
        isAllowedPromotion,
        registration_token,
        is_active,
        client_id
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  password: string;
  organization: string;
  title: string;
  role: string;
  isAllowedPromotion: boolean;
  registration_token: string;
  // error: string,
};

const initialState: initialStateUsersList = {
  email: "",
  username: "",
  first_name: "",
  last_name: "",
  password: "",
  organization: "",
  title: "",
  role: "",
  isAllowedPromotion: true,
  registration_token: "",
  // error:"",
};

const UserCreate = createSlice({
  name: "addNewUser",
  initialState,
  reducers: {
    newUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newUserAdd.fulfilled, (state, { payload }) => {
      state.email = payload.email;
      // state.error= payload.error
      // state.username= payload.username,
      // state.first_name= payload.,
      // state.last_name= payload.,
      // state.password= payload.,
      // state.organization= payload.,
      // state.title= payload.,
      // state.role= payload.,
      // state.isAllowedPromotion= true,
      // stateregistration_token= payload.,
    });
  },
});

export const { newUser } = UserCreate.actions;

export default UserCreate.reducer;
