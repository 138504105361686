import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Copyright from "./Copyright";
import { signInUser } from "../redux/slices/userSlice";
import { useAppDispatch } from "../redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import HeaderProfile from "../layout/HeaderProfile";
import lineBackground from "../assets/lineBackground.svg";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  divContainer: {
    backgroundImage: `url(${lineBackground})`,
    backgroundRepeat: "no-repeat",
  },
  mainContainer: {
    minHeight: "79vh",
  },
  signInContainer: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(20),
    background: "rgba(255,255,255,0.6)",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderRadius: "8px",
    border: "1px solid white",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFFFFF",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signInText: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
  },
  signUpText: {
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
  mainTextContainer: {
    display: "flex",
    alignItems: "center",
  },
  mainText: {
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function SignIn() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const history = useHistory();
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        signInUser({
          email: values.email,
          password: values.password,
        })
      )
        .then(unwrapResult)
        .then((response: any) => {
          console.log(response,"HHHHHHH")

          if (
            typeof response !== "string" &&
            response.message &&
            (response.message.includes("rejected") ||
              response.message.includes("failed"))
          ) {
            enqueueSnackbar("Email or password is incorrect", {
              autoHideDuration: 1500,
              key: 1,
              variant: "error",
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (response.isTemporary === true) {
              history.push("/changepassword");
            } else if (response.role === "Administrator" && response.isNew) {
              history.push("/welcome-page/");
            } else if (response.isTemporary === false) {
              history.push("/");
            } else {
              if (
                response.role === "Administrator" &&
                response.last_core_survey_uuid
              ) {
                history.push(`/core/${response.last_core_survey_uuid}/`);
              } else {
                enqueueSnackbar("Something went front", {
                  autoHideDuration: 1500,
                  key: 1,
                  variant: "error",
                });
              }
            }
          }
        })
        .catch((error) => {
          if (error === undefined) {
            enqueueSnackbar("Network error", {
              autoHideDuration: 1500,
              key: 1,
              variant: "error",
            });
          } else if (error.status && error.status === 400) {
            enqueueSnackbar(error.data.error, {
              autoHideDuration: 1500,
              key: 1,
              variant: "error",
            });
          }
        });
    },
  });
  return (
    <>
      <HeaderProfile />
      <div className={classes.divContainer}>
        <Container maxWidth="lg" className={classes.mainContainer}>
          <Grid container direction="row" justify="center" spacing={1}>
            <Grid item sm={6} className={classes.mainTextContainer}>
              <Hidden xsDown implementation="css">
                <Typography variant="h3" className={classes.mainText}>
                  <Box fontWeight={800}>
                    WE ARE GLAD <br />
                    TO SEE YOU AGAIN!
                  </Box>
                </Typography>
              </Hidden>
            </Grid>
            <Grid item sm={5}>
              <Container maxWidth="sm" className={classes.signInContainer}>
                <CssBaseline />
                <div className={classes.paper}>
                  <Typography variant="h4" className={classes.signInText}>
                    Sign in
                  </Typography>
                  <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <TextField
                      id="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Email Address"
                      autoComplete="email"
                      autoFocus
                      required
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      id="password"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign In
                    </Button>
                  </form>
                  <Typography
                    onClick={() => history.push("/forgotpassword")}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </Typography>
                </div>
              </Container>
            </Grid>
          </Grid>
        </Container>
        <Box mt={8}>
          <Copyright />
        </Box>
      </div>
    </>
  );
}
