import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allRiskMatrixGet } from "../../api/matrix/riskMatrix";

export const allRiskMatrixFetch = createAsyncThunk(
  "allmatrix/get",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await allRiskMatrixGet(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface riskMatrix {
  impact: string;
  impact_key: string;
  Likelihood: string;
  Likelihood_key: string;
  result: string;
}

export type initialStateMatrix = {
  //   matrix_choice: string;
  //   riskMatrixList: Array<riskMatrixList>;
  json_3_3: Array<riskMatrix>;
  json_4_4: Array<riskMatrix>;
  json_5_5: Array<riskMatrix>;
};

const initialState: initialStateMatrix = {
  //   matrix_choice: "",
  //   riskMatrixList: [],
  json_3_3: [],
  json_4_4: [],
  json_5_5: [],
};

const allmatrix = createSlice({
  name: "allRiskMatrixGet",
  initialState,
  reducers: {
    resetAllRiskMatrix: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(allRiskMatrixFetch.fulfilled, (state, { payload }) => {
      state.json_3_3 = [];
      state.json_4_4 = [];
      state.json_5_5 = [];
      payload?.json_3_3?.forEach((value: any) => {
        state.json_3_3.push({
          impact: value.impact,
          impact_key: value.impact_key,
          Likelihood: value.Likelihood,
          Likelihood_key: value.Likelihood_key,
          result: value.result,
        });
      });
      payload?.json_4_4?.forEach((value: any) => {
        state.json_4_4.push({
          impact: value.impact,
          impact_key: value.impact_key,
          Likelihood: value.Likelihood,
          Likelihood_key: value.Likelihood_key,
          result: value.result,
        });
      });
      payload?.json_5_5?.forEach((value: any) => {
        state.json_5_5.push({
          impact: value.impact,
          impact_key: value.impact_key,
          Likelihood: value.Likelihood,
          Likelihood_key: value.Likelihood_key,
          result: value.result,
        });
      });
    });
  },
});

export const { resetAllRiskMatrix } = allmatrix.actions;

export default allmatrix.reducer;
