import { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useAppDispatch } from "../../redux/store";
import AddUserDialog from "./AddUserDialog";
import { getAllSoaUsers } from "../../redux/slices/getSoaUserSlice";
import { getStatementRequest } from "../../redux/slices/getStatementSlice";
import {updateSoaContent} from "../../redux/slices/updateSoaContent";
import { unwrapResult } from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 13,
    width: "135px",
    cursor: "pointer",
    borderBottom: "1px solid #e5e5e5",
  },
  selectedBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 13,
    width: "135px",
    backgroundColor: "#1071BA",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  updateBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    padding: 13,
    width: "135px",
    backgroundColor: "#1071BA",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#1071BA",
      },
  },
  down: {
    display: "flex",
  },
  gridContainer: {
    margin: "30px 0",
    width: " 767px",
  },
  containerItem : {
    padding : "3px !important",
  },
  gridTitle: {
    margin: "0px 0",
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  select: {
    marginTop: "-1%",
  },
}));


const ContentData = ({ item }: any) => {

  const classes = useStyles();

  const [editState, setEditState] = useState(false);
  const [applicability, setApplicability] = useState(item.applicability);
  const [inclusion, setInclusion] = useState(item.source_for_inclusion);
  const [controlOwner, setControlOwner] = useState(item.control_owner);
  const [justifications,setJustifications]= useState(item.justification)
  const[comment, setComment] = useState(item.comments)
  const[appdocuments, setappdocuments] = useState(item.applicable_documents)
  const dispatch = useAppDispatch();
  
  const access_token = localStorage.getItem("Access_Token");
  const [controlStatus, setControlStatus] = useState(item.implementation_status);

  useEffect(() => {
    dispatch(getAllSoaUsers({ access_token }));
    
  }, [item]);

  const userList = useSelector(
    (state: RootState) => state.SoaUserList.get_soa_users
  );

  const handleChange = (event: any) => {
    setApplicability(event.target.value);
  };

  const handleChangeControlStatus = (event: any) => {
    setControlStatus(event.target.value);
  };

  const handleInclusionStatus = (event: any) => {
    setInclusion(event.target.value);
  };

  const handleControlOwnerStatus = (event: any) => {
    if (event.target.value === "Add New Control Owner") {
      setControlOwner(event.target.value);
      setEditState(true);
    } else {
      setControlOwner(event.target.value);
    }
  };

  const handleUpdate =  (item:any)=>{
      const {id,domain_name,question_id,main_title,control_id,control_name,control_description,user}= item

       dispatch(updateSoaContent({
        access_token:access_token,
        id:id,
        applicability:applicability,
        control_owner:controlOwner,
        implementation_status:controlStatus,
        domain_name:domain_name,
        question_id:question_id,
        main_title:main_title,
        control_id:control_id,
        control_name:control_name,
        control_description:control_description,
        justification:justifications,
        applicable_documents:appdocuments,
        comments:comment,
        user:user,
        source_for_inclusion:inclusion
     })).then(unwrapResult)
     .then((response: any) => {
        dispatch(getStatementRequest({access_token}));
     })
     .catch((error) => {
         console.log(error)
     }); 
  }

  const handleSelectControlOwner = (event: any) => {
    if (event?.target?.innerText === "Add New Control Owner") {
      setEditState(true);
    }
  };

  const handleJustification = (event:any)=>{
      setJustifications(event.target.value)
  }

  const handleComment = (event:any)=>{
      setComment(event.target.value)
  }

  const handleAppdocumentsChange =(event:any)=>{
      setappdocuments(event.target.value)
  }

  return (
    <div>
      <Grid container spacing={1} className={classes.gridTitle}>
        <Grid item xs={3}>
          <Typography className={classes.sectionTitle}>
            Control Title:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{item.control_name}</Typography>
        </Grid>
      </Grid>
  

      <Grid container className={classes.gridContainer} spacing={2}>
        <Grid item xs={3} className={classes.containerItem}>
          <Typography className={classes.sectionTitle}>
            Control Description:
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.containerItem}>
          <Typography>{item.control_description}</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.gridContainer} spacing={1}>
        <Grid item xs={3} >
          <Typography className={classes.sectionTitle}>
            Applicability
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.select}>
          <FormControl fullWidth>
            <InputLabel>Applicability</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={applicability}
              label="Applicability"
              onChange={handleChange}
            >
              <MenuItem value={"Applicable"}>Applicable</MenuItem>
              <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2} style={{ marginLeft: "6%" }}>
          <Typography className={classes.sectionTitle}>
            Control status
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.select}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Control status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={controlStatus}
              label="Control status"
              onChange={handleChangeControlStatus}
            >
              <MenuItem value={"Implemented"}>Implemented</MenuItem>
              <MenuItem value={"Not Implemented"}>Not Implemented</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} spacing={1}>
        <Grid item xs={3}>
          <Typography className={classes.sectionTitle}>
            Justification
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.select}>
          <TextField
            id="standard-multiline-static"
            multiline
            rows={4}
            value={justifications}
            variant="outlined"
            fullWidth
            onChange={handleJustification}
            // inputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} spacing={1}>
        <Grid item xs={3}>
          <Typography className={classes.sectionTitle}>
            Applicable document
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.select}>
          <TextField
            id="standard-multiline-static"
            multiline
            value={appdocuments}
            rows={4}
            variant="outlined"
            fullWidth
            onChange={handleAppdocumentsChange}
            // inputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} spacing={1}>
        <Grid item xs={3}>
          <Typography className={classes.sectionTitle}>
            Source for Inclusion
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.select}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Source for Inclusion
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inclusion}
              label="Source for Inclusion"
              onChange={handleInclusionStatus}
            >
              <MenuItem value={"Legal Requirement"} selected>
                Legal Requirement
              </MenuItem>
              <MenuItem value={"Contractual Requirement"}>
                Contractual Requirement
              </MenuItem>
              <MenuItem value={"Business Requirement"}>
                Business Requirement
              </MenuItem>
              <MenuItem value={"Best Practice"}>Best Practice</MenuItem>
              <MenuItem value={"Following Risk Assessment"}>
                Following Risk Assessment
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "6%" }}>
          <Typography className={classes.sectionTitle}>
            Control Owner
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.select}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Control Owner</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={controlOwner}
              label="Control Owner"
              onChange={handleControlOwnerStatus}
              onClick={handleSelectControlOwner}
            >
              <MenuItem value="Add New Control Owner">
                Add New Control Owner
              </MenuItem>
              {userList?.map((user: any, index: any) => (
                <MenuItem value={`${user.id}`} key={index}>
                  {user.first_name} {user.last_name} ,{user.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} spacing={1}>
        <Grid item xs={3}>
          <Typography className={classes.sectionTitle}>Comments</Typography>
        </Grid>
        <Grid item xs={9} className={classes.select}>
          <TextField
            id="standard-multiline-static"
            multiline
            rows={4}
            value={comment}
            variant="outlined"
            fullWidth
            onChange={handleComment}
            // inputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} spacing={1}>
      <Grid item xs={6}>
          <Button className={classes.updateBtn} onClick={()=>handleUpdate(item)}>Update</Button>
          </Grid>
      </Grid>
      <AddUserDialog
        setEditState={setEditState}
        editState={editState}
        addNewRiskOwner={false}
      />
    </div>
  );
};

export default ContentData;
