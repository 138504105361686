import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateProfile } from "../../api/updateProfile/updateProfile";

export const updateMyProfile = createAsyncThunk(
  "user/addUser",
  async ({ access_token, title, password, is_admin, id }: any, thunkAPI) => {
    try {
      return await updateProfile(access_token, title, password, is_admin, id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  error: boolean;
  status: string;
};

const initialState: initialStateUsersList = {
  error: false,
  status: "",
};

const profileUpdate = createSlice({
  name: "updatemyProfile",
  initialState,
  reducers: {
    myProfileUpdate: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateMyProfile.fulfilled, (state, { payload }) => {
      // state.error= payload.error,
      state.status = payload.status;
    });
  },
});

export const { myProfileUpdate } = profileUpdate.actions;

export default profileUpdate.reducer;
