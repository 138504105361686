import axios from "axios";
import { User } from "../../redux/slices/userSlice";
import { server_url } from "../../types/constants";

export async function signInApi(
  email: string,
  password: string
): Promise<User> {
  const url = `${server_url}/authentication/token/`;
  try {
    const signInResponse = await axios.post<User>(url, {
      username: email,
      password: password,
    });
    if (signInResponse.status >= 400) {
      throw signInResponse;
    }
    return signInResponse.data as User;
  } catch (err) {
    throw err.response;
  }
}
