import { useState, useEffect, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import edit from "../../assets/edit.svg";
import edit2 from "../../assets/ediit.svg";
import del from "../../assets/delete.svg";
import download from "../../assets/download.svg";
import {
  documentList,
  documentStatus,
} from "../../redux/slices/getDocumentManagementSlice";
import { documentDelStatus } from "../../redux/slices/documentDeleteSlice";
import { restoreOneRow } from "../../redux/slices/restoreRowSlice";
import { restoreMultipleRow } from "../../redux/slices/multipleRestoreSlice";
import { documentDownloadStatus } from "../../redux/slices/downloadDocSlice";
import { updateDocStatus } from "../../redux/slices/statusUpdateSlice";
import { downloadMultipleDoc } from "../../redux/slices/downloadSelDocSlice";
import { updateNameStatus } from "../../redux/slices/nameUpdateSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { RootState } from "../../redux/rootReducer";
import classNames from "classnames";
import { unwrapResult } from "@reduxjs/toolkit";
import { validateYupSchema } from "formik";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { eventFromMessage } from "@sentry/react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "3%",
    width: "95%",
    // height: "100%",
    height: "600px",
  },
  datagrid: {
    height: "64%",
    border: "none !important",
    "& .MuiDataGrid-windowContainer": {
      "& .MuiDataGrid-window": {
        overflowY: 'clip',
        overflowX: 'clip',
        "& .MuiDataGrid-dataContainer": {
          borderRight: '1px solid #EBEBEB',
        }
      }
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      "&  .MuiDataGrid-columnHeader": {
        paddingLeft: "0px",
      },
      "& .MuiDataGrid-columnHeader--alignRight MuiDataGrid-columnHeader--sortable MuiDataGrid-columnHeader--numeric MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-cell": {
          display: "none !important",
          minWidth: "0px !important",
          maxWidth: "0px !important",
          minHeight: "0px !important",
          maxHeight: "0px !important",
        }
      },
    },

    "& .MuiDataGrid-columnHeaderCheckbox": {
      display: "none",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "137%",
      color:"#000000",
    },
    " & .MuiDataGrid-cell": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "137% !important",
      color:"#000000",
      // borderLeft : "1px solid grey",
    },
    " & .MuiDataGrid-cellCheckbox": {
      borderRight: "1px solid #EBEBEB",
    },
    "& .MuiDataGrid-row": {
      borderLeft: "1px solid #EBEBEB",
      // borderRight: "1px solid #EBEBEB !important",
      // width: "100%",
    },
  },
  restoreBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "137%",
    color: "#1071BA",
  },
  actionbtn: {
    background: "#1071BA",
    border: "1px solid #1071BA",
    width: "200px",
    height: "54px",
    boxSizing: "border-box",
    borderRadius: "8px",
    color: "white",
  },
  dialogBox: {
    "& .MuiDialog-container": {
      height: '90% ',
      // "& .MuiDialog-paperWidthSm-321": {
      //   minWidth: '284px',
      //   minHeight: '248px',
      //   "& .MuiList-padding-331": {
      //     paddingTop: '24%',
      //     paddingLeft: '32%',
      //     fontSize: '20px',
      //   }
      // }
    }
  },
  modalBox: {
    width: 250,
    backgroundColor: '#FFFFFF',
    border: '2px solid #000',
    padding: '32px',
    marginTop: '23%',
    marginLeft: '50%',
    borderRadius: '8px',
    height: '220px',
    transform: 'translate(-50%, -50%)',
  },
  statusContainer : {
    paddingLeft: "30%",
    paddingTop: "19%",
  },
  setStatusBtn : {
    fontSize:"18px",
    color: "#000000",
    fontFamily:"Poppins",
    lineHeight:"150%",

  },
  iconContainer : {
    position:"relative",
  },
  statusChanger:{
    width: '170px',
    height: '138px',
    position:"absolute",
    // top: "300px !important",
    right:"9rem !important",
    backgroundColor: "white",
    zIndex: 999,
    border: "1px solid #D2D2D2",
    borderRadius: "8px",
  },
  changeStatusBtn : {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: "150%",
    color: "#000000",
  }
}));

const GridTable = ({ docCredential }: any) => {

  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isReset, setReset] = useState(false);
  const [isMultipleReset, setMultipleReset] = useState(false);

  const [questionId, setQuestionId] = useState<string[]>([]);
  const [multipleName, setMultipleName] = useState<string[]>([]);

  console.log(multipleName, 'xxxxxxxxxxxxx');
  const [ownerid, setOwnerId] = useState(" ");
  const token = localStorage.getItem("Access_Token");
  const [statusSuccess, setStatusSuccess] = useState(false);
  const [deleteRow, setDeleteRow] = useState({
    access_token: token,
    question_id: " ",
  });
  const [restoreRow, setRestoreRow] = useState({
    access_token: token,
    question_id: " ",
  });

  const [downloadDoc, setDownloadDoc] = useState({
    file_name: " ", // in filename now sending question id for every download button
    user_id: " ",
  });
  const [updateStatus, setUpdateStatus] = useState({
    access_token: token,
    question_id: " ",
    status: " ",
  });
  console.log(updateStatus, "dddddddddd"); // status is updating after clicking the dropdown
  useEffect(() => {
    if (downloadDoc.file_name.length > 1) {
      dispatch(documentDownloadStatus({ ...downloadDoc }));
    }
  }, [downloadDoc]);

  useEffect(() => {
    if (updateStatus.status.length > 2) {
      setStatusSuccess(false);
      dispatch(updateDocStatus({ ...updateStatus }))
        .then(unwrapResult)
        .then((response: any) => {
          setStatusSuccess(true);
        })
        .catch((err) => {
          setStatusSuccess(false);
        });
    }
  }, [updateStatus.status]);
  useEffect(() => {
    if (statusSuccess) {
      dispatch(documentStatus({ ...docCredential }));
      setOpen(0);
    }
  }, [statusSuccess]);

  useEffect(() => {
    if (deleteRow.question_id.length > 2) {
      setIsSuccess(false);
      console.log("delete dispatch is calling");
      dispatch(documentDelStatus({ ...deleteRow }))
        .then(unwrapResult)
        .then((response: any) => {
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsSuccess(false);
        });
    }
  }, [deleteRow]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (restoreRow.question_id.length > 2) {
      setReset(false);
      dispatch(restoreOneRow({ ...restoreRow }))
        .then(unwrapResult)
        .then((response: any) => {
          setReset(true);
        })
        .catch((err) => {
          setReset(false);
        });
    }
  }, [restoreRow]);
  useEffect(() => {
    if (isReset) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isReset]);

  const handleRestore = () => {
    const payload = {
      access_token: token,
      question_id: questionId,
    };
    if (payload) {
      setMultipleReset(false);
      dispatch(restoreMultipleRow(payload))
        .then(unwrapResult)
        .then((response: any) => {
          setMultipleReset(true);
        })
        .catch((err) => {
          setMultipleReset(false);
        });
    }
  };
  useEffect(() => {
    if (isMultipleReset) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isMultipleReset]);

  const storeMultiName = (question_id: any, ownerid: any) => {
    setOwnerId(ownerid);
    let statusExist = false;
    multipleName?.find((val: any) => {
      if (val === question_id) {
        statusExist = true;
      }
    });
    if (statusExist) {
      const a = [...multipleName];
      const b = a.filter((val) => val !== question_id);
      setMultipleName(b);
    } else {
      const a = [...multipleName];
      a.push(
        question_id
      );
      setMultipleName(a);
    }
  };
  const handleMultiDownload = () => {
    const payload = {
      file_name: multipleName,
      user_id: ownerid,
    };
    console.log(payload, "ppppppppppppppp"); // except other folder more than one checkbox is not selecting (BUG) 
    if (payload) {
      dispatch(downloadMultipleDoc(payload));
    }
  };
  const domains = useSelector((state: RootState) => state.documentlist.docList);
  let optionNo = useSelector((state: RootState) => state.submenulist.value);
  let filterd = useSelector((state: RootState) => state.documentfilter.docList);
  console.log(domains, 'dddddddddddddd');
  const columns = [
    {
      field: " ",
      headerName: "",
      width: 0,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.value}
            <Checkbox
              onChange={(e) => {
                optionNo === 6
                  ? setQuestionId([...questionId, cellValues.row.question_id])
                  : storeMultiName(cellValues.row.question_id, cellValues.row.ownerid);
              }}
            />
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      // width: 340,
      flex: 1,
      editable: true,
      renderCell: (cellValues: any) => {
        return (
          <>
           
            {cellValues.value}
            <IconButton>
              <img src={edit} alt="editicon" onClick={(e) => cellValues.api.setCellMode(cellValues.row.id, 'name', 'edit')}></img>
              {/* .setCellMode(1, 'Col1', 'edit')  useRef all function or api can call by above method*/}
            </IconButton>
          </>
        );
      },
    },  //width: 150  width: 170  width: 170  width: 150,
    { field: "owner", headerName: "Owner", flex: 0.4 },
    { field: "last_updated", headerName: "Last updates", flex: 0.6 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4, 
    },
    {
      field: "icon",
      headerName: " ",
      type: "number",
      flex: 0.6,
      renderCell: (cellValues: any) => {
        return (
          <>
          {console.log(cellValues,'_____________')}
            {optionNo === 6 ? (
              <>
                <IconButton
                  onClick={(e) => {
                    setRestoreRow({
                      ...restoreRow,
                      question_id: cellValues.row.question_id,
                    });
                  }}
                  >
                  <span className={classes.restoreBtn}>Restore</span>
                </IconButton>
              </>
            ) : (
              <div className={classes.iconContainer}>
                {/* //setOpen(true) */}
                <IconButton onClick={() => {statusEditAction(cellValues.row.question_id);setOpen(cellValues.row.index)}}>
                  {console.log(open,'_______________xxxxxxxxxxxxxxxxxx')}
                  <img src={edit2} alt="editicon"></img>
                </IconButton >
               
                {/* <Dialog onClose={handleClose} open={open} className={classes.dialogBox}>
                  <List>
                    <ListItem
                      autoFocus
                      button
                      onClick={(e) => {
                        setUpdateStatus({
                          ...updateStatus,
                          status: "in_draft",
                        });
                      }}
                    >
                      In draft
                    </ListItem>
                    <ListItem
                      autoFocus
                      button
                      onClick={(e) => {
                        setUpdateStatus({
                          ...updateStatus,
                          status: "Approved",
                        });
                      }}
                    >
                      Approved
                    </ListItem>
                    <ListItem
                      autoFocus
                      button
                      onClick={(e) => {
                        setUpdateStatus({
                          ...updateStatus,
                          status: "Published",
                        });
                      }}
                    >
                      Published
                    </ListItem>
                  </List>
                </Dialog> */}
                
                {/* <Menu
           id="basic-menu"
          open={open}
         onClose={handleClose}
          className={classes.statusChanger}
         MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
         <MenuItem onClick={handleClose}>Profile</MenuItem>
         <MenuItem onClick={handleClose}>My account</MenuItem>
         <MenuItem onClick={handleClose}>Logout</MenuItem>
         </Menu> */}
         {/* <div className={classes.statusChanger2}>
           <p>status changing should be display here</p>
         </div> */}
                <IconButton
                  onClick={(e) => {
                    setDownloadDoc({
                      ...downloadDoc,
                      file_name: cellValues.row.question_id,
                      user_id: cellValues.row.ownerid,
                    });
                  }}
                >
                  <img src={download} alt="download"></img>
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    setDeleteRow({
                      ...deleteRow,
                      question_id: cellValues.row.question_id,
                    });
                  }}
                >
                  <img src={del} alt="delete"></img>
                </IconButton>
              </div>
            )}
          </>
        );
      },
    },
  ];
  const classes = useStyles();
  console.log(domains, "asdfghjkl", filterd);
  // for edit dialog box
  const [open, setOpen] = useState(0);
  const statusEditAction = (question_id: any) => {
    setOpen(1);
    setUpdateStatus({
      ...updateStatus,
      question_id: question_id,
    });

  }
  const handleClose = () => {
    setOpen(0);
  };
  const editedValue = useRef("");
  const handleCellLeave = (params: any) => {
    let name = editedValue.current;
    const namepayload = {
      access_token: token,
      question_id: params.row.question_id,
      name: name,
    };
    dispatch(updateNameStatus(namepayload));
  };
  const handleCellChange = (params: any) => {
    // @ts-ignore
    let temp = Object.values(params)[0]?.name?.value;
    editedValue.current = temp;
  };
  return (
    <div className={classes.root}>
       {open ? <div
           id="basic-menu"
          className={classes.statusChanger}
          style={{top:`${open*52+250}px`}}
      >
        <Typography align="center">Set status as</Typography>
         <MenuItem 
          onClick={(e) => {
            setUpdateStatus({
              ...updateStatus,
              status: "In draft",
            });
          }
        }
          className = {classes.changeStatusBtn}
         >In draft</MenuItem>
         <MenuItem 
          onClick={(e) => {
            setUpdateStatus({
              ...updateStatus,
              status: "Approved",
            });
          }}>Approved</MenuItem>

         <MenuItem 
         onClick={(e) => {
          setUpdateStatus({
            ...updateStatus,
            status: "Published",
          });
        }}
         >Published</MenuItem>
         </div>:<span></span>}
      <DataGrid
        rows={filterd < domains ? filterd : domains}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[3]}
        className={classes.datagrid}
        disableSelectionOnClick
        onCellEditStop={handleCellLeave}
        onEditRowsModelChange={handleCellChange}
      // autoPageSize
      // checkboxSelection
      // onSelectionModelChange= {(items) => {
      // console.log(items,'qqqqqqqqqq');
      //  domains.filter((value)=>{
      //   if(items.includes(value.id))
      //    {
      //      console.log(value.question_id,'qqqqqqqqqq');
      //      if (questionId.includes(value.question_id) === false)  setQuestionId([...questionId, value.question_id]);
      //    }
      //  }
      //  )
      // }}
      />
      <Button
        onClick={() =>
          optionNo === 6 ? handleRestore() : handleMultiDownload()
        }
        className={classes.actionbtn}
        variant="contained"
      >
        {optionNo === 6 ? "Restore all selected" : "Download"}
      </Button>
    </div>
  );
};
export default GridTable;
