import React, { useState, useEffect } from "react";
import lineBackground from "../../assets/lineBackground.svg";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import HeaderProfile from "../../layout/HeaderProfile";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { userPasswordChange } from "../../redux/slices/changePasswordSlice";
import { RootState } from "../../redux/rootReducer";
import { resetUser } from "../../redux/slices/userSlice";
import { resetSurvey } from "../../redux/slices/surveySlice";
import { newPasswordSubmit } from "../../redux/slices/newPasswordSlice";
import { otResetPassword } from "../../redux/slices/oneTimeResetPasswordSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { forgotPasswordVerify } from "../../redux/slices/forgotPasswordValidSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    backgroundImage: `url(${lineBackground})`,
  },
  mainDiv: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  titleText: {
    fontSize: 30,
    color: "#1071BA",
    fontWeight: "bold",
    marginBottom: 30,
  },
  input: {
    width: 400,
  },
  hint: {
    color: "#1071BA",
    marginTop: 30,
  },
}));

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 40,
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const NewPassword = (props: any) => {
  const [userDetails, setuserDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorState, setErrorState] = useState({
    error: false,
    errormsg: "",
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const refresh_token = localStorage.getItem("Refresh_Token");
  const history = useHistory();
  const query = props.location.search;
  const urlParams = new URLSearchParams(query);
  const id: string = urlParams.get("e") || "";
  const timeId: string = urlParams.get("t") || "";
  const uuid: string = urlParams.get("uuid") || "";
  var decodedStringAtoB = atob(id);

  const newPasswordSubmitted = useSelector(
    (state: RootState) => state.newPassword
  );

  // console.log(timeId,uuid,"vvvvvv")

  useEffect(() => {
    if (uuid) {
      dispatch(forgotPasswordVerify({ uuid }))
        .then(unwrapResult)
        .then((response: any) => {
          if (!response.status) {
            enqueueSnackbar(
              "The reset password link has been used or expired.",
              {
                autoHideDuration: 2000,
                variant: "error",
              }
            );
            localStorage.clear();
            history.push("/signin");
          }
        })
        .catch((errorResponse: any) => {
          enqueueSnackbar("Error occured. Please try again", {
            autoHideDuration: 2000,
            key: 1,
            variant: "error",
          });
          localStorage.clear();
          history.push("/signin");
        });

      dispatch(otResetPassword({ uuid }))
        .then(unwrapResult)
        .then((response: any) => {
          if (!response.status) {
            enqueueSnackbar(
              "The reset password link has been used or expired.",
              {
                autoHideDuration: 2000,
                key: 1,
                variant: "error",
              }
            );
            localStorage.clear();
            history.push("/signin");
          }
        })
        .catch((errorResponse) => {
          enqueueSnackbar("Error occured. Please try again", {
            autoHideDuration: 2000,
            key: 1,
            variant: "error",
          });
          localStorage.clear();
          history.push("/signin");
        });
    }
  }, []);

  const handleValidate = () => {
    const reg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

    if (
      !userDetails.password ||
      !userDetails.confirmPassword ||
      userDetails.password !== userDetails.confirmPassword
    ) {
      setErrorState({ error: true, errormsg: "Passwords do not match" });
    } else if (reg.test(userDetails.password) === false) {
      setErrorState({
        error: true,
        errormsg:
          "Password does not meet the requirements. Please enter the correct password",
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (decodedStringAtoB) {
      const payload = {
        email: decodedStringAtoB,
        password: userDetails.password,
      };
      dispatch(newPasswordSubmit(payload));
    } else {
      enqueueSnackbar("Unexpected error occured. Please try again", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (newPasswordSubmitted.email === decodedStringAtoB) {
      localStorage.clear();
      history.push("/signin");
      enqueueSnackbar(newPasswordSubmitted?.status, {
        autoHideDuration: 2000,
        variant: "success",
      });
    }
  }, [newPasswordSubmitted.email, newPasswordSubmitted.status]);

  return (
    <>
      <div className={classes.root}>
        {/* <HeaderProfile /> */}
        <div className={classes.mainDiv}>
          <div>
            <Typography className={classes.titleText}>
              Enter your new password
            </Typography>
          </div>
          <div>
            <TextField
              className={classes.input}
              label="Password"
              type="password"
              id="outlined-basic"
              variant="outlined"
              error={errorState.error}
              helperText={errorState.error ? errorState.errormsg : null}
              onChange={(event) =>
                setuserDetails({ ...userDetails, password: event.target.value })
              }
              value={userDetails.password}
            />
          </div>
          <div style={{ marginTop: 50 }}>
            <Typography className={classes.titleText}>
              Confirm password
            </Typography>
          </div>
          <div>
            <TextField
              className={classes.input}
              label="Confirm Password"
              type="password"
              id="outlined-basic"
              variant="outlined"
              error={errorState.error}
              helperText={errorState.error ? errorState.errormsg : null}
              onChange={(event) =>
                setuserDetails({
                  ...userDetails,
                  confirmPassword: event.target.value,
                })
              }
              value={userDetails.confirmPassword}
            />
          </div>
          {errorState.error && (
            <div style={{ width: 400 }}>
              <Typography className={classes.hint}>
                Hint: Password should be at least <b>10</b> characters long and
                contain upper and lower case letters, numbers, and symbols like
                {"# / @ ! % ^ & )"}.
              </Typography>
            </div>
          )}
          <ColorButton
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={handleValidate}
          >
            Change Password
          </ColorButton>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
