import { server_url } from "../../types/constants";
import axios from "axios";

export async function downloadDocument(
  file_name:string,
  user_id :string,
) {
  const url = `${server_url}/rm/download/doc/?file_name=${file_name}&user_id=${user_id}`;
  try {
    const response = await axios(
      {
        method: "get",
        url:url,
        responseType: 'blob',
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.docx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  });

} catch (err) {
  throw err.response.data;
}
}


   