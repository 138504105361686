import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useAppDispatch } from "../../redux/store";
import { userUpdateInfo } from "../../redux/slices/updateUserInfo";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import EditDialog from "./EditDialog";
import editbtn from "../../assets/editbtn.svg";

const useStyles = makeStyles((theme) => ({
  redDot: {
    height: 7,
    width: 7,
    backgroundColor: "#EB5757",
    borderRadius: "50%",
    display: "inline-block",
    float: "right",
    marginTop: theme.spacing(1),
  },
  greenDot: {
    height: 7,
    width: 7,
    backgroundColor: "#4FDE8B",
    borderRadius: "50%",
    display: "inline-block",
    float: "right",
    marginTop: theme.spacing(1),
  },
  dropdown: {},
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid lightgrey",
      padding: theme.spacing(1, 1, 1, 1),
    },
    body: {
      fontSize: 16,
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      padding: theme.spacing(1.5, 3, 1.5, 2),
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#D6EAF8 ",
      },
    },
  })
)(TableRow);

const TableRowComponent = ({ row, userInfoUpdated }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const access_token = localStorage.getItem("Access_Token");
  const id = row.id;

  const [editState, setEditState] = useState(false);
  const [userTitle, setuserTitle] = useState(row.title);
  const [userRole, setuserRole] = useState(row.role);
  const [userActive, setuserActive] = useState(row.is_active);

  const handleEdit = () => {
    setEditState(!editState);
  };

  const handleSubmit = () => {
    setEditState(!editState);
    let payload = {
      access_token: access_token,
      title: userTitle,
      role: userRole,
      is_active: userActive,
      id: id,
    };
    dispatch(userUpdateInfo(payload));
  };

  return (
    <>
      <StyledTableRow key={row.id}>
        <StyledTableCell>{row.first_name}</StyledTableCell>
        <StyledTableCell>{row.last_name}</StyledTableCell>
        <StyledTableCell>{row.title}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.role}</StyledTableCell>
        <StyledTableCell>
          {row.is_active ? "Active" : "Disabled"}
          <span
            className={row.is_active ? classes.greenDot : classes.redDot}
          ></span>
        </StyledTableCell>
        <StyledTableCell align="center">
          <img
            src={editbtn}
            alt="Edit"
            onClick={handleEdit}
            style={{ cursor: "pointer" }}
          />
        </StyledTableCell>
      </StyledTableRow>
      <EditDialog
        editState={editState}
        row={row}
        userActive={userActive}
        userTitle={userTitle}
        userRole={userRole}
        setEditState={setEditState}
        handleSubmit={handleSubmit}
        setuserTitle={setuserTitle}
        setuserRole={setuserRole}
        setuserActive={setuserActive}
        edit={true}
      />
    </>
  );
};

export default TableRowComponent;
