import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {nameUpdate} from "../../api/documentManagement/nameUpdate";

export const updateNameStatus = createAsyncThunk(
  "/updatedocumentStatus",
  async ({ access_token, question_id,name}: any, thunkAPI) => {
    try {
      return await nameUpdate(access_token,question_id,name);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const nameReducer = createSlice({
  name: "reducerName",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateNameStatus.fulfilled, (state, { payload }) => {
      state.value = '';
     
    });
  },
});

export default nameReducer.reducer;