import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import Card from "@material-ui/core/Card";
import { riskMatrixPush } from "../../redux/slices/riskMatrixSetSlice";
import { riskMatrixFetch } from "../../redux/slices/riskMatrixGetSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import ConfirmChangeMatrix from "./ConfirmChangeMatrix";
import { matrixUpdateField } from "../../redux/slices/updateMatrixSlice";
import EditIcon from "@material-ui/icons/Edit";
import { riskMatrix } from "../../redux/slices/allRiskMatrixSlice";

const useStyles = makeStyles((theme) => ({
  matrixTable: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    marginTop: 30,
    marginBottom: 127,
    borderRadius: 8,
  },
  tableHead: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
  },
  tableCell: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    padding: 10,
  },
  verticalBorderNone: {
    borderBottom: "none",
    fontWeight: "bold",
    padding: 10,
    width: 100,
    textAlign: "center",
  },
  topCorner: {
    borderBottom: "1px solid #DEDEDE",
  },
  tableHeadCell: {
    padding: 10,
  },
  inputfield: {
    border: "none",
    outline: "none",
    fontSize: 15,
    width: 100,
    "&:active": {
      border: "none",
      outline: "none",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
    "&:select": {
      border: "none",
      outline: "none",
    },
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const Matrix3by3 = ({ matrix3_3, setUpdatedMatrix }: any) => {
  const [matrix, setMatrix] = useState<riskMatrix[]>([]);
  const [edit, setEdit] = useState({
    l1: true,
    l2: true,
    l3: true,
    l4: true,
    i1: true,
    i2: true,
    i3: true,
    i4: true,
  });
  const classes = useStyles();

  const riskMatrix3_3 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_3_3
  );

  useEffect(() => {
    setMatrix(riskMatrix3_3);
  }, [riskMatrix3_3]);

  useEffect(() => {
    setUpdatedMatrix(matrix3_3);
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  const handleChangeLikelihood = (event: any, details: any) => {
    const { impact_key, Likelihood_key } = details;
    const temp = [...matrix];
    let newMatrix = temp.map((val) => {
      if (val.Likelihood_key === Likelihood_key) {
        return {
          ...val,
          Likelihood: event.target.value,
        };
      } else {
        return {
          ...val,
        };
      }
    });

    setMatrix(newMatrix);
    setUpdatedMatrix(newMatrix);
  };

  const handleChangeImpact = (event: any, details: any) => {
    const { impact_key } = details;
    const temp = [...matrix];
    let newMatrix = temp.map((val) => {
      if (val.impact_key === impact_key) {
        return {
          ...val,
          impact: event.target.value,
        };
      } else {
        return {
          ...val,
        };
      }
    });
    setMatrix(newMatrix);
    setUpdatedMatrix(newMatrix);
  };

  const editAction = (id: any) => {
    setEdit({
      l1: true,
      l2: true,
      l3: true,
      l4: true,
      i1: true,
      i2: true,
      i3: true,
      i4: true,
      [id]: false,
    });
    window.document.getElementById(`${id}`)!.focus();
  };

  const [likelihoodOne] = matrix?.filter(
    (val: any) => val.Likelihood_key === "1"
  );
  const [likelihoodTwo] = matrix?.filter(
    (val: any) => val.Likelihood_key === "2"
  );
  const [likelihoodThree] = matrix?.filter(
    (val: any) => val.Likelihood_key === "3"
  );
  const [impactOne] = matrix?.filter((val: any) => val.impact_key === "1");
  const [impactTwo] = matrix?.filter((val: any) => val.impact_key === "2");
  const [impactThree] = matrix?.filter((val: any) => val.impact_key === "3");

  return (
    <>
      <table className={classes.matrixTable}>
        <tr>
          <th className={classes.topCorner}></th>
          <th></th>
          <th className={classes.tableHeadCell}>
            <Typography>
              <b>Likelihood</b>
            </Typography>
          </th>
          <th></th>
          <th></th>
        </tr>

        <tr>
          <td className={classes.verticalBorderNone}></td>
          <td className={classes.tableCell}></td>
          <td className={classes.tableCell}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                className={classes.inputfield}
                onChange={(e) => handleChangeLikelihood(e, likelihoodOne)}
                style={{fontSize: 17}}
                value={likelihoodOne?.Likelihood}
                readOnly={edit.l1}
                autoFocus={true}
                id="l1"
              />
              <EditIcon
                style={{ fontSize: 17, cursor: "pointer" }}
                onClick={() => editAction("l1")}
              />{" "}
            </form>
          </td>
          <td className={classes.tableCell}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                className={classes.inputfield}
                onChange={(e) => handleChangeLikelihood(e, likelihoodTwo)}
                style={{fontSize: 17}}
                value={likelihoodTwo?.Likelihood}
                readOnly={edit.l2}
                autoFocus={true}
                id="l2"
              />
              <EditIcon
                style={{ fontSize: 17, cursor: "pointer" }}
                onClick={() => editAction("l2")}
              />{" "}
            </form>
          </td>
          <td className={classes.tableCell}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                className={classes.inputfield}
                onChange={(e) => handleChangeLikelihood(e, likelihoodThree)}
                style={{fontSize: 17}}
                value={likelihoodThree?.Likelihood}
                readOnly={edit.l3}
                autoFocus={true}
                id="l3"
              />
              <EditIcon
                style={{ fontSize: 17, cursor: "pointer" }}
                onClick={() => editAction("l3")}
              />{" "}
            </form>
          </td>
        </tr>

        <tr>
          <td className={classes.verticalBorderNone}>
            <Typography>
              <b>Impact</b>
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                className={classes.inputfield}
                onChange={(e) => handleChangeImpact(e, impactOne)}
                style={{fontSize: 17}}
                value={impactOne?.impact}
                readOnly={edit.i1}
                autoFocus={true}
                id="i1"
              />
              <EditIcon
                style={{ fontSize: 17, cursor: "pointer" }}
                onClick={() => editAction("i1")}
              />{" "}
            </form>
          </td>
          <td className={classes.tableCell} style={{ background: "#7EEB7A" }}>
            <Typography>Low</Typography>
          </td>
          <td className={classes.tableCell} style={{ background: "#7EEB7A" }}>
            <Typography>Low</Typography>
          </td>
          <td className={classes.tableCell} style={{ background: "#F1F36C" }}>
            <Typography>Medium</Typography>
          </td>
        </tr>

        <tr>
          <td className={classes.verticalBorderNone}></td>
          <td className={classes.tableCell}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                className={classes.inputfield}
                onChange={(e) => handleChangeImpact(e, impactTwo)}
                style={{fontSize: 17}}
                value={impactTwo?.impact}
                readOnly={edit.i2}
                autoFocus={true}
                id="i2"
              />
              <EditIcon
                style={{ fontSize: 17, cursor: "pointer" }}
                onClick={() => editAction("i2")}
              />{" "}
            </form>
          </td>
          <td className={classes.tableCell} style={{ background: "#7EEB7A" }}>
            <Typography>Low</Typography>
          </td>
          <td className={classes.tableCell} style={{ background: "#F1F36C" }}>
            <Typography>Medium</Typography>
          </td>
          <td className={classes.tableCell} style={{ background: "#E53737" }}>
            <Typography>High</Typography>
          </td>
        </tr>

        <tr>
          <td className={classes.verticalBorderNone}></td>
          <td className={classes.tableCell}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                className={classes.inputfield}
                onChange={(e) => handleChangeImpact(e, impactThree)}
                style={{fontSize: 17}}
                value={impactThree?.impact}
                readOnly={edit.i3}
                autoFocus={true}
                id="i3"
              />
              <EditIcon
                style={{ fontSize: 17, cursor: "pointer" }}
                onClick={() => editAction("i3")}
              />{" "}
            </form>
          </td>
          <td className={classes.tableCell} style={{ background: "#F1F36C" }}>
            <Typography>Medium</Typography>
          </td>
          <td className={classes.tableCell} style={{ background: "#E53737" }}>
            <Typography>High</Typography>
          </td>
          <td className={classes.tableCell} style={{ background: "#E53737" }}>
            <Typography style={{fontSize: 16}}>High</Typography>
          </td>
        </tr>
      </table>
      {/* <ConfirmChangeMatrix handleUpdateMatrix={handleUpdateMatrix} /> */}
    </>
  );
};

export default Matrix3by3;
