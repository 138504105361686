import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

const MatrixPopupNotf = ({ matrixPopup, setMatrixPopup }: any) => {
  const handleClose = () => {
    setMatrixPopup(false);
  };

  return (
    <>
      <Dialog
        open={matrixPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "New risk matrix has been applied. Please review and reassess risks according to the new risk matrix."
          }
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MatrixPopupNotf;
