import { server_url } from "../../types/constants";
import axios from "axios";

export async function deleteDocument(
  access_token: string,
  question_id :string,
) {
  //  const { } = docCredential.payload;
  //  console.log(access_token,"aaaaaaa");
  const url = `${server_url}/rm/delete/doc/?question_id=${question_id}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
