import React from "react";
import { Form, useFormikContext } from "formik";
import Typography from "@material-ui/core/Typography";
import {
  setActiveDomain,
  submitResponseThunk,
  Survey_Question,
} from "../../../redux/slices/surveySlice";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { QuestionnaireProps } from "./Questionnaire.props";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import bigWhiteLogo from "../../../assets/bigWhiteLogo.svg";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import parse from "html-react-parser";
import { ParamTypes } from "../../../types/survey";
import sanitizeHtml from "sanitize-html";

export const questionnaireUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(0, 3, 0, 3),
      backgroundImage: `url(${bigWhiteLogo})`,
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "border-box",
      backgroundSize: "600px 900px",
      height: "99vh",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    paper: {
      display: "flex",
      flexDirection: "column",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "800px",
    },
    formContainer: {
      marginLeft: theme.spacing(2),
    },
    questionContainer: {
      marginTop: theme.spacing(5),
      "&:hover": {
        color: "black",
        fontWeight: 600,
      },
      "&:focus": {
        color: "black",
        fontWeight: 600,
      },
    },
    notRespondedQuestionContainer: {
      color: "grey",
    },
    radioGroup: {
      marginTop: theme.spacing(1),
    },
    partiallyNoResponseTextField: {
      marginTop: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      fontWeight: 600,
      minWidth: theme.spacing(16),
    },
    nextButton: {
      marginLeft: theme.spacing(2),
    },
    activeDomainTypography: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(5),
    },
    arrowGrid: {
      flexBasis: "5%",
    },
    radioLabel: {
      fontSize: 16,
    },
  })
);
const Questionnaire = (props: QuestionnaireProps) => {
  const { activeDomain, sidebarDomains } = props.CurrentPosition;
  const { domainSurveyQuestion } = props;
  const { surveyQuestions } = props;
  const { formik } = props;
  const classes = questionnaireUseStyles();
  const survey = useSelector((state: RootState) => state.survey.survey);
  const dispatch = useAppDispatch();
  const { uuid } = useParams<ParamTypes>();
  const { setFieldValue, submitForm } = useFormikContext();
  const { values }: any = useFormikContext();

  const handlePreviousPage = () => {
    let activeDomainIndex = sidebarDomains.findIndex(
      (domain) => domain === activeDomain
    );
    if (activeDomainIndex === 0) {
      activeDomainIndex = sidebarDomains.length;
    }
    dispatch(
      setActiveDomain(
        sidebarDomains[(activeDomainIndex - 1) % sidebarDomains.length]
      )
    );
  };
  const handleNextPage = async () => {
    setFieldValue("submitType", "next", false);
    submitForm();
  };

  const handleSubmit = () => {
    setFieldValue("submitType", "submit", false);
    submitForm();
  };

  function handleOnClick(event: any) {
    if (event?.target?.value) {
      const separatedResponse: string[] = event.target.value.split("-");
      //format -`NI-${surveyQuestion.question.id}-${survey.id}`
      dispatch(
        submitResponseThunk({
          response: separatedResponse[0],
          id_question: separatedResponse[1],
          id_survey: separatedResponse[2],
          uuid,
        })
      );
    }
  }

  const handleOnBlur = (event: any) => {
    if (event?.target?.id) {
      const response_explanation = values[event.target.id];
      const separatedResponse: string[] = event.target.id.split("-");
      // format -`NI-${surveyQuestion.question.id}-${survey.id}`
      if (response_explanation.length > 0) {
        dispatch(
          submitResponseThunk({
            response: separatedResponse[0],
            id_question: separatedResponse[1],
            id_survey: separatedResponse[2],
            uuid,
            response_explanation,
          })
        );
      } else {
        dispatch(
          submitResponseThunk({
            response: separatedResponse[0],
            id_question: separatedResponse[1],
            id_survey: separatedResponse[2],
            uuid,
          })
        );
      }
    }
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.paper}>
        <Form className={classes.form}>
          <Container maxWidth="md" className={classes.formContainer}>
            <Typography
              variant="h5"
              color="primary"
              className={classes.activeDomainTypography}
            >
              <Box fontWeight={800}>{activeDomain}</Box>
            </Typography>
            {domainSurveyQuestion.map(
              (surveyQuestion: Survey_Question, index) => {
                {
                  // here we check for parent question, iterating parent question and
                  // if parent question response is not FI return true
                }
                if (
                  surveyQuestion.question.parent_question.some(
                    ({ question_id }: any) => {
                      const changedQuestionIndex = surveyQuestions.findIndex(
                        (survey_question: Survey_Question) =>
                          survey_question.question.question_id === question_id
                      );
                      if (
                        surveyQuestions &&
                        surveyQuestions[changedQuestionIndex].response !== "FI"
                      ) {
                        return true;
                      }
                    }
                  )
                ) {
                  return (
                    <div
                      key={`div-${surveyQuestion.question.id}${survey.id}${index}`}
                    />
                  );
                } else {
                  return (
                    <div
                      key={`div-${surveyQuestion.question.id}${survey.id}${index}`}
                      className={`${classes.questionContainer} ${
                        surveyQuestion.response === "NR"
                          ? `${classes.notRespondedQuestionContainer}`
                          : ""
                      }`}
                    >
                      <Grid container direction="row" alignItems="flex-start">
                        <Grid item xs={1} className={classes.arrowGrid}>
                          <ChevronRightOutlinedIcon />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography variant="body1">
                            {parse(
                              sanitizeHtml(
                                surveyQuestion.question.question_text
                              )
                            )}
                          </Typography>

                          <RadioGroup
                            key={`${surveyQuestion.question.id}${survey.id}${index}`}
                            value={`${surveyQuestion.response}-${surveyQuestion.question.id}-${survey.id}`}
                            row
                            className={clsx(classes.radioGroup)}
                          >
                            <FormControlLabel
                              value={`FI-${surveyQuestion.question.id}-${survey.id}`}
                              key={`FI-${surveyQuestion.question.id}${survey.id}${index}`}
                              control={<Radio size="small" />}
                              label="Fully Implemented"
                              labelPlacement="end"
                              onClick={handleOnClick}
                              classes={{
                                label: classes.radioLabel,
                              }}
                            />
                            <FormControlLabel
                              value={`PI-${surveyQuestion.question.id}-${survey.id}`}
                              key={`PI-${surveyQuestion.question.id}${survey.id}${index}`}
                              control={<Radio size="small" />}
                              label="Partially Implemented"
                              labelPlacement="end"
                              onClick={handleOnClick}
                              classes={{
                                label: classes.radioLabel,
                              }}
                            />
                            <FormControlLabel
                              value={`NI-${surveyQuestion.question.id}-${survey.id}`}
                              key={`NI-${surveyQuestion.question.id}${survey.id}${index}`}
                              control={<Radio size="small" />}
                              label="Not Implemented"
                              labelPlacement="end"
                              onClick={handleOnClick}
                              classes={{
                                label: classes.radioLabel,
                              }}
                            />
                            <FormControlLabel
                              value={`NA-${surveyQuestion.question.id}-${survey.id}`}
                              key={`NA-${surveyQuestion.question.id}${survey.id}${index}`}
                              control={<Radio size="small" />}
                              label="Not Applicable"
                              labelPlacement="end"
                              onClick={handleOnClick}
                              classes={{
                                label: classes.radioLabel,
                              }}
                            />
                          </RadioGroup>
                          {surveyQuestion.response === "NA" ||
                          surveyQuestion.response === "NI" ||
                          surveyQuestion.response === "PI" ? (
                            <>
                              <TextField
                                id={`${surveyQuestion.response}-${surveyQuestion.question.id}-${survey.id}`}
                                key={`${surveyQuestion.question.id}${survey.id}`}
                                label={`Please describe the reason why you have answered ${
                                  surveyQuestion.response === "PI"
                                    ? "Partially Implemented"
                                    : "Not Implemented"
                                }`}
                                variant="outlined"
                                className={clsx(
                                  classes.partiallyNoResponseTextField
                                )}
                                fullWidth
                                required
                                multiline
                                rows={3}
                                rowsMax={5}
                                value={
                                  formik.values[
                                    `${surveyQuestion.response}-${surveyQuestion.question.id}-${survey.id}`
                                  ]
                                }
                                onChange={formik.handleChange}
                                onBlur={handleOnBlur}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  );
                }
              }
            )}
          </Container>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={clsx(classes.button)}
              onClick={handlePreviousPage}
              disabled={
                surveyQuestions &&
                surveyQuestions.length > 1 &&
                activeDomain === surveyQuestions[0].question.domain
              }
            >
              Previous
            </Button>

            {surveyQuestions &&
            surveyQuestions.length > 1 &&
            activeDomain !==
              surveyQuestions[surveyQuestions.length - 1].question.domain ? (
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.nextButton, classes.button)}
                onClick={handleNextPage}
              >
                Next
              </Button>
            ) : (
              <></>
            )}
            {surveyQuestions &&
            surveyQuestions.length > 1 &&
            activeDomain ===
              surveyQuestions[surveyQuestions.length - 1].question.domain ? (
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.nextButton, classes.button)}
                onClick={handleSubmit}
              >
                Finish
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Form>
      </div>
    </main>
  );
};

export default Questionnaire;
