import { server_url } from "../../types/constants";
import axios from "axios";

export async function sendDeviceDetails(email: string,device:string) {
  const url = `http://5.9.18.28:8023/authentication/user_tracking?email=${email}&device=${device}`;
  try {
    const response = await axios.get(url, {
      // headers: {
      //   Authorization: `Bearer ${access_token}`,
      // },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
