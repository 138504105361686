import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {statusUpdate} from "../../api/documentManagement/statusUpdate";

export const updateDocStatus = createAsyncThunk(
  "/updatedocumentStatus",
  async ({ access_token, question_id,status}: any, thunkAPI) => {
    try {
      return await statusUpdate(access_token,question_id,status);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const statusReducer = createSlice({
  name: "reducerStatus",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateDocStatus.fulfilled, (state, { payload }) => {
      state.value = '';
     
    });
  },
});

export default statusReducer.reducer;