import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendDeviceDetails } from "../../api/auth/deviceDetails";

export const userDeviceDetails = createAsyncThunk(
  "deviceDetails",
  async ({ email, device }: any, thunkAPI) => {
    try {
      return await sendDeviceDetails(email,device);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateRiskList = {
    status: string,
};

const initialState: initialStateRiskList = {
    status: "",
};

const deviceInfo = createSlice({
  name: "deviceDetailsSlice",
  initialState,
  reducers: {
    resetDeviceInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(userDeviceDetails.fulfilled, (state, { payload }) => {
    //   state.is_notification = payload.is_notification;
    });
  },
});

export const { resetDeviceInfo } = deviceInfo.actions;

export default deviceInfo.reducer;