import React, {  useState } from 'react'
import {
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { useAppDispatch } from "../../redux/store";
import Confirmation from "../RiskManagement/confirmationModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { newSoaUser } from '../../redux/slices/addSoaUserSlice';
import { getAllSoaUsers } from "../../redux/slices/getSoaUserSlice";

const useStyles = makeStyles((theme) => ({
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  modalContainer: {
    width: 500,
    paddingLeft: 140,
    paddingRight: 140,
    paddingTop: 50,
    paddingBottom: 100,
  },
  subtitle: {
    color: "red",
    fontSize: 12,
  },
  names: {
    display: "flex",
    justifyContent: "space-between",
  },
  roleAndTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 53,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  rolefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
  },
  titlefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldsRight: {
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
    marginLeft: 7,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldsLeft: {
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
    marginRight: 7,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  emailtextFields: {
    marginTop: 5,
    marginBottom: 5,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  accountStatusDiv: {
    display: "flex",
   
    alignItems: "center",
    flex: 1,
  },
  accountStatusText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  formControl: {
    flex: 1,
    marginLeft: 7,
  },
  dropDownSelect: {
    borderRadius: 8,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 50,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 50,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AddUserDialog = ({ editState, setEditState }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const access_token = localStorage.getItem("Access_Token");
  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    title: false,
  });
  const [errorMsg, setErrorMsg] = useState({
    first_name: "",
    last_name: "",
    title: "",
  });
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    title: "",
  });
  const [confirmation, setConfirmation] = useState(false);



  const handleValidate = () => {
    const errors: any = {};
    const errorMessage: any = {};
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let validated = true;
    if (!userDetails.first_name) {
      errors["first_name"] = true;
      errorMessage["first_name"] = "Please enter the first name";
      validated = false;
    }
    if (!userDetails.last_name) {
      errors["last_name"] = true;
      errorMessage["last_name"] = "Please enter the last name";
      validated = false;
    }
  
    if (!userDetails.title) {
      errors["title"] = true;
      errorMessage["title"] = "Please enter title *";
      validated = false;
    }
   
    if (validated === true) {
      handleSubmitUser();
      resetErrors();
    }
    setError({ ...errors });
    setErrorMsg({ ...errorMessage });
  };

  const handleSubmitUser = () => {
    let payload = {
      first_name: userDetails.first_name,
      last_name:userDetails.last_name,
      title:userDetails.title
    };
    dispatch(newSoaUser(payload)).then(unwrapResult)
    .then((response: any) => {
      dispatch(getAllSoaUsers({ access_token }));
      handleCancel();
    })
    .catch((error) => {
    });
  };


  const resetErrors = () => {
    setError({
      first_name: false,
      last_name: false,
      title: false,
     
    });
  };

  const handleCancel = () => {
    setEditState(false);
    setUserDetails({
      first_name: "",
      last_name: "",
      title: "",
     
    });
    resetErrors();
  };


  const handleOpenConfirm = (confirm: boolean) => {
    setConfirmation(confirm);
  };

  const handleCheckChanges = () => {
    let isConfirm = false;
    if (
      
      userDetails.first_name ||
      userDetails.last_name ||
      userDetails.title 
    ) {
      isConfirm = true;
      handleOpenConfirm(true);
    } else {
      isConfirm = false;
      handleOpenConfirm(false);
      handleCancel();
    }
    return isConfirm;
  };

  return (
    <>
      <Dialog
        onClose={handleCheckChanges}
        aria-labelledby="customized-dialog-title"
        open={editState}
        onBackdropClick={() => handleCheckChanges()}
        onEscapeKeyDown={() => handleCheckChanges()}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.titleCenter}>
              <Typography className={classes.profileTitle}>
                Add New user
              </Typography>
            </div>
            <div className={classes.names}>
              <TextField
                className={classes.textFieldsLeft}
                required
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    first_name: e.target.value,
                  })
                }
                id="outlined-required"
                label="First Name"
                variant="outlined"
                error={error.first_name}
                helperText={error.first_name ? errorMsg.first_name : null}
              />
              <TextField
                className={classes.textFieldsRight}
                required
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    last_name: e.target.value,
                  })
                }
                id="outlined-required"
                label="Last Name"
                variant="outlined"
                error={error.last_name}
                helperText={error.last_name ? errorMsg.last_name : null}
              />
            </div>
            
            <div className={classes.accountStatus}>
              <div className={classes.accountStatusDiv}>
                <Typography className={classes.accountStatusText}>
                  Enter Your title *
                </Typography>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
               
              <TextField
                className={classes.textFieldsRight}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    title: e.target.value,
                  })
                }
                id="outlined-required"
                label="Enter your title"
                variant="outlined"
                error={error.title}
                helperText={error.title ? errorMsg.title : null}
              />
               
              </FormControl>
            </div>
            <div className={classes.buttons}>
              <Button className={classes.saveBtn} onClick={handleValidate}>
                Save
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <Confirmation
              confirmation={confirmation}
              setConfirmation={setConfirmation}
              handleCancel={handleCancel}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUserDialog;
