import React, { useEffect, useState } from "react";
import HeaderProfile from "../../layout/HeaderProfile";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Confirmation from "../RiskManagement/confirmationModal";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  modalContainer: {
    paddingLeft: 140,
    paddingRight: 140,
    paddingTop: 30,
    paddingBottom: 100,
  },
  subtitle: {
    color: "grey",
    fontSize: 12,
  },
  names: {
    display: "flex",
    justifyContent: "space-between",
  },
  roleAndTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 53,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  rolefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
    maxWidth: 240,
  },
  titlefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    maxWidth: 240,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldsRight: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    maxWidth: 240,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldsLeft: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
    maxWidth: 240,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  emailtextFields: {
    marginTop: 5,
    marginBottom: 5,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  accountStatusDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  accountStatusText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  formControl: {
    flex: 1,
    marginLeft: 7,
  },
  dropDownSelect: {
    borderRadius: 8,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 50,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 50,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const EditDialog = ({
  editState,
  userActive,
  userTitle,
  userRole,
  row,
  handleSubmit,
  setEditState,
  setuserTitle,
  setuserRole,
  setuserActive,
  task,
}: any) => {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);
  const [valueChange, setValueChange] = useState(false);
  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    role: false,
    title: false,
    email: false,
    status: false,
  });
  const [errorMsg, setErrorMsg] = useState({
    first_name: "",
    last_name: "",
    role: "",
    title: "",
    email: "",
    status: "",
  });

  const handleCancel = () => {
    setEditState(false);
    setValueChange(false);
    resetErrors();
    setuserTitle(row.title);
    setuserRole(row.role);
    setuserActive(row.is_active);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValueChange(true);
    if (event.target.value === "Active") setuserActive(true);
    else {
      setuserActive(false);
    }
  };

  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setuserRole(event.target.value as string);
    setValueChange(true);
  };

  const handleChangeTitle = (event: any) => {
    setuserTitle(event.target.value);
    setValueChange(true);
  };

  const handleOpenConfirm = (confirm: boolean) => {
    setConfirmation(confirm);
  };

  const handleValidate = () => {
    const errors: any = {};
    const errorMessage: any = {};
    let validated = true;

    if (!userRole) {
      errors["role"] = true;
      errorMessage["role"] = "Please enter role";
      validated = false;
    }
    if (!userTitle) {
      errors["title"] = true;
      errorMessage["title"] = "Please enter title";
      validated = false;
    }
    if (userActive === null) {
      errors["status"] = true;
      errorMessage["status"] = "Please enter status";
      validated = false;
    }
    if (validated === true) {
      handleSubmit();
      resetErrors();
    }
    setError({ ...errors });
    setErrorMsg({ ...errorMessage });
  };

  const resetErrors = () => {
    setError({
      first_name: false,
      last_name: false,
      role: false,
      title: false,
      email: false,
      status: false,
    });
  };

  const handleCheckChanges = () => {
    let isConfirm = false;
    if (valueChange) {
      isConfirm = true;
      handleOpenConfirm(true);
    } else {
      isConfirm = false;
      handleOpenConfirm(false);
      handleCancel();
    }
    return isConfirm;
  };

  return (
    <>
      <Dialog
        onClose={handleCheckChanges}
        aria-labelledby="customized-dialog-title"
        open={editState}
        onBackdropClick={() => handleCheckChanges()}
        onEscapeKeyDown={() => handleCheckChanges()}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.titleCenter}>
              <Typography className={classes.profileTitle}>
                Edit user
              </Typography>
            </div>
            <div className={classes.names}>
              <TextField
                className={classes.textFieldsLeft}
                disabled
                value={row.first_name}
                id="outlined-required"
                label="First Name"
                variant="outlined"
              />
              <TextField
                className={classes.textFieldsRight}
                disabled
                value={row.last_name}
                id="outlined-required"
                label="Last Name"
                variant="outlined"
              />
            </div>
            <div className={classes.roleAndTitle}>
              <FormControl variant="outlined" className={classes.rolefield}>
                <InputLabel>Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  required
                  onChange={handleChangeRole}
                  label="Role"
                  value={userRole}
                  className={classes.dropDownSelect}
                  error={error.role}
                >
                  <MenuItem disabled value={"Administrator"}>
                    Administrator
                  </MenuItem>
                  <MenuItem value={"Risk manager"}>Risk manager</MenuItem>
                  <MenuItem value={"Risk owner"}>Risk owner</MenuItem>
                  <MenuItem value={"Control owner"}>Control owner</MenuItem>
                </Select>
                {error.role && (
                  <FormHelperText style={{ color: "red" }}>
                    {errorMsg.role}
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                className={classes.titlefield}
                required
                value={userTitle}
                onChange={handleChangeTitle}
                id="outlined-required"
                label="Title"
                variant="outlined"
                error={error.title}
                helperText={error.title ? errorMsg.title : null}
              />
            </div>
            <TextField
              className={classes.emailtextFields}
              disabled
              value={row.email}
              id="outlined-required"
              label="Email Address"
              variant="outlined"
              fullWidth
            />
            <div className={classes.accountStatus}>
              <div className={classes.accountStatusDiv}>
                <Typography className={classes.accountStatusText}>
                  Choose account status
                </Typography>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={userActive ? "Active" : "Disabled"}
                  onChange={handleChangeStatus}
                  label="Status"
                  className={classes.dropDownSelect}
                  error={error.status}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
                {error.status && (
                  <FormHelperText style={{ color: "red" }}>
                    {errorMsg.status}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={classes.buttons}>
              <Button className={classes.saveBtn} onClick={handleValidate}>
                Save
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <Confirmation
              confirmation={confirmation}
              setConfirmation={setConfirmation}
              handleCancel={handleCancel}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDialog;
