import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {uploadFile} from "../../api/documentManagement/uploadFile";

export const uploadDocStatus = createAsyncThunk(
  "/uploaddocumentStatus",
  async ({ access_token, question_id,iso_control,upload_type,file}: any, thunkAPI) => {
    try {
      return await uploadFile(access_token, question_id,iso_control,upload_type,file);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const uploadReducer = createSlice({
  name: "reducerUpload",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadDocStatus.fulfilled, (state, { payload }) => {
      state.value = '';
    });
  },
});

export default uploadReducer.reducer;