import { useState } from "react";
import lineBackground from "../../assets/lineBackground.svg";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useAppDispatch } from "../../redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { userEmailSubmit } from "../../redux/slices/emailSubmitSlice";
import DisclaimerModal from "./disclaimerModal";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    backgroundImage: `url(${lineBackground})`,
  },
  mainDiv: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  titleText: {
    fontSize: 30,
    color: "#1071BA",
    fontWeight: "bold",
    marginBottom: 30,
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleTextSmall: {
    fontSize: 22,
    color: "#1071BA",
    fontWeight: "bold",
    marginBottom: 30,
  },
}));

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 40,
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const EmailSubmit = () => {
  const [userEmail, setUserEmail] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleValidate = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!userEmail) {
      setErrorState(true);
    } else if (reg.test(userEmail) === false) {
      setErrorState(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const payload = {
      email: userEmail,
    };
    dispatch(userEmailSubmit(payload))
      .then(unwrapResult)
      .then((response: any) => {
        setDisclaimer(true);
        setUserEmail("");
      })
      .catch((errorResponse) => {
        enqueueSnackbar("Error occured, Please try again", {
          autoHideDuration: 2000,
          key: 1,
          variant: "error",
        });
      });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.mainDiv}>
          <div className={classes.titleDiv}>
            <Typography className={classes.titleText}>
              Please enter your email address.
            </Typography>
            <Typography className={classes.titleTextSmall}>
              You will receive a link to create a new password via email.
            </Typography>
          </div>
          <div>
            <TextField
              label="Email"
              type="email"
              id="outlined-basic"
              variant="outlined"
              error={errorState}
              helperText={errorState ? "Please enter a valid email" : null}
              onChange={(event) => setUserEmail(event.target.value)}
              value={userEmail}
            />
          </div>
          <ColorButton
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={handleValidate}
          >
            Submit
          </ColorButton>
        </div>
        <DisclaimerModal
          disclaimer={disclaimer}
          setDisclaimer={setDisclaimer}
        />
      </div>
    </>
  );
};

export default EmailSubmit;
