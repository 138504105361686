import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addRiskGroup } from "../../api/risk/addRiskGroup";

export const newRiskGroupAdd = createAsyncThunk(
  "risk/addRiskGroup",
  async ({ name, access_token }: any, thunkAPI) => {
    try {
      return await addRiskGroup(name, access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateRiskGroup = {
  id: number;
};

const initialState: initialStateRiskGroup = {
  id: 0,
};

const RiskGroupCreate = createSlice({
  name: "addRiskGroup",
  initialState,
  reducers: {
    resetAddRiskGroup: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newRiskGroupAdd.fulfilled, (state, { payload }) => {
      state.id = payload.id;
    });
  },
});

export const { resetAddRiskGroup } = RiskGroupCreate.actions;

export default RiskGroupCreate.reducer;
