import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HeaderProfile from "../layout/HeaderProfile";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { Button, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import lineBackground from "../assets/lineBackground.svg";
import { useSnackbar } from "notistack";
import { createAssessmentThunk } from "../redux/slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../redux/store";
import { confirmAlert } from "react-confirm-alert";
import { REFRESH_TOKEN } from "../types/constants";
import { loadUser, User } from "../redux/slices/userSlice";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "../layout/Loading"; // Import css
// import { matrixSettingCheck } from "../redux/slices/riskMatrixSetCheckSlice";
import CoreAssessmentModal from "../components/CoreAssessmentModal";
import { userDeviceDetails } from "../redux/slices/devicePlatformInfoSlice";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    marginTop: theme.spacing(8),
    backgroundImage: `url(${lineBackground})`,
    minHeight: "85vh",
  },
  congratulationsText: {
    color: theme.palette.primary.main,
  },
  questionnaireDescription: {
    marginTop: theme.spacing(2),
  },
  mainButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10),
  },
  buttonGroupContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    backgroundColor: "rgba(255,255,255,0.6)",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    marginRight: theme.spacing(4),
    fontWeight: 800,
    width: theme.spacing(34),
    height: theme.spacing(14),
    marginTop: theme.spacing(3),
  },
}));

function MainPage() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  const [openModal, setOpenModal] = useState(false);
  const [resetSurvey, setResetSurvey] = useState("");
  const [assessmentType, setAssessmentType] = useState("");
  // const access_token = localStorage.getItem("Access_Token");
  const history = useHistory();
  useEffect(() => {
    if (refresh_token && refresh_token.length > 1) {
      dispatch(loadUser({ refresh_token }))
        .then(unwrapResult)
        .then((user: User) => {
          const email = user?.email;
          const device = navigator.userAgent;
          const payload = {
            email: email,
            device: device,
          }
            dispatch(userDeviceDetails(payload))
          if (user.role === "Administrator" && !isFinishedFirstCore) {
            if (user.isNew) {
              history.push("/welcome-page/");
            } else if (
              user.role === "Administrator" &&
              user.last_core_survey_uuid &&
              user.last_core_survey_uuid.length > 1 &&
              user.last_core_survey_status === "OP"
            ) {
              history.push(`/core/${user.last_core_survey_uuid}/`);
            }
          }
        });
    } else {
      history.push("/signin/");
    }
  }, []);

  useEffect(() => {
    if (assessmentType === "Core") {
      if (resetSurvey === "false") {
        history.push(`/core/${last_core_survey_uuid}/`);
      } else if (resetSurvey === "true") {
        startAssessment("Core Assessment", "core");
      }
    }

    if (assessmentType === "Technical") {
      if (resetSurvey === "false") {
        if (last_filter_survey_status && last_filter_survey_status === "OF") {
          history.push(`/filter/${last_filter_survey_uuid}/`);
        } else if (
          last_filter_survey_status &&
          last_filter_survey_status === "OT"
        ) {
          history.push(`/technical/${last_filter_survey_uuid}/`);
        } else if (
          last_filter_survey_status &&
          last_filter_survey_status === "OD"
        ) {
          history.push(`/technical/${last_filter_survey_uuid}/`);
        } else {
          history.push(`/technical/${last_filter_survey_uuid}/`);
        }
      } else if (resetSurvey === "true") {
        startAssessment("Filter Assessment", "filter");
      }
    }
  }, [resetSurvey]);

  const isPending = useSelector((state: RootState) => state.user.isPending);
  const { enqueueSnackbar } = useSnackbar();
  const isFinishedFirstCore = useSelector(
    (state: RootState) => state.user.isFinishedFirstCore
  );

  const last_core_survey_uuid = useSelector(
    (state: RootState) => state.user.last_core_survey_uuid
  );
  const last_core_survey_status = useSelector(
    (state: RootState) => state.user.last_core_survey_status
  );

  const last_filter_survey_uuid = useSelector(
    (state: RootState) => state.user.last_filter_survey_uuid
  );
  const last_filter_survey_status = useSelector(
    (state: RootState) => state.user.last_filter_survey_status
  );
  const userRole = useSelector((state: RootState) => state.user.role);

  if (isPending) {
    return <Loading />;
  }

  const startCoreHandler = () => {
    if (userRole === "Administrator") {
      setOpenModal(true);
      setAssessmentType("Core");
      // if (last_core_survey_status && last_core_survey_status === "RG") {
      //   showStartCoreConfirmMessage("Core Assessment", "core");
      // } else
      // if (
      //   last_core_survey_status &&
      //   (last_core_survey_status === "OP" || last_core_survey_status === "OF")
      // ) {
      // history.push(`/core/${last_core_survey_uuid}/`);
      // }
      // else {
      //   startAssessment("Core Assessment", "core");
      // }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const coreReportHandler = () => {
    if (userRole === "Administrator") {
      if (last_core_survey_status && last_core_survey_status === "RG") {
        history.push(`/report/${last_core_survey_uuid}/`);
      } else {
        enqueueSnackbar("Please complete core questionnaire first", {
          autoHideDuration: 2000,
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const startTechnicalHandler = () => {
    if (userRole === "Administrator") {
      if (!isFinishedFirstCore) {
        enqueueSnackbar("Please complete core questionnaire first", {
          autoHideDuration: 2000,
          variant: "warning",
        });
      }
      // else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "OF"
      // ) {
      //   history.push(`/filter/${last_filter_survey_uuid}/`);
      // } else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "OT"
      // ) {
      //   history.push(`/technical/${last_filter_survey_uuid}/`);
      // }
      // else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "RG"
      // ) {
      //   showStartCoreConfirmMessage("Filter Assessment", "filter");
      // }
      // else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "OD"
      // ) {
      //   history.push(`/technical/${last_filter_survey_uuid}/`);
      // }
      else {
        setOpenModal(true);
        setAssessmentType("Technical");
        // startAssessment("Filter Assessment", "filter");
      }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const technicalReportHandler = () => {
    if (userRole === "Administrator") {
      if (last_filter_survey_status && last_filter_survey_status === "RG") {
        history.push(`/report/${last_filter_survey_uuid}/`);
      } else {
        enqueueSnackbar("Please complete technical questionnaire first", {
          autoHideDuration: 2000,
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const usersProfile = () => {
    if (userRole === "Administrator") {
      history.push(`/usersprofile`);
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }

    // if (userRole === "Administrator") {
    // } else {
    //   enqueueSnackbar("Access denied, please contact Administrator", {
    //     variant: "error",
    //   });
    // }
  };

  const showStartCoreConfirmMessage = (
    assessment_category: string,
    url_to_category: string
  ) => {
    confirmAlert({
      title: "Confirm to start new assessment",
      message: "This will reset previous assessment results",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            startAssessment(assessment_category, url_to_category);
          },
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  const startAssessment = (
    assessment_category: string,
    url_to_category: string
  ) => {
    dispatch(
      createAssessmentThunk({
        category_name: assessment_category,
      })
    )
      .then(unwrapResult)
      .then((response: any) => {
        if (response.survey.uuid) {
          if (refresh_token) {
            dispatch(loadUser({ refresh_token }))
              .then(unwrapResult)
              .then(() => {
                history.push(`/${url_to_category}/${response.survey.uuid}/`);
              });
          }
        }
      })
      .catch((error) => { });
  };

  return (
    <>
      <HeaderProfile />
      <div className={classes.root}>
        <Container component="main" maxWidth="lg">
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            className={classes.congratulationsText}
          >
            <Box fontWeight={800}>Welcome to the main page</Box>
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={classes.questionnaireDescription}
          >
            Please choose below action you want.
          </Typography>
          <div className={clsx(classes.mainButtonContainer)}>
            <div className={clsx(classes.buttonGroupContainer)}>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={startCoreHandler}
              >
                START CORE
                <br />
                CONTROLS ASSESSMENT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={coreReportHandler}
              >
                OPEN CORE
                <br />
                CONTROLS ASSESSMENT REPORT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={usersProfile}
              >
                USER PROFILES
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/remediationplan`)}
              >
                REMEDIATION PLAN
              </Button>
                
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/docmanagement`)}
              >
                DOCUMENT MANAGEMENT
              </Button>

            </div>
            <div className={clsx(classes.buttonGroupContainer)}>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={startTechnicalHandler}
              >
                START TECHNICAL
                <br />
                CONTROLS ASSESSMENT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={technicalReportHandler}
              >
                OPEN TECHNICAL
                <br />
                CONTROLS ASSESSMENT REPORT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/riskmanagement`)}
              >
                RISK MANAGEMENT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/soa`)}
              >
                Statement of Applicability
              </Button>
            </div>
          </div>
          <CoreAssessmentModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            setResetSurvey={setResetSurvey}
            assessmentType={assessmentType}
          />
        </Container>
      </div>
    </>
  );
}

export default MainPage;
