import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { editRisk } from "../../api/risk/editRisk";

export const riskEdit = createAsyncThunk(
  "risk/addRisk",
  async (
    {
      id,
      access_token,
      risk_status,
      risk_group,
      name,
      description,
      vulnerability,
      likelihood,
      likelihood_justification,
      impact,
      impact_justification,
      risk_rating,
      existing_controls,
      risk_response,
      residual_risk_rating,
      related_project,
      risk_owner,
      risk_review_date,
      comments,
      risk_closure_justification,
      iso_control,
    }: any,
    thunkAPI
  ) => {
    try {
      return await editRisk(
        id,
        access_token,
        risk_status,
        risk_group,
        name,
        description,
        vulnerability,
        likelihood,
        likelihood_justification,
        impact,
        impact_justification,
        risk_rating,
        existing_controls,
        risk_response,
        residual_risk_rating,
        related_project,
        risk_owner,
        risk_review_date,
        comments,
        risk_closure_justification,
        iso_control
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  id: number;
  risk_status: string;
  risk_group: string;
  name: string;
  description: string;
  vulnerability: string;
  likelihood: string;
  likelihood_justification: string;
  impact: string;
  impact_justification: string;
  risk_rating: string;
  existing_controls: string;
  risk_response: string;
  residual_risk_rating: string;
  related_project: string;
  risk_owner: number;
  risk_review_date: string;
  comments: string;
  risk_closure_justification: string;
};

const initialState: initialStateUsersList = {
  id: 0,
  risk_status: "",
  risk_group: "",
  name: "",
  description: "",
  vulnerability: "",
  likelihood: "",
  likelihood_justification: "",
  impact: "",
  impact_justification: "",
  risk_rating: "",
  existing_controls: "",
  risk_response: "",
  residual_risk_rating: "",
  related_project: "",
  risk_owner: 0,
  risk_review_date: "",
  comments: "",
  risk_closure_justification: "",
};

const editRiskEdit = createSlice({
  name: "editRisk",
  initialState,
  reducers: {
    riskEditInit: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(riskEdit.fulfilled, (state, { payload }) => {
      state.id = payload.id;
      state.risk_status = payload.risk_status;
      state.risk_group = payload.risk_group;
      state.name = payload.name;
      state.description = payload.description;
      state.vulnerability = payload.vulnerability;
      state.likelihood = payload.likelihood;
      state.likelihood_justification = payload.likelihood_justification;
      state.impact = payload.impact;
      state.impact_justification = payload.impact_justification;
      state.risk_rating = payload.risk_rating;
      state.existing_controls = payload.existing_controls;
      state.risk_response = payload.risk_response;
      state.residual_risk_rating = payload.residual_risk_rating;
      state.related_project = payload.related_project;
      state.risk_owner = payload.risk_owner;
      state.risk_review_date = payload.risk_review_date;
      state.comments = payload.comments;
      state.risk_closure_justification = payload.risk_closure_justification;
    });
  },
});

export const { riskEditInit } = editRiskEdit.actions;

export default editRiskEdit.reducer;
