import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useAppDispatch } from "../../redux/store";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import editbtn from "../../assets/editbtn.svg";
import EditRisk from "./EditRIsk";
import classes from "*.module.css";

const useStyles = makeStyles((theme) => ({}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid lightgrey",
      padding: theme.spacing(1, 1, 1, 1),
    },
    body: {
      fontSize: 16,
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      padding: theme.spacing(1.5, 3, 1.5, 2),
      color: (props: any) =>
        props.riskRating === "Low"
          ? "#4FDE8B"
          : props.riskRating === "Medium"
          ? "#FFC700"
          : props.riskRating === "High"
          ? "#FA6B6B"
          : props.riskRating === "Critical"
          ? "#D73636"
          : "#333",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#D6EAF8 ",
      },
      cursor: "pointer",
    },
  })
)(TableRow);

const TableRowRisk = ({
  row,
  selectedMatrix,
  impact3_3,
  impact4_4,
  impact5_5,
  likelihood3_3,
  likelihood4_4,
  likelihood5_5,
}: any) => {
  const [riskEditState, setRiskEditState] = useState(false);

  const handleEditRisk = () => {
    setRiskEditState(!riskEditState);
  };

  // console.log(row,"JJJJJJ")

  return (
    <>
      <StyledTableRow onClick={handleEditRisk}>
        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.risk_group}</StyledTableCell>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.risk_status}</StyledTableCell>
        <StyledTableCell>{row.likelihood}</StyledTableCell>
        <StyledTableCell>{row.impact}</StyledTableCell>
        <StyledTableCell riskRating={row.risk_rating}>
          {row.risk_rating}
        </StyledTableCell>
        <StyledTableCell>{row.existing_controls}</StyledTableCell>
        <StyledTableCell>{row.residual_risk_rating}</StyledTableCell>
        <StyledTableCell>{row.risk_response}</StyledTableCell>
        <StyledTableCell>{row.related_project}</StyledTableCell>
        <StyledTableCell>{row.risk_owner_name}</StyledTableCell>
        <StyledTableCell>{row.risk_review_date}</StyledTableCell>
      </StyledTableRow>
      <EditRisk
        riskEditState={riskEditState}
        setRiskEditState={setRiskEditState}
        row={row}
        selectedMatrix={selectedMatrix}
        impact3_3={impact3_3}
        impact4_4={impact4_4}
        impact5_5={impact5_5}
        likelihood3_3={likelihood3_3}
        likelihood4_4={likelihood4_4}
        likelihood5_5={likelihood5_5}
      />
    </>
  );
};

export default TableRowRisk;
