import { server_url } from "../../types/constants";
import axios from "axios";

export async function getAllDomains(access_token: string) {
  const url = `${server_url}/rm/get/user_domain/`;
  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
