import { server_url } from "../../types/constants";
import axios from "axios";

export async function updateSoaData(access_token:string,
        id:number,
        applicability:string,
        control_owner:string,
        implementation_status:string,
        domain_name:string,
        question_id:string,
        main_title:string,
        control_id:string,
        control_name:string,
        control_description:string,
        justification:string,
        applicable_documents:string,
        comments:string,
        user:number,
        source_for_inclusion:string
    ) {
  const url = `${server_url}/rm/update/seed_soa/${id}/`;
  try {
    const response = await axios({
      method: "patch",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data:{
        applicability:applicability,
        control_owner:control_owner,
        implementation_status:implementation_status,
        domain_name:domain_name,
        question_id:question_id,
        main_title:main_title,
        control_id:control_id,
        control_name:control_name,
        control_description:control_description,
        justification:justification,
        applicable_documents:applicable_documents,
        comments:comments,
        user:user,
        source_for_inclusion:source_for_inclusion,
      }
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}