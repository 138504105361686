import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import { useAppDispatch } from "../../redux/store";
import { usersListFetch } from "../../redux/slices/usersListSlice";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { riskEdit } from "../../redux/slices/editRiskSlice";
import AddDialog from "../UsersProfile/AddDialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import AddRiskGroup from "./addRiskGroup";
import AddRiskProject from "./addRiskProject";
import { riskGroupFetch } from "../../redux/slices/riskGroupSlice";
import { projectListFetch } from "../../redux/slices/projectListSlice";
import goTo from "../../assets/goTo.svg";
import Confirmation from "./confirmationModal";
// import { riskMatrixFetch } from "../../redux/slices/riskMatrixGetSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 1000,
    paddingLeft: 150,
    paddingRight: 150,
    paddingTop: 50,
    paddingBottom: 100,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  riskGroupDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 13,
  },
  riskDescriptionDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 13,
  },
  textFieldTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#2C2C2C",
  },
  textFieldSmall: {
    flex: 1,
    maxWidth: 250,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldDate: {
    flex: 1,
    maxWidth: 227,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldLarge: {
    flex: 1,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },

  dropdownSelect: {
    borderRadius: 8,
  },
  btnDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 55,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 59,
    maxWidth: 237,
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 59,
    maxWidth: 237,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
  dropdownDiv: {
    // maxWidth: 373,
    // flex: 1,
  },
  dropdown: {
    width: 250,
  },
  dropdownSmall: {
    width: 195,
  },
  dropdownFull: {
    width: 395,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  controlCore: {
    display : "flex",
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const EditRisk = ({
  riskEditState,
  setRiskEditState,
  row,
  selectedMatrix,
  impact3_3,
  impact4_4,
  impact5_5,
  likelihood3_3,
  likelihood4_4,
  likelihood5_5,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const access_token = localStorage.getItem("Access_Token");
  const risk_id = row.id;

  const [addGroupState, setAddGroupState] = useState(false);
  const [addProjectState, setAddProjectState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [toggleViewEditState, setToggleViewEditState] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [isoChecked, setIsoChecked ] = useState([])
  const [errorState, setErrorState] = useState({
    risk_group: false,
    vulnerability: false,
    likelihood_justification: false,
    existing_controls: false,
    related_project: false,
    description: false,
    impact_justification: false,
    risk_closure_justification: false,
    risk_owner: false,
    impact: false,
    likelihood: false,
    iso_control: false,
  });

  const [riskEditDetails, setRiskEditDetails] = useState({
    risk_status: row.risk_status,
    risk_group: "",
    name: row.name,
    description: row.description,
    vulnerability: row.vulnerability,
    likelihood: row.likelihood,
    likelihood_justification: row.likelihood_justification,
    impact: row.impact,
    impact_justification: row.impact_justification,
    existing_controls: row.existing_controls,
    risk_response: row.risk_response,
    residual_risk_rating: row.residual_risk_rating,
    related_project: "",
    comments: row.comments,
    risk_closure_justification: row.risk_closure_justification,
    risk_review_date: new Date(row.risk_review_date) as any,
    risk_rating: row.risk_rating,
    risk_owner: "",
    risk_uuid: row.risk_uuid,
    iso_control: row.iso_control,
  });



  useEffect(() => {
    if (riskEditState) {


      let ids: any = {};
      if (usersList && usersList.length) {
        usersList.filter((value) => {
          if (value.email === row?.risk_owner) {
            ids["risk_owner"] = value.id;
          }
        });
      }

      if (groupList && groupList.length) {
        groupList.filter((value) => {
          if (value.name === row?.risk_group) {
            ids["risk_group"] = value.id;
          }
        });
      }
      if (projectList && projectList.length) {
        projectList.filter((value) => {
          if (value.name === row?.related_project) {
            ids["related_project"] = value.id;
          }
        });
      }
      setRiskEditDetails({
        risk_status: row.risk_status,
        // risk_group: "",
        name: row.name,
        description: row.description,
        vulnerability: row.vulnerability,
        likelihood: row.likelihood,
        likelihood_justification: row.likelihood_justification,
        impact: row.impact,
        impact_justification: row.impact_justification,
        existing_controls: row.existing_controls,
        risk_response: row.risk_response,
        residual_risk_rating: row.residual_risk_rating,
        // related_project: "",
        comments: row.comments,
        risk_closure_justification: row.risk_closure_justification,
        risk_review_date: new Date(row.risk_review_date) as any,
        risk_rating: row.risk_rating,
        // risk_owner: "",
        risk_uuid: row.risk_uuid,
        iso_control: row.iso_control,
        ...ids,
      });
    }
  }, [riskEditState]);

  useEffect(() => {
    const temp = currentMatrixGet?.filter(
      (value) =>
        value.impact === riskEditDetails.impact &&
        value.Likelihood === riskEditDetails.likelihood
    );
    setRiskEditDetails({ ...riskEditDetails, risk_rating: temp[0]?.result });
  }, [riskEditDetails.impact, riskEditDetails.likelihood]);

  const usersList = useSelector(
    (state: RootState) => state.usersList.allUsersList
  );

  const projectList = useSelector(
    (state: RootState) => state.projectList.allprojectlist
  );

  const groupList = useSelector(
    (state: RootState) => state.riskGroup.allRiskGroups
  );

  const updateUserList = useSelector(
    (state: RootState) => state.addNewUser.email
  );

  const updateRiskProject = useSelector(
    (state: RootState) => state.addRiskProject.id
  );

  const updateRiskGroup = useSelector(
    (state: RootState) => state.addRiskGroup.id
  );

  const userRole = useSelector((state: RootState) => state.user.role);

  const currentMatrixGet = useSelector(
    (state: RootState) => state?.riskMatrixGet.riskMatrixList
  );

  const isoControls = useSelector((state: RootState) => state.isoControls.isoControlsList);


  useEffect(() => {
    if (riskEditState) {
      dispatch(usersListFetch({ access_token }));
    }
  }, [updateUserList]);

  useEffect(() => {
    if (riskEditState) {
      dispatch(projectListFetch({ access_token }));
    }
  }, [updateRiskProject]);

  useEffect(() => {
    if (riskEditState) {
      dispatch(riskGroupFetch({ access_token }));
    }
  }, [updateRiskGroup]);

  useEffect(() => {
    if (!riskEditState) {
      handleCancel();
    }
  }, [riskEditState]);

  const handleCancel = () => {
    setToggleViewEditState(false);
    setErrorState({
      risk_group: false,
      vulnerability: false,
      likelihood_justification: false,
      existing_controls: false,
      related_project: false,
      description: false,
      impact_justification: false,
      risk_closure_justification: false,
      risk_owner: false,
      likelihood: false,
      impact: false,
      iso_control: false,
    });

    setRiskEditDetails({
      impact: row.impact,
      likelihood: row.likelihood,
      risk_status: row.risk_status,
      risk_group: row.risk_group,
      name: row.name,
      description: row.description,
      vulnerability: row.vulnerability,
      likelihood_justification: row.likelihood_justification,
      impact_justification: row.impact_justification,
      existing_controls: row.existing_controls,
      risk_response: row.risk_response,
      residual_risk_rating: row.residual_risk_rating,
      related_project: row.related_project,
      comments: row.comments,
      risk_closure_justification: row.risk_closure_justification,
      risk_review_date: new Date(row.risk_review_date) as any,
      risk_rating: row.risk_rating,
      risk_owner: riskEditDetails.risk_owner,
      risk_uuid: row.risk_uuid,
      iso_control: row.iso_control,
    });

    setRiskEditState(false);
  };

  const handleSubmit = async () => {
    if (
      risk_id &&
      riskEditDetails.risk_status &&
      riskEditDetails.risk_group &&
      riskEditDetails.name &&
      riskEditDetails.description &&
      riskEditDetails.vulnerability &&
      riskEditDetails.likelihood &&
      riskEditDetails.likelihood_justification &&
      riskEditDetails.impact &&
      riskEditDetails.impact_justification &&
      riskEditDetails.existing_controls &&
      riskEditDetails.risk_response &&
      riskEditDetails.residual_risk_rating &&
      riskEditDetails.related_project &&
      // riskEditDetails.comments &&
      // riskEditDetails.risk_closure_justification &&
      riskEditDetails.risk_review_date &&
      riskEditDetails.risk_rating &&
      riskEditDetails.risk_owner &&
      riskEditDetails.iso_control
    ) {
      const payload = {
        id: risk_id,
        access_token: access_token,
        risk_status: riskEditDetails.risk_status,
        risk_group: riskEditDetails.risk_group,
        name: riskEditDetails.name,
        description: riskEditDetails.description,
        vulnerability: riskEditDetails.vulnerability,
        likelihood: riskEditDetails.likelihood,
        likelihood_justification: riskEditDetails.likelihood_justification,
        impact: riskEditDetails.impact,
        impact_justification: riskEditDetails.impact_justification,
        existing_controls: riskEditDetails.existing_controls,
        risk_response: riskEditDetails.risk_response,
        residual_risk_rating: riskEditDetails.residual_risk_rating,
        related_project: riskEditDetails.related_project,
        comments: riskEditDetails.comments,
        risk_closure_justification: riskEditDetails.risk_closure_justification,
        risk_review_date: moment(riskEditDetails.risk_review_date).format(
          "YYYY-MM-DD"
        ),
        risk_rating: riskEditDetails.risk_rating,
        risk_owner: parseInt(riskEditDetails.risk_owner),
        iso_control: riskEditDetails.iso_control,
      };

      if (payload) {
        await dispatch(riskEdit(payload));
        setToggleViewEditState(false);
        setErrorState({
          risk_group: false,
          vulnerability: false,
          likelihood_justification: false,
          existing_controls: false,
          related_project: false,
          description: false,
          impact_justification: false,
          risk_closure_justification: false,
          risk_owner: false,
          likelihood: false,
          impact: false,
          iso_control: false,
        });
        setRiskEditState(false);
      }
    }
  };

  const handleValidate = async () => {
    const errors: any = {};
    let validated = true;

    if (riskEditDetails.risk_group === "Add New Group") {
      errors["risk_group"] = true;
      validated = false;
    }
    if (!riskEditDetails.vulnerability) {
      errors["vulnerability"] = true;
      validated = false;
    }
    if (!riskEditDetails.likelihood_justification) {
      errors["likelihood_justification"] = true;
      validated = false;
    }
    if (!riskEditDetails.existing_controls) {
      errors["existing_controls"] = true;
      validated = false;
    }
    if (riskEditDetails.related_project === "Add New Project") {
      errors["related_project"] = true;
      validated = false;
    }
    if (!riskEditDetails.description) {
      errors["description"] = true;
      validated = false;
    }
    if (!riskEditDetails.impact_justification) {
      errors["impact_justification"] = true;
      validated = false;
    }
    if (riskEditDetails.risk_owner === "Add New Risk Owner") {
      errors["risk_owner"] = true;
      validated = false;
    }
    if (
      riskEditDetails.risk_status === "Closed" &&
      !riskEditDetails.risk_closure_justification
    ) {
      errors["risk_closure_justification"] = true;
      validated = false;
    }
    if (!riskEditDetails.impact) {
      errors["impact"] = true;
      validated = false;
    }
    if (!riskEditDetails.likelihood) {
      errors["likelihood"] = true;
      validated = false;
    }
    if (!riskEditDetails.iso_control) {
      errors["iso_control"] = true;
      validated = false;
    }
    if (validated === true) {
      setErrorState({
        risk_group: false,
        vulnerability: false,
        likelihood_justification: false,
        existing_controls: false,
        related_project: false,
        description: false,
        impact_justification: false,
        risk_closure_justification: false,
        risk_owner: false,
        likelihood: false,
        impact: false,
        iso_control: false,
      });

      handleSubmit();
    }

    setErrorState({ ...errors });
  };

  const handleCloseEditRisk = () => {
    setRiskEditState(false);
  };

  const handleChangeLikelihood = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskEditDetails({
      ...riskEditDetails,
      likelihood: event.target.value as string,
    });
  };

  const handleChangeImpact = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRiskEditDetails({
      ...riskEditDetails,
      impact: event.target.value as string,
    });
  };

  const handleChangeRiskStatus = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskEditDetails({
      ...riskEditDetails,
      risk_status: event.target.value as string,
    });
  };

  const handleChangeRiskResponse = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskEditDetails({
      ...riskEditDetails,
      risk_response: event.target.value as string,
    });
  };

  const handleChangeResidualRiskRating = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskEditDetails({
      ...riskEditDetails,
      residual_risk_rating: event.target.value as string,
    });
  };

  const handletoggleViewEditState = () => {
    setToggleViewEditState(true);
  };

  const handleSelectRiskGroup = (event: any) => {
    if (event?.target?.innerText === "Add New Group") {
      addGroup();
    }
  };

  const handleSelectRelatedProject = (event: any) => {
    if (event?.target?.innerText === "Add New Project") {
      addProject();
    }
  };

  const handleSelectAddRiskOwner = (event: any) => {
    if (event?.target?.innerText === "Add New Risk Owner") {
      setEditState(true);
    }
  };

  const handleChangeRiskOwner = (event: any) => {
    // console.log(value.email, "riskkkkkowner", value);

    if (event.target.value === "Add New Risk Owner") {
      setRiskEditDetails({
        ...riskEditDetails,
        risk_owner: event.target.value as string,
      });
      setEditState(true);
    } else {
      setRiskEditDetails({
        ...riskEditDetails,
        risk_owner: event.target.value as string,
      });
    }
  };

  const handleChangeISOControls = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskEditDetails({
      ...riskEditDetails,
      iso_control: event.target.value as string[],
    });
  };

  const handleChangeRiskGroup = (event: any) => {
    if (event.target.value === "Add New Group") {
      setRiskEditDetails({
        ...riskEditDetails,
        risk_group: event.target.value as string,
      });
      addGroup();
    } else {
      setRiskEditDetails({
        ...riskEditDetails,
        risk_group: event.target.value as string,
      });
    }
  };

  const handleChangeRelatedProject = (event: any) => {
    if (event.target.value === "Add New Project") {
      setRiskEditDetails({
        ...riskEditDetails,
        related_project: event.target.value as string,
      });
      addProject();
    } else {
      setRiskEditDetails({
        ...riskEditDetails,
        related_project: event.target.value as string,
      });
    }
  };

  const addGroup = () => {
    setAddGroupState(true);
  };

  const addProject = () => {
    setAddProjectState(true);
  };

  const handleOpenConfirm = (confirm: boolean) => {
    setConfirmation(confirm);
  };

  const handleCheckChanges = () => {
    let isConfirm = false;
    if (toggleViewEditState) {
      isConfirm = true;
      handleOpenConfirm(true);
    } else {
      isConfirm = false;
      handleOpenConfirm(false);
      handleCloseEditRisk();
    }
    return isConfirm;
  };
  const handleChecked=(val:any)=>{
    let isAvailable=riskEditDetails.iso_control.indexOf(val.description)
    // console.log(isAvailable,'WWWWWWWWWWWWWWWW')
    if(isAvailable >=0 && val?.description){
      let updatededIso=riskEditDetails.iso_control.filter((value:any)=>value!==val.description)
      setRiskEditDetails((prev)=>{
        return{
          ...prev,
          iso_control:[...updatededIso]
        }
      })
    }
    else{
      setRiskEditDetails((prev)=>{
        return{
          ...prev,
          iso_control:[...prev.iso_control,val.description]
        }
      })
    }
   
   
  }
  const getChecked=(value:any)=>{
    // console.log(value, "checked")
    return riskEditDetails?.iso_control?.filter((val:any)=>val===value?.description).length>0 ? true :false
  }

  // console.log("HHHHHHH",riskEditDetails.iso_control)

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog
          onClose={handleCheckChanges}
          aria-labelledby="customized-dialog-title"
          open={riskEditState}
          onBackdropClick={() => handleCheckChanges()}
          onEscapeKeyDown={() => handleCheckChanges()}
          maxWidth={"xl"}
        >
          <DialogContent>
            <div className={classes.modalContainer}>
              <div className={classes.titleCenter}>
                <Typography className={classes.profileTitle}>
                  {toggleViewEditState ? "Edit Risk" : row.name}
                </Typography>
              </div>

              {/*================================ first row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk group
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.dropdown}
                    >
                      {/* <InputLabel>Risk group</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required
                        inputProps={
                          toggleViewEditState
                            ? {}
                            : {
                              readOnly: true,
                            }
                        }
                        error={errorState.risk_group}
                        onChange={handleChangeRiskGroup}
                        onClick={handleSelectRiskGroup}
                        className={classes.dropdownSelect}
                        value={riskEditDetails.risk_group}
                      // label="Risk group"
                      >
                        <MenuItem value={`Add New Group`}>
                          Add New Group
                        </MenuItem>
                        {groupList.map((value) => (
                          <MenuItem value={`${value.id}`} key={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errorState.risk_group && (
                        <FormHelperText style={{ color: "red" }}>
                          Please fill this field
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk Name
                    </Typography>
                    <TextField
                      className={classes.textFieldSmall}
                      required
                      id="outlined-required"
                      label={toggleViewEditState ? "Enter risk name" : null}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={riskEditDetails.name}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Risk status
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        {/* {toggleViewEditState && (
                          <InputLabel>Risk Status</InputLabel>
                        )} */}
                        <Select
                          // labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          inputProps={
                            toggleViewEditState
                              ? {}
                              : {
                                readOnly: true,
                              }
                          }
                          required
                          value={riskEditDetails.risk_status}
                          onChange={handleChangeRiskStatus}
                          className={classes.dropdownSelect}
                        // label={toggleViewEditState ? "Risk Status" : null}
                        >
                          <MenuItem value={"Active"}>Active</MenuItem>
                          <MenuItem value={"Closed"}>Closed</MenuItem>
                          {/* <MenuItem value={"Deleted"}>Deleted</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div style={{ marginBottom: 12 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Description
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      error={errorState.description}
                      helperText={
                        errorState.description ? "Please fill this field" : null
                      }
                      label={
                        toggleViewEditState ? "Enter risk description" : null
                      }
                      multiline
                      rows={6}
                      InputProps={
                        toggleViewEditState
                          ? {}
                          : {
                            readOnly: true,
                          }
                      }
                      variant="outlined"
                      value={riskEditDetails.description}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #D2D2D2",
                  width: 916,
                  marginTop: 37,
                  marginBottom: 40,
                }}
              />

              {/*================================ second row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div style={{ marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Vulnerability
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      label={toggleViewEditState ? "Enter vulnerability" : null}
                      variant="outlined"
                      error={errorState.vulnerability}
                      helperText={
                        errorState.vulnerability
                          ? "Please fill this field"
                          : null
                      }
                      InputProps={
                        toggleViewEditState
                          ? {}
                          : {
                            readOnly: true,
                          }
                      }
                      multiline
                      rows={8}
                      value={riskEditDetails.vulnerability}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          vulnerability: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div style={{ marginTop: 50 }}>
                    <div className={classes.riskGroupDiv}>
                      <div>
                        <Typography className={classes.textFieldTitle}>
                          Impact
                        </Typography>
                      </div>
                      <div className={classes.dropdownDiv}>
                        <FormControl
                          variant="outlined"
                          className={classes.dropdown}
                        >
                          {/* {toggleViewEditState && <InputLabel>Impact</InputLabel>} */}
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            inputProps={
                              toggleViewEditState
                                ? {}
                                : {
                                  readOnly: true,
                                }
                            }
                            required
                            value={riskEditDetails?.impact}
                            error={errorState.impact}
                            onChange={handleChangeImpact}
                            className={classes.dropdownSelect}
                          // label={toggleViewEditState ? "Impact" : null}
                          >
                            {selectedMatrix === "json_3_3" &&
                              impact3_3.map((val: any, index: any) => (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              ))}
                            {selectedMatrix === "json_4_4" &&
                              impact4_4.map((val: any, index: any) => (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              ))}
                            {selectedMatrix === "json_5_5" &&
                              impact5_5.map((val: any, index: any) => (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              ))}
                          </Select>
                          {errorState.impact && (
                            <FormHelperText style={{ color: "red" }}>
                              Please fill this field
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Likelihood
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        {/* <InputLabel>
                          {toggleViewEditState ? "Likelihood" : null}
                        </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          inputProps={
                            toggleViewEditState
                              ? {}
                              : {
                                readOnly: true,
                              }
                          }
                          required
                          value={riskEditDetails.likelihood}
                          error={errorState.likelihood}
                          onChange={handleChangeLikelihood}
                          // label={toggleViewEditState ? "Likelihood" : null}
                          className={classes.dropdownSelect}
                        >
                          {selectedMatrix === "json_3_3" &&
                            likelihood3_3.map((val: any, index: any) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                          {selectedMatrix === "json_4_4" &&
                            likelihood4_4.map((val: any, index: any) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                          {selectedMatrix === "json_5_5" &&
                            likelihood5_5.map((val: any, index: any) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                        </Select>
                        {errorState.likelihood && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk rating
                    </Typography>
                    <TextField
                      className={classes.textFieldSmall}
                      required
                      id="outlined-required"
                      InputProps={{
                        readOnly: true,
                      }}
                      // label={toggleViewEditState ? "risk rating" : null}
                      variant="outlined"
                      value={riskEditDetails.risk_rating}
                    />
                  </div>
                </div>
              </div>

              <hr
                style={{
                  border: "1px solid #D2D2D2",
                  width: 916,
                  marginTop: 37,
                  marginBottom: 40,
                }}
              />

              {/*================================ third row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div style={{ marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Likelihood justification
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      multiline
                      rows={5}
                      id="outlined-required"
                      InputProps={
                        toggleViewEditState
                          ? {}
                          : {
                            readOnly: true,
                          }
                      }
                      label={
                        toggleViewEditState ? "likelihood justification" : null
                      }
                      variant="outlined"
                      error={errorState.likelihood_justification}
                      helperText={
                        errorState.likelihood_justification
                          ? "Please fill this field"
                          : null
                      }
                      value={riskEditDetails.likelihood_justification}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          likelihood_justification: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div style={{ marginBottom: 27 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Impact justification
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      multiline
                      rows={5}
                      id="outlined-required"
                      error={errorState.impact_justification}
                      helperText={
                        errorState.impact_justification
                          ? "Please fill this field"
                          : null
                      }
                      InputProps={
                        toggleViewEditState
                          ? {}
                          : {
                            readOnly: true,
                          }
                      }
                      label={
                        toggleViewEditState ? "Impact justification" : null
                      }
                      variant="outlined"
                      value={riskEditDetails.impact_justification}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          impact_justification: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <hr
                style={{
                  border: "1px solid #D2D2D2",
                  width: 916,
                  marginTop: 37,
                  marginBottom: 40,
                }}
              />

              {/*================================ 4 row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div style={{ marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Existing controls
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      label={
                        toggleViewEditState ? "Enter existing controls" : null
                      }
                      variant="outlined"
                      error={errorState.existing_controls}
                      helperText={
                        errorState.existing_controls
                          ? "Please fill this field"
                          : null
                      }
                      InputProps={
                        toggleViewEditState
                          ? {}
                          : {
                            readOnly: true,
                          }
                      }
                      multiline
                      rows={5}
                      value={riskEditDetails.existing_controls}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          existing_controls: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div style={{ flex: 1, marginLeft: 113, marginTop: 56 }}>
                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Risk response
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownSmall}
                      >
                        {/* {toggleViewEditState && (
                            <InputLabel>Risk Response</InputLabel>
                          )} */}
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          inputProps={
                            toggleViewEditState
                              ? {}
                              : {
                                readOnly: true,
                              }
                          }
                          required
                          value={riskEditDetails.risk_response}
                          onChange={handleChangeRiskResponse}
                          className={classes.dropdownSelect}
                        // label={toggleViewEditState ? "Risk Response" : null}
                        >
                          <MenuItem value={"Accept"}>Accept</MenuItem>
                          <MenuItem value={"Remediate"}>Remediate</MenuItem>
                          <MenuItem value={"Transfer"}>Transfer</MenuItem>
                          <MenuItem value={"Avoid"}>Avoid</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Residual risk rating
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownSmall}
                      >
                        {/* {toggleViewEditState && (
                          <InputLabel>Residual Risk Rating</InputLabel>
                        )} */}
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          inputProps={
                            toggleViewEditState
                              ? {}
                              : {
                                readOnly: true,
                              }
                          }
                          required
                          value={riskEditDetails.residual_risk_rating}
                          onChange={handleChangeResidualRiskRating}
                          className={classes.dropdownSelect}
                        // label={
                        //   toggleViewEditState ? "Residual Risk Rating" : null
                        // }
                        >
                          <MenuItem value={"Low"}>Low</MenuItem>
                          <MenuItem value={"Medium"}>Medium</MenuItem>
                          <MenuItem value={"High"}>High</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              <hr
                style={{
                  border: "1px solid #D2D2D2",
                  width: 916,
                  marginTop: 37,
                  marginBottom: 40,
                }}
              />

              {/*================================ 5 row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div style={{ marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        ISO Controls
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownFull}
                      >
                        <InputLabel>ISO Statndard Controls</InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          // defaultValue={riskEditDetails.iso_control[0]}
                          required
                          multiple
                          onChange={handleChangeISOControls}
                          className={classes.dropdownSelect}
                          value={riskEditDetails.iso_control}
                          renderValue={(selected) => (selected as string[]).join(', ')}
                          error={errorState.iso_control}
                          inputProps={
                            toggleViewEditState
                              ? {}
                              : {
                                readOnly: true,
                              }
                          }
                          label="ISO Controls"
                        >
                          {isoControls?.map((value, index) => (
                            <div className={classes.controlCore}>
                              <Checkbox
                                checked={getChecked(value)}
                                onChange={()=>handleChecked(value)}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                              <MenuItem
                                value={`${value.description}`}
                                key={index}
                              >
                                {`${value.control_id} ${' - '}${value.description}`}
                              </MenuItem>
                            </div>
                          ))}
                        </Select>
                        {errorState.iso_control && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1, marginRight: 113 }}></div>
              </div>

              <hr
                style={{
                  border: "1px solid #D2D2D2",
                  width: 916,
                  marginTop: 37,
                  marginBottom: 40,
                }}
              />

              {/*================================ 6 row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div style={{ marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Related Project
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <FormControl
                      variant="outlined"
                      className={classes.dropdown}
                    >
                      {/* <InputLabel>Risk project</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required
                        inputProps={
                          toggleViewEditState
                            ? {}
                            : {
                              readOnly: true,
                            }
                        }
                        error={errorState.related_project}
                        onChange={handleChangeRelatedProject}
                        onClick={handleSelectRelatedProject}
                        value={riskEditDetails.related_project}
                        className={classes.dropdownFull}
                      // label="Risk Status"
                      >
                        <MenuItem value={`Add New Project`}>
                          Add New Project
                        </MenuItem>
                        {projectList.map((value) => (
                          <MenuItem value={`${value.id}`} key={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errorState.related_project && (
                        <FormHelperText style={{ color: "red" }}>
                          Please fill this field
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div style={{ marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Risk owner
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownFull}
                      >
                        {/* {toggleViewEditState && (
                          <InputLabel>Risk Owner</InputLabel>
                        )} */}
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          inputProps={
                            toggleViewEditState && userRole !== "Risk owner"
                              ? {}
                              : {
                                readOnly: true,
                              }
                          }
                          required
                          value={riskEditDetails.risk_owner}
                          error={errorState.risk_owner}
                          onChange={handleChangeRiskOwner}
                          onClick={handleSelectAddRiskOwner}
                          className={classes.dropdownSelect}
                        // label={toggleViewEditState ? "Risk Owner" : null}
                        >
                          <MenuItem value="Add New Risk Owner">
                            Add New Risk Owner
                          </MenuItem>
                          {usersList
                            ?.filter(
                              (val) => val.is_active === true
                              // && val.role === "Administrator" ||
                              // val.role === "Risk owner" ||
                              // val.role === "Risk manager"
                            )
                            ?.map((user, index) => (
                              <MenuItem value={`${user.id}`} key={index}>
                                {user.email}
                              </MenuItem>
                            ))}
                        </Select>
                        {errorState.risk_owner && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              {/*================================ 7 row ==========================*/}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113, marginTop: 30 }}>
                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Comments
                    </Typography>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      id="outlined-required"
                      label={toggleViewEditState ? "Comments" : null}
                      variant="outlined"
                      InputProps={
                        toggleViewEditState
                          ? {}
                          : {
                            readOnly: true,
                          }
                      }
                      multiline
                      rows={7}
                      value={riskEditDetails.comments}
                      onChange={(e) =>
                        setRiskEditDetails({
                          ...riskEditDetails,
                          comments: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div style={{ marginBottom: 22, marginTop: 20 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Risk review date
                      </Typography>
                      <KeyboardDatePicker
                        className={classes.textFieldDate}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        inputProps={
                          toggleViewEditState
                            ? {}
                            : {
                              readOnly: true,
                            }
                        }
                        // label={
                        //   toggleViewEditState ? "Enter risk review date" : null
                        // }
                        format="YYYY-MM-DD"
                        value={riskEditDetails.risk_review_date}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) =>
                          setRiskEditDetails({
                            ...riskEditDetails,
                            risk_review_date: date,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: 19 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Remediation plan
                      </Typography>
                      <div
                        style={{
                          borderRadius: 8,
                          border: "1px solid #D2D2D2",
                          height: 50,
                          width: 225,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(`/remediationplan/${row.risk_uuid}`)
                        }
                      >
                        <Typography style={{ color: "#1071BA", fontSize: 16 }}>
                          <u>Remediation controls</u>
                        </Typography>
                        <img src={goTo} alt="Link" style={{ marginLeft: 15 }} />
                      </div>
                    </div>
                  </div>
                  {riskEditDetails.risk_status === "Closed" && (
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Risk closure justification
                      </Typography>
                    </div>
                  )}

                  {riskEditDetails.risk_status === "Closed" && (
                    <div className={classes.riskGroupDiv}>
                      <TextField
                        className={classes.textFieldLarge}
                        required
                        id="outlined-required"
                        error={errorState.risk_closure_justification}
                        helperText={
                          errorState.risk_closure_justification
                            ? "Please fill this field"
                            : null
                        }
                        InputProps={
                          toggleViewEditState
                            ? {}
                            : {
                              readOnly: true,
                            }
                        }
                        multiline
                        rows={3}
                        label={
                          toggleViewEditState
                            ? "risk closure justification"
                            : null
                        }
                        variant="outlined"
                        value={riskEditDetails.risk_closure_justification}
                        onChange={(e) =>
                          setRiskEditDetails({
                            ...riskEditDetails,
                            risk_closure_justification: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.btnDiv}>
                {toggleViewEditState ? (
                  <Button className={classes.saveBtn} onClick={handleValidate}>
                    SAVE
                  </Button>
                ) : (
                  <Button
                    className={classes.saveBtn}
                    onClick={handletoggleViewEditState}
                  >
                    EDIT
                  </Button>
                )}
                <Button className={classes.cancelBtn} onClick={handleCancel}>
                  CANCEL
                </Button>
              </div>
              <AddDialog
                setEditState={setEditState}
                editState={editState}
                addNewRiskOwner={true}
              />
              <AddRiskGroup
                addGroupState={addGroupState}
                setAddGroupState={setAddGroupState}
              />
              <AddRiskProject
                addProjectState={addProjectState}
                setAddProjectState={setAddProjectState}
              />
              <Confirmation
                confirmation={confirmation}
                setConfirmation={setConfirmation}
                handleCancel={handleCancel}
              />
            </div>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default EditRisk;
