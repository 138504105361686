import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createToken } from "../../api/addUser/addUser";

export const tokenFetch = createAsyncThunk(
  "addUsers/fetchToken",
  async ({ email }: any, thunkAPI) => {
    try {
      return await createToken(email);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  token: string;
  email: string;
};

const initialState: initialStateUsersList = {
  token: "",
  email: "",
};

const addUserToken = createSlice({
  name: "createToken",
  initialState,
  reducers: {
    token: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(tokenFetch.fulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.email = payload.email;
    });
  },
});

export const { token } = addUserToken.actions;

export default addUserToken.reducer;
