import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUploadDocData } from "../../api/documentManagement/getUploadDocData";

export const uploadDataCategory = createAsyncThunk(
  "/getuploaddata",
  async ({ access_token,type }: any, thunkAPI) => {
    console.log(type,'typpppppppppppppp');
    try {
      return await getUploadDocData(access_token,type);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export interface uploadlist {
  name: string;
  question_id: string;
  iso_control: string;
  control_name:string;
}
export type initialStateDocument = {
  uploadList: Array<uploadlist>;
};
const initialState: initialStateDocument = {
  uploadList : [],
};
const uploadListGet = createSlice({
  name: "uploadListSlice",
  initialState,
  reducers: {
    getuploadlist: () => initialState,
  },
  // here getting an error if getuploaddocdata api does not provide payload "unhandeled error" 
  extraReducers: (builder) => {
    builder.addCase(uploadDataCategory.fulfilled, (state, { payload }) => {
      state.uploadList = [];
      
      payload?.forEach((value: any) => {
        let nameWithId = value.name.split('@');
        state?.uploadList?.push({
          name: nameWithId[0],
          question_id: value.question_id,
          iso_control: value.iso_control,
          control_name:value.control_name,
        });
      });
    });
  },
});
export const { getuploadlist } = uploadListGet.actions;
export default uploadListGet.reducer;