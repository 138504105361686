import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@mui/material/Divider";
import { Typography, Grid, Button } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@material-ui/core/IconButton";
import menu from "../../assets/menu.svg";
import box from "../../assets/boxView.svg"
import selectedList from "../../assets/selectedList.svg"
import selectedBox from "../../assets/selectedBox.svg"
import TabularData from "./TabularData";
import GridTable from "./GridTable";
import { useAppDispatch } from "../../redux/store";
import { documentFilterStatus } from "../../redux/slices/filterDocumentSlice";
import { uploadDocStatus } from "../../redux/slices/uploadFileSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import BoxView from "./BoxView";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { uploadDataCategory } from "../../redux/slices/getUploadDocSlice"
import { uploadBtnDataCategory } from "../../redux/slices/getUploadBtnSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { AnySchema } from "yup";
import { documentStatus } from "../../redux/slices/getDocumentManagementSlice";
import InputLabel from '@mui/material/InputLabel';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 


// i have to dispatch uploadDataCategory on dropdown select
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    justifyContent: "space-between",
    marginTop: "17px",
    marginBottom: "17px",
    width: "100%",
    paddingLeft: "3%",
    paddingRight:"4%",
    fontSize: "18px !important",
  },
  checkBoxLabel: {
    "& .MuiCheckbox-root": {
      marginRight: "13px",
    },
    "& .css-ahj2mt-MuiTypography-root": {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "137%",
    fontFamily: "poppins",
    color: "black",
    }
  },

  listDesign: {
    justifyContent:"space-between",
    alignItems: "center",
  },
 
  modalBox: {
    width: 400,
    backgroundColor: '#FFFFFF',
    border: '2px solid #000',
    padding: '32px',
    marginTop: '23%',
    marginLeft: '50%',
    borderRadius: '8px',
    height: '220px',
    transform: 'translate(-50%, -50%)',
  },
  typemodaltitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#1071BA',
    textAlign: 'center',
    marginBottom: '23px',
  },
  typemodalsubtitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#1071BA',
  },
  typeBtnContainer: {
    marginTop: '40px',
    marginLeft: '93px',
  },
  uploadBtn: {
    backgroundColor: '#1071BA',
    width: '115px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    color:'#FFFFFF',
  },
  resetBtn: {
    width: '115px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    marginLeft: '5px',
    color: '#1071BA',

  },
  upload: {
    backgroundColor: 'white',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    marginBottom: '6px',
  },
  fileuploadbtn: {
    backgroundColor: '#1071BA',
    width: '115px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    marginLeft: '9%',
    color: '#FFFFFF',
  },
  docuploadbtn: {
    width: '200px',
    height: '54px',
   border: '1px solid #1071BA',
   boxSizing: 'border-box',
  borderRadius: '8px',
  color: '#1071BA',
  }

}));
const PageDetail = ({ docupdate }: any) => {
  const [pageView, setPageView] = useState("list");
  const [filter, setFilter] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const optionNo = useSelector((state: RootState) => state.submenulist.value);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(" ");
  const [userId, setUserId] = useState(" ");
  const token = localStorage.getItem("Access_Token");
  const [submitCredential, setSubmitCredential] = useState(
    {
      access_token: token,
      document_file: null,
      question_id: " ",
    }
  )
  // purpose of this code to resolve the error which faced while i choose another type after filtring in any particular type 
  useEffect(() => {
    console.log(optionNo, "asdfghjkllll", filter);
    setFilter([]);
  }, [optionNo]);
  // purpose done by above
  const handleFilter = (status: any) => {
    let statusExist = false;
    filter?.find((val) => {
      if (val === status) {
        statusExist = true;
      }
    });
    if (statusExist) {
      const a = [...filter];
      const b = a.filter((val) => val != status);
      setFilter(b);
    } else {
      const a = [...filter];
      a.push(status);
      setFilter(a);
    }
  };
  useEffect(() => {
    const payload = {
      access_token: docupdate.access_token,
      applicability: docupdate.applicability,
      status: [...filter],
      type: docupdate.type,
    };

    dispatch(documentFilterStatus({ ...payload }));

  }, [filter]);
  const domains = useSelector((state: RootState) => state.documentlist.docList);
  const uploadCredential = useSelector((state: RootState) => state.uploadlist.uploadList);
  //uploadbuttonlist state is useless
  // state for modal for upload document section
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const [typeModal, setTypeModal] = useState(false);
  const handleTypeModalOpen = () => setTypeModal(true);
  const handleTypeModalClose = () => setTypeModal(false);
  const [replaceData, setReplaceData] = useState({
    access_token: token,
    type: " ",
  });

  const uploadType = (type: string) => {
    setModalOpen(false)
    setTypeModal(true)
    setReplaceData({ ...replaceData, type: type });
  }
  useEffect(() => {
    if (replaceData.type.length > 1) {
      dispatch(uploadDataCategory({ ...replaceData }))
    }
  }, [replaceData])

  const [uploadLinkModal, setUploadLinkModal] = useState(false);
  const handleLinkModalOpen = () => {
    handleTypeModalClose()
    setUploadLinkModal(true);
    setUploadPayload({ ...uploadPayload, upload_type: 'upload' });
    setUnlinkedPayload({...unlinkedPayload, upload_type: 'upload'});
    // dispatch(uploadBtnDataCategory({ ...replaceData })) //delet
    setdocUploadType({...docuploadType,replace:false});
  }
  const handleLinkModalClose = () => setUploadLinkModal(false);

  const [replaceModal, setReplaceModal] = useState(false);
  const replaceModalOpen = () => {
    handleTypeModalClose();
    setReplaceModal(true);
    setReplacePayload({ ...replacePayload, upload_type: 'replace' })
  }
  const replaceModalClose = () => setReplaceModal(false);

  const [replaceName, setReplaceName] = useState(''); // this state purpose is only to show selected item in dropdown textfield
  const [linkName,setLinkName] = useState('');  // this state purpose is only to show selected item in dropdown textfield
  const [isReplace, setIsReplace] = useState(false);
  const [islinkUpload, setIslinkUpload] = useState(false);
  const [replacePayload, setReplacePayload] = useState({
    access_token: token,
    question_id: '',
    iso_control: '',
    upload_type: '',
    file: '',
  });

  const [uploadPayload, setUploadPayload] = useState({
    access_token: token,
    file: '',
    upload_type: '',
    question_id: '',
  })
  const [unlinkedPayload,setUnlinkedPayload] = useState(
    {
      access_token: token,
      file:'',
      upload_type: '',
    }
  )
  console.log(unlinkedPayload,'uuuuuuuuuuuuuuu');
  const [isNewFileSelected,setNewFileSelected] = useState(false);
  const [isNewFileUploaded,setNewFileUploaded] = useState(false);
  const getreplacePayload = (value: any) => {
    setReplacePayload({ ...replacePayload, question_id: value.question_id, iso_control: value.iso_control })
    setNewFileSelected(true);
  }
  const [isContorlLinkedSelected,setControlLinkSeleted] = useState(false);
  const getlinkUploadPayload = (value: any) => {
    setUploadPayload({...uploadPayload,question_id:value.question_id})
    setControlLinkSeleted(true)
  }
  // handleChange is only for the purpose to show selected dropdown name in dropdown textfield 
  const handleChange = (event: any) => {
    setReplaceName(event.target.value);
  };
  const handleLinkName = (event:any) => {
    setLinkName(event.target.value);
  } 
  // upload modal is starting from here 
  const [uploadModal, setUploadModal] = useState(false);
  const handleUploadModalOpen = () => setUploadModal(true);
  const handleUploadModalClose = () => setUploadModal(false);
  const replaceAction = () => {
    replaceModalClose();
    setReplaceModal(false);
    handleUploadModalOpen();
  }
  const cancelReplace = () =>{
    replaceModalClose();
    setNewFileSelected(false);
  }
  const onFileChange = (event: any) => {
    {!docuploadType.replace?
      (
        docuploadType.link?setUploadPayload({...uploadPayload,file: event.target.files[0] }):
      setUnlinkedPayload({...unlinkedPayload,file: event.target.files[0]})
      ):
    setReplacePayload({ ...replacePayload, file: event.target.files[0] })}
     setNewFileUploaded(true);
  };
  const uploadFile = () => {
    handleUploadModalClose();
    setNewFileUploaded(false);
      {!docuploadType.replace?
        (
          docuploadType.link?
        dispatch(uploadDocStatus({ ...uploadPayload }))   
         // here i have to write some extra block of code for rerendering the api
          :
          dispatch(uploadDocStatus({ ...unlinkedPayload }))
          ):
          setIsReplace(false);
          dispatch(uploadDocStatus({ ...replacePayload }))
            .then(unwrapResult)
            .then((response: any) => {
              setIsReplace(true);
            })
            .catch((err) => {
              setIsReplace(false);
            });}
     
  }
  useEffect(() => {
    if (isReplace) {
      dispatch(documentStatus({ ...docupdate }));
    }
  }, [isReplace]);

 // for link and dont link upload 
 const [linkUploadModal, setLinkUploadModal] = useState(false);  
 const linkUploadModalOpen = () => setLinkUploadModal(true);
 const linkUploadModalClose = () => setLinkUploadModal(false);
 const linkUpload = () =>
 {
  handleLinkModalClose();
  linkUploadModalOpen();
  setdocUploadType({...docuploadType,link:true});
 }
 const unLinkeUpload = () =>
 {
  handleLinkModalClose();
  handleUploadModalOpen();
 }
 const [docuploadType,setdocUploadType] = useState({
     replace:true,
     link:false,
 })
const linkUploadAction =() =>
{
  linkUploadModalClose();
  handleUploadModalOpen();
  setControlLinkSeleted(false)
}
  return (
    <div className={classes.root}>
      <Divider />
      <Grid container className={classes.header}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.includes("Approved") ? true : false}
                  onChange={(e) => handleFilter("Approved")}
                />
              }
              label="Approved"
              className={classes.checkBoxLabel}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.includes("Published") ? true : false}
                  onChange={(e) => handleFilter("Published")}
                />
              }
              label="Published"
              className={classes.checkBoxLabel}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.includes("in_draft") ? true : false}
                  onChange={(e) => handleFilter("in_draft")}
                />
              }
              label=" In Draft"
              className={classes.checkBoxLabel}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    e.target.checked ? handleFilter("View all") : " "
                  }
                />
              }
              label="View all"
              className={classes.checkBoxLabel}
            />
          </FormGroup>
        </Grid >
        <Grid item  xs={4} sm={4} md={4} lg={4} xl={4}>
          <Grid container className={classes.listDesign}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <IconButton onClick={(e) => setPageView("box")}>
                {pageView ==="box" ? <img src={selectedBox} alt="boxSelected"></img>: <img src={box} alt="box"></img>}
                    
               {/* box view is here  */}
              </IconButton>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <IconButton onClick={(e) => setPageView("list")}>
              {pageView ==="list" ?  <img src={selectedList} alt="menuicon"></img>: <img src={menu} alt="menuicon"></img>}
  
              </IconButton>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

              <Button variant="outlined" component="span" onClick={handleModalOpen} className={classes.docuploadbtn}>
                Upload
              </Button>
              </Grid>
          </Grid>
        </Grid>
              <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modalBox}>
                  <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                    Choose document type to upload
                  </Typography>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Choose document type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    // value={age}
                    // label="Age"
                    // onChange={handleChange}
                       label="Choose document type"
                    >
                      <MenuItem value={'Policies'} onClick={() => uploadType('Policies')}>Policy</MenuItem>
                      <MenuItem value={'Procedures'} onClick={() => uploadType('Procedures')}>Procedure</MenuItem>
                      <MenuItem value={'Standarts'} onClick={() => uploadType('Standards')}>Standard</MenuItem>
                      <MenuItem value={'Guidelines'} onClick={() => uploadType('Guidelines')}>Guideline</MenuItem>
                      <MenuItem value={'Meeting'} onClick={() => uploadType('Meeting')}>Meeting minutes</MenuItem>
                      <MenuItem value={'Others'} onClick={() => uploadType('Others')}>Other documents</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Modal>
              {/* modal no.2 is starting from here */}
                {/*not disabling */}
              <Modal
                open={typeModal}
                onClose={handleTypeModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modalBox}>
                  {/* <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                    Replace or upload?
                  </Typography> */}
                  <Typography className={classes.typemodalsubtitle} variant="h6" component="h2">
                    Do you want to replace exising document or upload a new document?
                  </Typography>
                  <Grid container className={classes.typeBtnContainer}>
                    <Grid item>
                      <Button variant="contained" className={classes.uploadBtn} onClick={handleLinkModalOpen}>Upload</Button>
                    </Grid>
                    <Button variant="outlined" className={classes.resetBtn} onClick={replaceModalOpen}>Replace</Button>
                    <Grid item>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              {/* modal 3 for upload  */}

              <Modal
                open={uploadLinkModal}
                onClose={handleLinkModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modalBox}>
                  <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                  Do you want to link document to the security control?
          </Typography>
                  
                  <Grid container className={classes.typeBtnContainer}>
                    <Grid item>
                      <Button variant="contained" className={classes.uploadBtn} onClick={linkUpload}>Link</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" className={classes.resetBtn} onClick={unLinkeUpload}>Don’t link</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              {/* modal 3.1 for upload  */}
              <Modal
                open={linkUploadModal}
                onClose={linkUploadModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modalBox}>
                  <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                  Choose the control to link
                  </Typography>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Choose the control to link</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={linkName}
                      onChange={handleLinkName}
                      label="Choose the control"
                    >
                      {
                        uploadCredential.map((value, index) => {
                          return (
                            <MenuItem value={value.control_name} onClick={(e) => getlinkUploadPayload(value)}>{value.control_name}</MenuItem>
                          )
                        })                           
                      }
                    </Select>
                  </FormControl>
                  <Grid container className={classes.typeBtnContainer}>
                    <Grid item>
                      <Button variant="contained" className={classes.uploadBtn} onClick={
                        (e)=>{
                          isContorlLinkedSelected?
                          linkUploadAction(): toast.warning('please select file first');
                           } 
                      }>Upload</Button>
                      {/* //agar koi link click nhi kiya h aur upload kr rha to usko ek error dega aur upload button click nhi hoga */}
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" className={classes.resetBtn} onClick={linkUploadModalClose}>Cancel</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              {/* Modal 4 for replace document */}

              <Modal
                open={replaceModal}
                onClose={replaceModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modalBox}>
                  <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                    Choose the document name to replace
                  </Typography>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Choose the document name to replace</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={replaceName}
                      onChange={handleChange}
                      label="Choose document name"
                    >
                      {
                        uploadCredential.map((value, index) => {
                          return (
                            <MenuItem value={value.name} onClick={(e) => getreplacePayload(value)}>{value.name}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <Grid container className={classes.typeBtnContainer}>
                    <Grid item>
                      <Button variant="contained" className={classes.uploadBtn} 
                      onClick={(e) =>{
                        isNewFileSelected?
                        replaceAction():toast.warning('please select file first');}}
                      >Replace</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" className={classes.resetBtn} onClick={cancelReplace}>Cancel</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              {/* Modal 5 for upload to replace  document */}
              <Modal
                open={uploadModal}
                onClose={handleUploadModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modalBox}>
                  <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                    {/* should be condition for link upload text  */}
                    {!docuploadType.replace?(docuploadType.link?'upload new document with security control link':'upload new document without security control link'):
                    'Upload new document to replace previous one'}
                    {/* Upload new document to replace previous one */}
                  </Typography>

                  <Grid container className={classes.typeBtnContainer}>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>

                      <input type="file" onChange={onFileChange} id="raised-button-file" style={{ display: "none" }} />
                      <label htmlFor="raised-button-file">
                        <Button variant="contained" className={classes.upload} component="span">
                          Choose file to upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                      <Button variant="contained" className={classes.fileuploadbtn} 
                       onClick={(e) =>{
                        isNewFileUploaded?
                        uploadFile():toast.warning('please select file first');}}
                      >Upload</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>             
            </Grid>  
      <Divider />
        
      {pageView === "box" ?
        domains ?
          <BoxView docCredential={docupdate} />
          : null
        : domains ?
          <GridTable docCredential={docupdate} />
          : null
      }
    </div>
  );
};
export default PageDetail;
