import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emailSend } from "../../api/forgotPassword/emailSend";

export const userEmailSubmit = createAsyncThunk(
  "forgotPassword/emailSubmit",
  async ({ email }: any, thunkAPI) => {
    try {
      return await emailSend(email);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUserEmail = {
  status: string;
  email: string;
};

const initialState: initialStateUserEmail = {
  status: "",
  email: "",
};

const userSubmitEmail = createSlice({
  name: "emailSubmit",
  initialState,
  reducers: {
    resetEmail: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(userEmailSubmit.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.email = payload.email;
    });
  },
});

export const { resetEmail } = userSubmitEmail.actions;

export default userSubmitEmail.reducer;
