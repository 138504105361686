import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsersList } from "../../api/usersList/usersList";

export const usersListFetch = createAsyncThunk(
  "users/list",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getUsersList(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface userslist {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  title: string;
  role: string;
  is_active: boolean;
}

export type initialStateUsersList = {
  allUsersList: Array<userslist>;
};

const initialState: initialStateUsersList = {
  allUsersList: [],
};

const UsersList = createSlice({
  name: "usersListSlice",
  initialState,
  reducers: {
    resetUsersList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(usersListFetch.fulfilled, (state, { payload }) => {
      state.allUsersList = [];
      // for (let i = 0; i < payload.length; i++) {
      //   state.allUsersList.push({
      //     email: payload[i].email,
      //     id: payload[i].id,
      //     first_name: payload[i].first_name,
      //     last_name: payload[i].last_name,
      //     title: payload[i].organization_details.title,
      //     role: payload[i].organization_details.role,
      //     is_active: payload[i].is_active,
      //   });
      // }
      payload.forEach((value:any) => {
        state.allUsersList.push({
          email: value.email,
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
          title: value.organization_details.title,
          role: value.organization_details.role,
          is_active: value.is_active,
        });
      });
    });
  },
});

export const { resetUsersList } = UsersList.actions;

export default UsersList.reducer;
