import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setRiskAdded } from "../../api/risk/riskAdded";

export const setRiskAddedStatus = createAsyncThunk(
  "/setRiskAddedStatus",
  async ({ new_risk_added,access_token }: any, thunkAPI) => {
    try {
      return await setRiskAdded(new_risk_added,access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateRiskAdded = {
  new_risk_added: boolean;
};

const initialState: initialStateRiskAdded = {
  new_risk_added: true,
};

const setRiskStatusAdd = createSlice({
  name: "setRiskAddedStatus",
  initialState,
  reducers: {
    resetRiskAddedStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(setRiskAddedStatus.fulfilled, (state, { payload }) => {
      // state.new_risk_added = payload.new_risk_added;
    });
  },
});

export const { resetRiskAddedStatus } = setRiskStatusAdd.actions;

export default setRiskStatusAdd.reducer;
