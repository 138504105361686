import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useAppDispatch } from "../../redux/store";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import EditControl from "./editControl";

// import EditRisk from "./EditRIsk";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid lightgrey",
      padding: theme.spacing(1, 1, 1, 1),
    },
    body: {
      fontSize: 16,
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      padding: theme.spacing(1.5, 3, 1.5, 2),
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#D6EAF8 ",
      },
      cursor: "pointer",
    },
  })
)(TableRow);

const RemediationRow = ({ row, key }: any) => {
  const [editControlState, setEditControlState] = useState(false);

  return (
    <>
      <StyledTableRow onClick={() => setEditControlState(true)}>
        <StyledTableCell>{row.risk_name}</StyledTableCell>
        <StyledTableCell>{row.risk_response}</StyledTableCell>
        <StyledTableCell>{row.risk_rating}</StyledTableCell>
        <StyledTableCell>{row.control_group_name}</StyledTableCell>
        <StyledTableCell>{row.control_name}</StyledTableCell>
        <StyledTableCell>{row.mitigation}</StyledTableCell>
        <StyledTableCell>{row.Status}</StyledTableCell>
        <StyledTableCell>{row.due_date}</StyledTableCell>
        <StyledTableCell>{row.control_owner_name}</StyledTableCell>
      </StyledTableRow>
      <EditControl
        editControlState={editControlState}
        setEditControlState={setEditControlState}
        controlName={row.control_name}
        row={row}
      />
    </>
  );
};

export default RemediationRow;
