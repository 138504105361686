import { server_url } from "../../types/constants";
import axios from "axios";

export async function submitFilterResponseApi(
  response_answer: string,
  id_survey: string,
  id_filter: string,
  access_token: string,
  uuid: string,
  response_explanation?: string
) {
  const url = `${server_url}/survey/filter/${uuid}/`;
  try {
    const response = await axios.put(
      url,
      {
        response: response_answer,
        id_survey,
        id_filter,
        response_explanation,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
