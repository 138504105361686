import { server_url } from "../../types/constants";
import axios from "axios";

export async function editRisk(
  id: number,
  access_token: string,
  risk_status: string,
  risk_group: string,
  name: string,
  description: string,
  vulnerability: string,
  likelihood: string,
  likelihood_justification: string,
  impact: string,
  impact_justification: string,
  risk_rating: string,
  existing_controls: string,
  risk_response: string,
  residual_risk_rating: string,
  related_project: string,
  risk_owner: number,
  risk_review_date: string,
  comments: string,
  risk_closure_justification: string,
  iso_control: string,
) {
  const url = `${server_url}/rm/update/risk/${id}/`;
  try {
    const response = await axios({
      method: "patch",
      url: url,
      data: {
        risk_status: risk_status,
        risk_group: risk_group,
        name: name,
        description: description,
        vulnerability: vulnerability,
        likelihood: likelihood,
        likelihood_justification: likelihood_justification,
        impact: impact,
        impact_justification: impact_justification,
        risk_rating: risk_rating,
        existing_controls: existing_controls,
        risk_response: risk_response,
        residual_risk_rating: residual_risk_rating,
        related_project: related_project,
        risk_owner: risk_owner,
        risk_review_date: risk_review_date,
        comments: comments,
        risk_closure_justification: risk_closure_justification,
        iso_control: iso_control,
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
