import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {restoreRow} from "../../api/documentManagement/restoreRow";
import {mulipleRestore} from "../../api/documentManagement/multipleRestore";

export const restoreMultipleRow = createAsyncThunk(
  "/RestoreSliceStatus",
  async ({ access_token, question_id}: any, thunkAPI) => {
    try {
      return await mulipleRestore(access_token,question_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const restoreMultipleReducer = createSlice({
  name: "reducerMultipleRestore",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(restoreMultipleRow.fulfilled, (state, { payload }) => {
      state.value = '';
    });
  },
});

export default restoreMultipleReducer.reducer;