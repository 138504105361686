import { server_url } from "../../types/constants";
import axios from "axios";

export async function riskMatrixSet(access_token: string, matrix: string) {
  const url = `${server_url}/rm/matrix_settings`;
  try {
    const response = await axios({
      method: "post",
      url: url,
      data: {
        matrix_choice: matrix,
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function riskMatrixGet(access_token: string) {
  const url = `${server_url}/rm/get/matrix`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function allRiskMatrixGet(access_token: string) {
  const url = `${server_url}/rm/get_all/matrix`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function matrixUpdate(access_token: string, type: string, matrix: any,) {
  const url = `${server_url}/rm/update/matrix`;
  try {
    const response = await axios({
      method: "patch",
      url: url,
      data: {
        type: type,
        data: matrix,
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function riskMatrixSettingCheck(access_token: string) {
  const url = `${server_url}/rm/matrix_settings`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}