import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useAppDispatch } from "../../redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { setRiskAddedStatus } from "../../redux/slices/setRiskAddedSlice";
import { riskAddedStatus } from "../../redux/slices/getRiskAddedSlice";

const NewRiskAddNotification = ({
  newRiskNotification,
  setNewRiskNotification,
}: any) => {
  const dispatch = useAppDispatch();
  const access_token = localStorage.getItem("Access_Token");

  const handleClose = () => {
    setNewRiskNotification(false);
  };

  const handleOK = () => {
    const payload = {
      new_risk_added: false,
      access_token: access_token,
    };
    dispatch(setRiskAddedStatus(payload))
      .then(unwrapResult)
      .then((response: any) => {
        dispatch(riskAddedStatus({ access_token }));
        handleClose();
      })
      .catch((error) => {
        handleClose();
      });
  };

  return (
    <>
      <Dialog
        open={newRiskNotification}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "We have added new risks based on assessment results. Please review and assess them."
          }
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleOK} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewRiskAddNotification;
