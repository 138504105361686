import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateUserInfo } from "../../api/usersList/updateUserInfo";

export const userUpdateInfo = createAsyncThunk(
  "users/updateInfo",
  async ({ access_token, title, role, is_active, id }: any, thunkAPI) => {
    try {
      return await updateUserInfo(access_token, title, role, is_active, id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  title: string;
  role: string;
  is_active: any;
  user_id: number;
};

const initialState: initialStateUsersList = {
  title: "",
  role: "",
  is_active: null,
  user_id: 0,
};

const setStateWithUserInfo = (state: initialStateUsersList, payload: any) => {
  state.title = payload.title;
  state.role = payload.role;
  state.user_id = payload.user_id;
  state.is_active = payload.is_active;
};

const UpdateInfo = createSlice({
  name: "usersUpdateInfo",
  initialState,
  reducers: {
    userInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(userUpdateInfo.fulfilled, (state, { payload }) => {
      setStateWithUserInfo(state, payload);
      state.title = payload.title;
      state.role = payload.role;
      state.user_id = payload.user_id;
      state.is_active = payload.is_active;
    });
  },
});

export const { userInfo } = UpdateInfo.actions;

export default UpdateInfo.reducer;
