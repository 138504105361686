import { server_url } from "../../types/constants";
import axios from "axios";

export async function filterDocument(
  access_token: string,
  applicability: string,
  status:Array<string>[],
  type: any,

) {
  const url = `${server_url}/rm/filter/doc/`;
  try {
    const response = await axios( {
        method : "post",
        url : url,
        data : {
            applicability:applicability,
            status:status,
            type:type,
        },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
