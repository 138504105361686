import { server_url } from "../../types/constants";
import axios from "axios";

export async function downloadSelDoc(
  file_name:any,
  user_id:string,
) {
  const url = `${server_url}/rm/download_all/doc/`;
  // need to display all question id data
   try {
    const response = await axios({
        method: "post",
        url: url,
        data: {
            file_name : file_name,
            user_id : user_id,
        },
        responseType: 'blob',
  
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
   
  } catch (err) {
    throw err.response.data;
  }
}
