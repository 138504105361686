import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@material-ui/core";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import edit from "../../assets/edit.svg";
import edit2 from '../../assets/ediit.svg';
import del from "../../assets/delete.svg";
import download from "../../assets/download.svg";
import IconButton from "@material-ui/core/IconButton";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useAppDispatch } from "../../redux/store";
import { documentDownloadStatus } from "../../redux/slices/downloadDocSlice";
import { documentDelStatus } from "../../redux/slices/documentDeleteSlice";
import { documentStatus } from "../../redux/slices/getDocumentManagementSlice";
import { updateDocStatus } from "../../redux/slices/statusUpdateSlice";
import { restoreOneRow } from "../../redux/slices/restoreRowSlice";
import { updateNameStatus } from "../../redux/slices/nameUpdateSlice";
import { downloadMultipleDoc } from "../../redux/slices/downloadSelDocSlice";
import { restoreMultipleRow } from "../../redux/slices/multipleRestoreSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5% 5% 0 5%'
  },
  checkBoxLabel: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "black",
    "& .MuiCheckbox-root": {
      marginRight: "13px",
    }
  },
  cardsroot: {
    margin: '5% 5% 0 3%'
  },
  actionbtn: {
    background: "#1071BA",
    border: "1px solid #1071BA",
    boxSizing: "border-box",
    borderRadius: "8px",
    width:"200px",
    height:"54px",
    color: "white",
  },
  restoreBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "137%",
    color: "#1071BA",
  },
  dialogBox: {
    
    "& .MuiDialog-container-314": {
      height:'90%',
      "& .MuiDialog-paperWidthSm-320": {
        minWidth: '284px',
        minHeight: '248px',
        "& .MuiList-padding-336": {
          paddingTop: '24%',
          paddingLeft: '32%',
          fontSize: '20px',
        }
      }
    }
  },
  statusChanger:{
    width: '170px',
    height: '138px',
    position:"absolute",
    // right:"65rem !important",
    backgroundColor: "white",
    zIndex: 999,
    border: "1px solid #D2D2D2",
    borderRadius: "8px",
    top:"435px",
    // marginLeft: "28px",
  },
  statusTopChanger:{
    width: '170px',
    height: '138px',
    position:"absolute",
    // right:"65rem !important",
    backgroundColor: "white",
    zIndex: 999,
    border: "1px solid #D2D2D2",
    borderRadius: "8px",
    top:"560px",
    // marginLeft: "28px",
  },
  changeStatusBtn : {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: "150%",
    color: "#000000",
  }

})
);
//updateNameStatus have to dispatch for updating name 
export default function BoxView({ docCredential }: any) {
  const domains = useSelector((state: RootState) => state.documentlist.docList);
  let filterd = useSelector((state: RootState) => state.documentfilter.docList);
  let optionNo = useSelector((state: RootState) => state.submenulist.value);
  let uploadCredential = useSelector((state: RootState) => state.uploadlist);
  console.log(domains, 'dddddddddd');
  console.log(uploadCredential, 'uuuuuuuuuuuu');
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setcount] = useState(0);
  const rowsPerPage = 8;

  const begin = (currentPage - 1) * rowsPerPage;
  const end = begin + rowsPerPage;

  useEffect(() => {
    if (domains) {
      const data = Math.ceil(domains.length / rowsPerPage);
      setcount(data);
    }
  }, [domains]);
  const handleChange = (e: any, p: any) => {
    setCurrentPage(p);
  }
  // all api state satarting from here 
  const [downloadDoc, setDownloadDoc] = useState({
    file_name: " ",
    user_id: " ",
  });

  useEffect(() => {
    if (downloadDoc.file_name.length > 1) {
      dispatch(documentDownloadStatus({ ...downloadDoc }));
    }
  }, [downloadDoc]);
  const token = localStorage.getItem("Access_Token");
  const [deleteRow, setDeleteRow] = useState({
    access_token: token,
    question_id: " ",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    if (deleteRow.question_id.length > 2) {
      setIsSuccess(false);
      console.log("delete dispatch is calling");
      dispatch(documentDelStatus({ ...deleteRow }))
        .then(unwrapResult)
        .then((response: any) => {
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsSuccess(false);
        });
    }
  }, [deleteRow]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isSuccess]);

  // edit status 
  const [open, setOpen] = useState(0);
  console.log(open,'oooooooooooooooooooo');
  const statusEditAction = (question_id: any,index:any) => {
    setOpen(index);     // changing setOpen true to index no.
    setUpdateStatus({
      ...updateStatus,
      question_id: question_id,
    });
  }
  const handleClose = () => {                      // now handleClose will perform on 0
    setOpen(0);
  };
   const [updateStatus, setUpdateStatus] = useState({
    access_token: token,
    question_id: " ",
    status: " ",
  });
  console.log(updateStatus, 'updatedstatus');
  const [statusSuccess, setStatusSuccess] = useState(false);
  useEffect(() => {
    if (updateStatus.status.length > 2) {
      setStatusSuccess(false);
      dispatch(updateDocStatus({ ...updateStatus }))
        .then(unwrapResult)
        .then((response: any) => {
          setStatusSuccess(true);
        })
        .catch((err) => {
          setStatusSuccess(false);
        });
    }
  }, [updateStatus.status]);
  useEffect(() => {
    if (statusSuccess) {
      dispatch(documentStatus({ ...docCredential }));
      setOpen(0);
    }
  }, [statusSuccess]);

  const [restoreRow, setRestoreRow] = useState({
    access_token: token,
    question_id: " ",
  });
  // for single reseting 
  const [isReset, setReset] = useState(false);

  useEffect(() => {
    if (restoreRow.question_id.length > 2) {
      setReset(false);
      dispatch(restoreOneRow({ ...restoreRow }))
        .then(unwrapResult)
        .then((response: any) => {
          setReset(true);
        })
        .catch((err) => {
          setReset(false);
        });
    }
  }, [restoreRow]);
  useEffect(() => {
    if (isReset) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isReset]);

  const [oldName, setOldName] = useState('');
  const [renameIndex, setRenameIndex] = useState(-1);
  const [isRenameSuccess, setRenameSuccess] = useState(false);
  const updateName = (prevName: string, index: number) => {
    setOldName(prevName);
    setRenameIndex(index);
  }
  const handleUpdateName = (e: any, question_id: string) => {
    e.preventDefault();
    setRenameIndex(-1);
    setRenamePayload({ ...renamePayload, question_id: question_id })
    if (renamePayload.question_id.length > 1)  // condition because it get delay to set question id...before dispatching
    {
      setRenameSuccess(false);
      dispatch(updateNameStatus({ ...renamePayload }))
        .then(unwrapResult)
        .then((response: any) => {
          setRenameSuccess(true);
        })
        .catch((err) => {
          setRenameSuccess(false);
        });
    }
  }
  useEffect(() => {
    if (isRenameSuccess) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isRenameSuccess]);

  const [renamePayload, setRenamePayload] = useState({
    access_token: token,
    question_id: '',
    name: '',
  })
  console.log(renamePayload, 'rrrrrrrrrrrrrrrrrrrrrrrrrr');
  // for restore all and for download all
  const [questionId, setQuestionId] = useState<string[]>([]);
  const [multipleName, setMultipleName] = useState<string[]>([]);
  const [ownerid, setOwnerId] = useState(" ");
  const [isMultipleReset, setMultipleReset] = useState(false);
  const storeMultiName = (question_id: any, ownerid: any) => {
    setOwnerId(ownerid);
    let statusExist = false;
    multipleName?.find((val: any) => {
      if (val === question_id) {
        statusExist = true;
      }
    });
    if (statusExist) {
      const a = [...multipleName];
      const b = a.filter((val) => val !== question_id);
      setMultipleName(b);
    } else {
      const a = [...multipleName];
      a.push(
        question_id
      );
      setMultipleName(a);
    }
  };
  const handleMultiDownload = () => {
    const payload = {
      file_name: multipleName,
      user_id: ownerid,
    };
    if (payload) {
      dispatch(downloadMultipleDoc(payload));
    }
  };
  const handleRestore = () => {
    const payload = {
      access_token: token,
      question_id: questionId,
    };
    if (payload) {
      setMultipleReset(false);
      dispatch(restoreMultipleRow(payload))
        .then(unwrapResult)
        .then((response: any) => {
          setMultipleReset(true);
        })
        .catch((err) => {
          setMultipleReset(false);
        });
    }
  };
  useEffect(() => {
    if (isMultipleReset) {
      dispatch(documentStatus({ ...docCredential }));
    }
  }, [isMultipleReset]);
  return (
    <Box sx={{ minWidth: 220 }} className={classes.cardsroot}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

        {(filterd < domains ? filterd : domains).slice(begin, end).map((item: any, index: any) => (

          <Grid item xs={2} sm={4} md={3} >
            <Card variant="outlined" style={{ height: '180px' }}>
              <CardContent>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    {index !== renameIndex ? (
                      <Typography variant="body2" >
                        {item.name}  <IconButton onClick={(e) => updateName(item.name, index)}>
                          <img src={edit} alt="editname"></img>
                        </IconButton>
                      </Typography>
                    ) :
                      <form onSubmit={(e) => handleUpdateName(e, item.question_id)}>
                        <input
                          type="text"
                          aria-label="empty textarea"
                          style={{ width: 200 }}
                          defaultValue={oldName}
                          onChange={(e) => { setRenamePayload({ ...renamePayload, name: e.target.value }) }}
                        />
                      </form>
                    }
                  </Box>
                  <FormGroup >
                    <FormControlLabel style={{ paddingLeft: '60%' }} control={<Checkbox
                      onChange={(e) => {
                        optionNo === 6
                          ? setQuestionId([...questionId, item.question_id])
                          : storeMultiName(item.question_id, item.ownerid);
                      }}
                    />} label="" className={classes.checkBoxLabel} />
                  </FormGroup>
                </Box>
                <Box sx={{ display: "flex" }} style={{ marginTop: '10%' }}>
                  <Box sx={{ flexGrow: 1 }} ><Typography variant="body2" >
                    {item.status}
                  </Typography>
                  </Box>
                  <Typography variant="body2" >
                    {item.owner}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }} style={{ marginTop: '5%' }}>
                  <Box sx={{ flexGrow: 1 }} style={{ marginTop: '5%' }} ><Typography variant="body2" >
                    {item.last_updated}
                  </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }} >
                    {
                      optionNo === 6 ? (
                        <>
                          <IconButton
                            onClick={(e) => {
                              setRestoreRow({
                                ...restoreRow,
                                question_id: item.question_id,
                              });
                            }}
                          >
                            <span className={classes.restoreBtn}>Restore</span>
                          </IconButton>
                        </>
                      ) : (
                        <>
                          {/* setOpen(true) */}
                          <IconButton onClick={() => statusEditAction(item.question_id,index+1)}>
                            <img src={edit2} alt="editicon"></img>
                          </IconButton>
                           
                          {/* <Dialog onClose={handleClose} open={open} className={classes.dialogBox}>
                            <List>
                              <ListItem
                                autoFocus
                                button
                                onClick={(e) => {
                                  console.log("edit is clicked");
                                  setUpdateStatus({
                                    ...updateStatus,
                                    status: "in_draft",
                                  });
                                }}
                              >
                                In draft
                              </ListItem>
                              <ListItem
                                autoFocus
                                button
                                onClick={(e) => {
                                  setUpdateStatus({
                                    ...updateStatus,
                                    status: "Approved",
                                  });
                                }}
                              >
                                Approved
                              </ListItem>
                              <ListItem
                                autoFocus
                                button
                                onClick={(e) => {
                                  setUpdateStatus({
                                    ...updateStatus,
                                    status: "Published",
                                  });
                                }}
                              >
                                Published
                              </ListItem>
                            </List>
                          </Dialog> */}
                        
                          {/* //download button startig from here */}
                          <IconButton
                            onClick={(e) => {
                              setDownloadDoc({
                                ...downloadDoc,
                                file_name: item.name,
                                user_id: item.ownerid,
                              });
                            }}
                          >
                            <img src={download} alt="download"></img>
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              setDeleteRow({
                                ...deleteRow,
                                question_id: item.question_id,
                              });
                            }}
                          >
                            <img src={del} alt="delete"></img>
                          </IconButton>
                        </>
                      )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

        ))}
         {/* status changer is starting */}
         {open ? <div
           id="basic-menu"
          //open/5 ===1?classes.statusTopChanger:
          className={classes.statusChanger}
          style={{marginLeft:`${open*20-20}%`}}
      >
        <Typography align="center">Set status as</Typography>
         <MenuItem 
          onClick={(e) => {
            setUpdateStatus({
              ...updateStatus,
              status: "In draft",
            });
          }
        }
          className = {classes.changeStatusBtn}
         >In draft</MenuItem>
         <MenuItem 
          onClick={(e) => {
            setUpdateStatus({
              ...updateStatus,
              status: "Approved",
            });
          }}>Approved</MenuItem>

         <MenuItem 
         onClick={(e) => {
          setUpdateStatus({
            ...updateStatus,
            status: "Published",
          });
        }}
         >Published</MenuItem>
         </div>:<span></span>}
      </Grid>
      <Box sx={{ display: "flex" }} style={{ marginTop: '5%' }}>
        <Button
          onClick={() =>
            optionNo === 6 ? handleRestore() : handleMultiDownload()
          }
          className={classes.actionbtn}
          variant="contained"
        >
          {optionNo === 6 ? "Restore all selected" : "Download"}
        </Button>

        <Box sx={{ m: 2 }}>
          <Pagination
            color="primary"
            size="small"
            count={count}
            page={currentPage}
            onChange={handleChange}
          />
        </Box>

      </Box>
    </Box>
  );
}