import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateSoaData } from "../../api/updateSoa/updateSoa";

export const updateSoaContent = createAsyncThunk(
  "/updateSoa",
  async ({ access_token,
        id, 
        applicability,
        control_owner,
        implementation_status,
        domain_name,
        question_id,
        main_title,
        control_id,
        control_name,
        control_description,
        justification,
        applicable_documents,
        comments,
        user,
        source_for_inclusion }: any, thunkAPI) => {
    try {
      return await updateSoaData(
        access_token,
        id, 
        applicability,
        control_owner,
        implementation_status,
        domain_name,
        question_id,
        main_title,
        control_id,
        control_name,
        control_description,
        justification,
        applicable_documents,
        comments,
        user,
        source_for_inclusion
        );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
 
};

const initialState: initialStateUsersList = {
 
};

const soaContentUpdate = createSlice({
  name: "updateSoaContentSlice",
  initialState,
  reducers: {
    soaUpdate: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateSoaContent.fulfilled, (state, { payload }) => {
      // state.error= payload.error,
   
    });
  },
});

export const { soaUpdate } = soaContentUpdate.actions;

export default soaContentUpdate.reducer;
