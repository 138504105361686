import React, { useEffect, useState } from "react";
import HeaderProfile from "../../layout/HeaderProfile";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import { updateMyProfile } from "../../redux/slices/updateProfileSlice";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import Card from "@material-ui/core/Card";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 100,
    marginLeft: 100,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    marginBottom: 30,
    marginTop: 60,
  },
  separation: {
    border: "1px solid #999999",
    height: 0.1,
    width: 500,
    marginBottom: 50,
  },
  cardDiv: {
    width: 734,
    height: 640,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },

  matrixCard: {
    width: 734,
    height: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },

  nameDiv: {
    width: 494,
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  leftDiv: {
    borderRadius: 8,
    border: "1px solid #D2D2D2",
    width: 237,
    height: 60,
    display: "flex",
    alignItems: "center",
  },
  rightDiv: {
    borderRadius: 8,
    border: "1px solid #D2D2D2",
    width: 237,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  texts: {
    marginLeft: 20,
    fontSize: 16,
    color: "#222222",
  },
  emailDiv: {
    borderRadius: 8,
    border: "1px solid #D2D2D2",
    width: 494,
    height: 60,
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
  statusText: {
    fontSize: 18,
    fontWeight: 500,
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
  },
  redDot: {
    height: 7,
    width: 7,
    backgroundColor: "#EB5757",
    borderRadius: "50%",
    marginRight: 50,
  },
  greenDot: {
    height: 7,
    width: 7,
    backgroundColor: "#4FDE8B",
    borderRadius: "50%",
    marginRight: 50,
  },

  modalContainer: {
    paddingLeft: 140,
    paddingRight: 140,
    paddingTop: 30,
    paddingBottom: 70,
    maxWidth: 494,
    // minWidth:494,
    // maxWidth:786,
  },
  subtitle: {
    color: "grey",
    fontSize: 12,
  },
  names: {
    display: "flex",
    justifyContent: "space-between",
  },
  roleAndTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 53,
  },
  profileText: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    // marginBottom: 30,
    // marginTop: 60,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  textFieldsRight: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
  },
  textFieldsLeft: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
  },
  rolefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
  },
  matrixInput: {
    width: 200,
    marginBottom: 20,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  titlefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
  },
  emailtextFields: {
    marginTop: 5,
    marginBottom: 5,
  },
  accountStatusDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  accountStatusText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  formControl: {
    flex: 1,
    marginLeft: 7,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 50,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 50,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 50,
    width: 250,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const MyProfile = () => {
  const classes = useStyles();
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    role: "",
    title: "",
    email: "",
    password: "",
    status: "",
  });

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserDetails({ ...userDetails, status: event.target.value as string });
  };

  const profileData = useSelector((state: RootState) => state.user);

  const userRole = useSelector((state: RootState) => state.user.role);

  const profileUpdated = useSelector(
    (state: RootState) => state.updatemyProfile
  );

  useEffect(() => {
    if (profileData) {
      setUserDetails({
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        role: profileData.role,
        title: profileData.title,
        email: profileData.email,
        password: "",
        status: profileData.is_active ? "Active" : "Disabled",
      });
    }
    // if (currentMatrixSet) {
    //   setSelectedMatrix(currentMatrixSet);
    // } else {
    //   setSelectedMatrix("json_5_5");
    // }
  }, []);

  useEffect(() => {
    if (profileData) {
      setUserDetails({
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        role: profileData.role,
        title: profileData.title,
        email: profileData.email,
        password: "",
        status: profileData.is_active ? "Active" : "Disabled",
      });
    }
  }, [profileUpdated.status]);

  const handleSubmit = () => {
    handleCloseModal();
    let payload =
      profileData.role === "Administrator"
        ? {
            access_token: access_token,
            title: userDetails.title,
            password: userDetails.password,
            is_admin: true,
            id: profileData.id,
          }
        : {
            access_token: access_token,
            title: userDetails.title,
            password: userDetails.password,
            is_admin: false,
            id: profileData.id,
          };
    dispatch(updateMyProfile(payload));
  };

  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserDetails({
      ...userDetails,
      role: event.target.value as string,
    });
  };

  return (
    <>
      <Card className={classes.cardDiv}>
        <Typography className={classes.profileTitle}>My profile</Typography>
        <div className={classes.separation}></div>
        <div className={classes.nameDiv}>
          <div className={classes.leftDiv}>
            <Typography className={classes.texts}>
              {userDetails.first_name}
            </Typography>
          </div>
          <div className={classes.rightDiv}>
            <Typography className={classes.texts}>
              {userDetails.last_name}
            </Typography>
          </div>
        </div>
        <div className={classes.nameDiv}>
          <div className={classes.leftDiv}>
            <Typography className={classes.texts}>
              {userDetails.role}
            </Typography>
          </div>
          <div className={classes.rightDiv}>
            <Typography className={classes.texts}>
              {userDetails.title}
            </Typography>
          </div>
        </div>
        <div className={classes.emailDiv}>
          <Typography className={classes.texts}>{userDetails.email}</Typography>
        </div>
        <div className={classes.nameDiv}>
          <div className={classes.statusDiv}>
            <Typography className={classes.statusText}>
              Account Status
            </Typography>
          </div>
          <div className={classes.rightDiv}>
            <Typography className={classes.texts}>
              {userDetails.status}
            </Typography>
            <span
              className={
                userDetails.status === "Active"
                  ? classes.greenDot
                  : classes.redDot
              }
            ></span>
          </div>
        </div>
        <div style={{ marginTop: 54 }}>
          <ColorButton
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
          >
            EDIT PROFILE
          </ColorButton>
        </div>
      </Card>

      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.titleCenter}>
              <Typography className={classes.profileText}>
                EDIT MY PROFILE
              </Typography>
            </div>
            <div className={classes.names}>
              <TextField
                className={classes.textFieldsLeft}
                required
                disabled
                id="outlined-required"
                label="First Name"
                value={userDetails.first_name}
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    first_name: e.target.value,
                  });
                }}
                variant="outlined"
              />
              <TextField
                className={classes.textFieldsRight}
                required
                disabled
                id="outlined-required"
                label="Last Name"
                value={userDetails.last_name}
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    last_name: e.target.value,
                  });
                }}
                variant="outlined"
              />
            </div>
            <div className={classes.roleAndTitle}>
              <FormControl variant="outlined" className={classes.rolefield}>
                <InputLabel>Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  required
                  disabled
                  onChange={handleChangeRole}
                  label="Role"
                  value={userDetails.role}
                >
                  <MenuItem value={"Administrator"}>Administrator</MenuItem>
                  <MenuItem value={"Risk manager"}>Risk manager</MenuItem>
                  <MenuItem value={"Risk owner"}>Risk owner</MenuItem>
                  <MenuItem value={"Control owner"}>Control owner</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={classes.titlefield}
                required
                id="outlined-required"
                label="Title"
                value={userDetails.title}
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    title: e.target.value,
                  });
                }}
                variant="outlined"
              />
            </div>
            <TextField
              className={classes.emailtextFields}
              required
              id="outlined-password-input"
              label="Password"
              type="password"
              value={userDetails.password}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  password: e.target.value,
                });
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.emailtextFields}
              required
              disabled
              id="outlined-required"
              label="Email Address"
              value={userDetails.email}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  email: e.target.value,
                });
              }}
              variant="outlined"
              fullWidth
            />
            <div className={classes.accountStatus}>
              <div className={classes.accountStatusDiv}>
                <Typography className={classes.accountStatusText}>
                  Choose account status
                </Typography>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={userDetails.status}
                  disabled
                  onChange={handleChangeStatus}
                  label="Status"
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.buttons}>
              <Button className={classes.saveBtn} onClick={handleSubmit}>
                Save
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCloseModal}>
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MyProfile;
