import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSoaUser } from "../../api/addUser/getNewSoaUser";

export const getAllSoaUsers = createAsyncThunk(
  "/getSoaUser",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getSoaUser(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface user {
  id: number;
  first_name: string;
  last_name: string;
  title:string;
}

export type initialStateUsers = {
  get_soa_users: Array<user>;
};

const initialState: initialStateUsers = {
    get_soa_users: [],
};

const getSoaUsers = createSlice({
  name: "getSoaUsersSlice",
  initialState,
  reducers: {
    resetSoaUsers: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSoaUsers.fulfilled, (state, { payload }) => {
      state.get_soa_users = [];
      payload?.forEach((value: any) => {
        state?.get_soa_users?.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
          title: value.title
        });
      });
    });
  },
});

export const { resetSoaUsers } = getSoaUsers.actions;

export default getSoaUsers.reducer;