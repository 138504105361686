import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { riskMatrixGet } from "../../api/matrix/riskMatrix";

export const riskMatrixFetch = createAsyncThunk(
  "matrix/get",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await riskMatrixGet(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface riskMatrixList {
  impact: String;
  Likelihood: String;
  result: string;
}

export type initialStateMatrix = {
  //   matrix_choice: string;
  riskMatrixList: Array<riskMatrixList>;
};

const initialState: initialStateMatrix = {
  //   matrix_choice: "",
  riskMatrixList: [],
};

const matrix = createSlice({
  name: "riskMatrixGet",
  initialState,
  reducers: {
    resetRiskMatrix: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(riskMatrixFetch.fulfilled, (state, { payload }) => {
      state.riskMatrixList = [];
      payload?.data?.forEach((value: any) => {
        state.riskMatrixList.push({
          impact: value.impact,
          Likelihood: value.Likelihood,
          result: value.result,
        });
      });
    });
  },
});

export const { resetRiskMatrix } = matrix.actions;

export default matrix.reducer;
