import { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { resetUser } from "../redux/slices/userSlice";
import { resetSurvey } from "../redux/slices/surveySlice";
import home from "../assets/home.svg";
import profile from "../assets/profile.svg";
import logout from "../assets/logout.svg";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    // height: 165,
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    top: theme.spacing(10),
    right: theme.spacing(12),
    zIndex: 9,
    border: "1px solid #E2E2E2",
    borderRadius: 8,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listIcon: {
    marginLeft: theme.spacing(3),
  },
}));

export default function DrawerComponent({
  open,
  handleClose,
  appBar,
  useOutsideAlerter,
}: any) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleMyProfileClick = () => {
    history.push("/settings/");
  };
  const handleHomeClick = () => {
    history.push("/");
    appBar && dispatch(resetSurvey());
  };

  const isFinishedFirstCore = useSelector(
    (state: RootState) => state.user.isFinishedFirstCore
  );

  const userRole = useSelector((state: RootState) => state.user.role);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (open) {
    return (
      <div ref={wrapperRef}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {appBar && userRole === "Administrator" ? (
            isFinishedFirstCore && (
              <ListItem button onClick={handleHomeClick}>
                <ListItemIcon className={classes.listIcon}>
                  <img src={home} alt="Home" style={{ cursor: "pointer" }} />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            )
          ) : (
            <ListItem button onClick={handleHomeClick}>
              <ListItemIcon className={classes.listIcon}>
                <img src={home} alt="Home" style={{ cursor: "pointer" }} />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          )}

          <ListItem button onClick={handleMyProfileClick}>
            <ListItemIcon className={classes.listIcon}>
              <img src={profile} alt="Settings" style={{ cursor: "pointer" }} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              localStorage.clear();
              dispatch(resetUser());
              dispatch(resetSurvey());
              history.push("/signin");
            }}
          >
            <ListItemIcon className={classes.listIcon}>
              <img src={logout} alt="Logout" style={{ cursor: "pointer" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    );
  }
  return <div />;
}
