import React, { useState, useEffect } from "react";
import HeaderProfile from "../../layout/HeaderProfile";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useSnackbar } from "notistack";
import { unwrapResult } from "@reduxjs/toolkit";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddRisk from "./AddRisk";
import { riskListFetch } from "../../redux/slices/riskSlice";
import { usersListFetch } from "../../redux/slices/usersListSlice";
import { riskGroupFetch } from "../../redux/slices/riskGroupSlice";
import { projectListFetch } from "../../redux/slices/projectListSlice";
// import { matrixSettingCheck } from "../../redux/slices/riskMatrixSetCheckSlice";
import { risklist as Irisklist } from "../../redux/slices/riskSlice";
import TableRowRisk from "./TableRowRisk";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MatrixSelectModal from "./matrixSelectionModal";
import MatrixPopupNotf from "./matrixChangePopUp";
import { riskMatrixFetch } from "../../redux/slices/riskMatrixGetSlice";
import { allRiskMatrixFetch } from "../../redux/slices/allRiskMatrixSlice";
import NewRiskAddNotification from "./newRiskAddNotification";
import { riskAddedStatus } from "../../redux/slices/getRiskAddedSlice";
import RiskSummary from "./riskSummary";
import { isoControlsFetch } from "../../redux/slices/isoControlsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    marginBottom: 100,
  },
  body: {
    marginTop: 60,
    marginBottom: 40,
    marginRight: 100,
    marginLeft: 100,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: 700,
    marginBottom: 30,
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 50,
    alignItems: "center",
  },
  filterDiv: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  formControl: {
    padding: theme.spacing(1, 0, 0, 0),
    margin: theme.spacing(1, 2, 1, 2),
    minWidth: 200,
  },
  inputLabel: {
    margin: theme.spacing(0, 0, 0, 0),
    fontSize: 16,
  },
  select: {
    height: 40,
    fontSize: 16,
    borderRadius: 8,
  },
  selectLarge: {
    height: 40,
    fontSize: 16,
    borderRadius: 8,
    width: 300,
  },
  table: {
    minWidth: 650,
    maxWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid lightgrey",
      padding: theme.spacing(1.5, 3, 1.5, 2),
      fontWeight: "bold",
      fontSize: 18,
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    body: {
      fontSize: 16,
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      padding: theme.spacing(1, 1, 1, 1),
    },
  })
)(TableCell);

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 40,
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const RiskManagement = () => {
  const classes = useStyles();
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [filterBy, setFilterBy] = useState("");
  const [subFilter, setSubFilter] = useState("");
  const [modalState, setModalState] = useState(false);
  const [filteredRiskList, setFilteredRiskList] = useState<Irisklist[]>([]);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [selectMatrix, setSelectMatrix] = useState(false);
  const [matrixPopup, setMatrixPopup] = useState(false);

  const [selectedMatrix, setSelectedMatrix] = useState("");
  const [newRiskNotification, setNewRiskNotification] = useState(false);


  type Order = "asc" | "desc";

  interface Data {
    id: number;
    risk_status: string;
    risk_group: string;
    name: string;
    likelihood: string;
    impact: string;
    risk_rating: string;
    existing_controls: string;
    risk_response: string;
    residual_risk_rating: string;
    related_project: string;
    risk_owner_name: string;
    risk_review_date: string;
    comments: string;
  }

  const matrixSelected = useSelector(
    (state: RootState) => state.user.risk_matrix
  );

  useEffect(() => {
    dispatch(riskListFetch({ access_token }));
    dispatch(riskAddedStatus({ access_token }));
    dispatch(usersListFetch({ access_token }));
    dispatch(riskGroupFetch({ access_token }));
    dispatch(projectListFetch({ access_token }));
    dispatch(isoControlsFetch({access_token}));
    dispatch(riskMatrixFetch({ access_token }))
      .then(unwrapResult)
      .then((response: any) => {
        const currentMatrix = response.matrix;
        setSelectedMatrix(currentMatrix);
      })
      .catch((error) => {});
    dispatch(allRiskMatrixFetch({ access_token }));
  }, []);

  useEffect(() => {
    if (!matrixSelected) {
      setSelectMatrix(true);
    }
  }, [matrixSelected]);

  const riskList = useSelector(
    (state: RootState) => state.riskList.allRiskList
  );

  const riskMatrixNotification = useSelector(
    (state: RootState) => state.riskList.is_notification
  );

  const riskAdded = useSelector((state: RootState) => state.addNewRisk.id);

  const riskUpdated = useSelector((state: RootState) => state.editRisk);

  const addRiskNotification = useSelector(
    (state: RootState) => state.newRiskAddedStatus.new_risk_added
  );

  const usersList = useSelector(
    (state: RootState) => state.usersList.allUsersList
  );

  const groupList = useSelector(
    (state: RootState) => state.riskGroup.allRiskGroups
  );

  const userRole = useSelector((state: RootState) => state.user.role);

  const logstate = useSelector((state: RootState) => state);

  const riskMatrix3_3 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_3_3
  );

  const riskMatrix4_4 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_4_4
  );

  const riskMatrix5_5 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_5_5
  );

  let impact3: string[] = [];
  riskMatrix3_3?.forEach((val) => {
    impact3.push(val.impact);
  });

  let impact4: string[] = [];
  riskMatrix4_4?.forEach((val) => {
    impact4.push(val.impact);
  });

  let impact5: string[] = [];
  riskMatrix5_5?.forEach((val) => {
    impact5.push(val.impact);
  });

  const impact3_3 = [...new Set(impact3)];

  const impact4_4 = [...new Set(impact4)];

  const impact5_5 = [...new Set(impact5)];

  let likelihood3: string[] = [];
  riskMatrix3_3?.forEach((val) => {
    likelihood3.push(val.Likelihood);
  });

  let likelihood4: string[] = [];
  riskMatrix4_4?.forEach((val) => {
    likelihood4.push(val.Likelihood);
  });

  let likelihood5: string[] = [];
  riskMatrix5_5?.forEach((val) => {
    likelihood5.push(val.Likelihood);
  });

  const likelihood3_3 = [...new Set(likelihood3)];

  const likelihood4_4 = [...new Set(likelihood4)];

  const likelihood5_5 = [...new Set(likelihood5)];

  // console.log(logstate, "ffffffffff");

  useEffect(() => {
    if (riskMatrixNotification) {
      if (userRole === "Administrator" || userRole === "Risk manager") {
        setMatrixPopup(true);
      }
    }
  }, [riskMatrixNotification]);

  const handleChangeFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterBy(event.target.value as string);
  };

  const handleChangeSubFilter = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSubFilter(event.target.value as string);

    if (filterBy === "Risk group" && event.target.value !== "All") {
      const temp = riskList.filter(
        (value) => value.risk_group === event.target.value
      );
      if (temp) {
        setFilteredRiskList(temp);
      }
    } else if (filterBy === "Risk rating" && event.target.value !== "All") {
      const temp = riskList.filter(
        (value) => value.risk_rating === event.target.value
      );
      if (temp) {
        setFilteredRiskList(temp);
      }
    } else if (filterBy === "Response type" && event.target.value !== "All") {
      const temp = riskList.filter(
        (value) => value.risk_response === event.target.value
      );
      if (temp) {
        setFilteredRiskList(temp);
      }
    } else if (
      filterBy === "Residual risk rating" &&
      event.target.value !== "All"
    ) {
      const temp = riskList.filter(
        (value) => value.residual_risk_rating === event.target.value
      );
      if (temp) {
        setFilteredRiskList(temp);
      }
    } else if (filterBy === "Risk owner" && event.target.value !== "All") {
      const temp = riskList.filter(
        (value) => value.risk_owner === event.target.value
      );
      if (temp) {
        setFilteredRiskList(temp);
      }
    } else {
      setFilteredRiskList(riskList);
    }
  };

  const handleAddNewRisk = () => {
    if (userRole === "Administrator" || userRole === "Risk manager") {
      setModalState(true);
    } else {
      enqueueSnackbar("Action denied, please contact Risk Manager", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (filterBy === "All") {
      setFilteredRiskList(riskList);
    }
  }, [filterBy]);

  useEffect(() => {
    if (riskAdded) {
      dispatch(riskListFetch({ access_token }));
    }
  }, [riskAdded]);

  useEffect(() => {
    dispatch(riskListFetch({ access_token }));
  }, [riskUpdated]);

  useEffect(() => {
    if (subFilter === "" || subFilter === "All") {
      setFilteredRiskList(riskList);
    }
  }, [riskList]);

  useEffect(() => {
    if (userRole === "Administrator" || userRole === "Risk manager") {
      if (addRiskNotification) {
        setNewRiskNotification(true);
      }
    }
  }, [addRiskNotification]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    order: Order;
    orderBy: string;
  }

  interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    { id: "id", numeric: true, label: "Risk ID" },
    { id: "risk_group", numeric: false, label: "Risk group" },
    { id: "name", numeric: false, label: "Risk name" },
    { id: "risk_status", numeric: false, label: "Risk status" },
    { id: "likelihood", numeric: false, label: "Likelihood" },
    { id: "impact", numeric: false, label: "Impact" },
    { id: "risk_rating", numeric: false, label: "Risk rating" },
    { id: "existing_controls", numeric: false, label: "Existing controls" },
    {
      id: "residual_risk_rating",
      numeric: false,
      label: "Residual risk Rating",
    },
    { id: "risk_response", numeric: false, label: "Risk response" },
    { id: "related_project", numeric: false, label: "Related project" },
    { id: "risk_owner_name", numeric: false, label: "Risk owner" },
    { id: "risk_review_date", numeric: false, label: "Risk review date" },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              align={"left"}
              // padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <HeaderProfile />
          <div className={classes.body}>
            <Typography className={classes.profileTitle}>
              Risk management
            </Typography>

            <div className={classes.filterContainer}>
              <div className={classes.filterDiv}>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    marginRight: 20,
                    marginTop: 8,
                  }}
                >
                  Filter by
                </Typography>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.inputLabel}>
                    Choose filter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-
                        
                    le-select-outlined"
                    // value={filterBy}
                    onChange={handleChangeFilter}
                    // label="Role"
                    className={classes.select}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Risk group"}>Risk Group</MenuItem>
                    <MenuItem value={"Risk rating"}>Risk Rating</MenuItem>
                    <MenuItem value={"Response type"}>Response type</MenuItem>
                    <MenuItem value={"Residual risk rating"}>
                      Residual risk rating
                    </MenuItem>
                    <MenuItem value={"Risk owner"}>Risk Owner</MenuItem>
                  </Select>
                </FormControl>

                {filterBy === "Risk group" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {groupList &&
                        groupList.length &&
                        groupList.map((group, key) => (
                          <MenuItem value={group.name}>{group.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Risk rating" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"Low"}>Low</MenuItem>
                      <MenuItem value={"Medium"}>Medium</MenuItem>
                      <MenuItem value={"High"}>High</MenuItem>
                      <MenuItem value={"Critical"}>Critical</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Response type" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"Accept"}>Accept</MenuItem>
                      <MenuItem value={"Remediate"}>Remediate</MenuItem>
                      <MenuItem value={"Transfer"}>Transfer</MenuItem>
                      <MenuItem value={"Avoid"}>Avoid</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Residual risk rating" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"Low"}>Low</MenuItem>
                      <MenuItem value={"Medium"}>Medium</MenuItem>
                      <MenuItem value={"High"}>High</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Risk owner" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {usersList
                        ?.filter(
                          (val) => val.is_active === true
                          //   && val.role === "Administrator" ||
                          // val.role === "Risk owner" ||
                          // val.role === "Risk manager"
                        )
                        ?.map((user, key) => (
                          <MenuItem value={user.email}>{user.email}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </div>

              <ColorButton
                variant="contained"
                color="primary"
                disabled={!matrixSelected}
                onClick={() => handleAddNewRisk()}
              >
                Add new risk
              </ColorButton>
            </div>

            <RiskSummary riskList={riskList}/>

            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {filteredRiskList && filteredRiskList.length
                    ? stableSort(
                        filteredRiskList,
                        getComparator(order, orderBy)
                      )
                        .filter((value) => value.risk_status !== "Closed")
                        .map((row, key) => (
                          <TableRowRisk
                            row={row}
                            key={key}
                            selectedMatrix={selectedMatrix}
                            impact3_3={impact3_3}
                            impact4_4={impact4_4}
                            impact5_5={impact5_5}
                            likelihood3_3={likelihood3_3}
                            likelihood4_4={likelihood4_4}
                            likelihood5_5={likelihood5_5}
                          />
                        ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: 40 }}>
              <ColorButton
                variant="contained"
                color="primary"
                disabled={!matrixSelected}
                onClick={() => handleAddNewRisk()}
              >
                Add new risk
              </ColorButton>
            </div>
            <AddRisk setModalState={setModalState} modalState={modalState} />
          </div>
          <MatrixSelectModal
            setSelectMatrix={setSelectMatrix}
            selectMatrix={selectMatrix}
          />
          <MatrixPopupNotf
            matrixPopup={matrixPopup}
            setMatrixPopup={setMatrixPopup}
          />
          <NewRiskAddNotification
            newRiskNotification={newRiskNotification}
            setNewRiskNotification={setNewRiskNotification}
          />
        </div>
      </ThemeProvider>
    </>
  );
};

export default RiskManagement;
