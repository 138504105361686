import { server_url } from "../../types/constants";
import axios from "axios";
import { User } from "../../redux/slices/userSlice";

export async function loadUserApi(refresh_token: string): Promise<User> {
  const url = `${server_url}/authentication/token/refresh/`;
  try {
    const loadUserResponse = await axios.post<User>(url, {
      refresh_token: refresh_token,
    });
    return loadUserResponse.data as User;
  } catch (err) {
    throw err.response;
  }
}
