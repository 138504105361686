import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import Card from "@material-ui/core/Card";
import { riskMatrixPush } from "../../redux/slices/riskMatrixSetSlice";
import { riskMatrixFetch } from "../../redux/slices/riskMatrixGetSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  matrixTable: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    marginTop: 100,
    marginBottom: 118,
    width: 1280,
  },
  tableHead: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
  },
  tableCell: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    width: 320,
    verticalAlign: "top",
    textAlign: "left",
    paddingRight: 35,
    paddingLeft: 35,
    paddingTop: 18,
    paddingBottom: 18,
  },
  verticalBorderNone: {
    borderBottom: "none",
    fontWeight: "bold",
    padding: 10,
  },
  topCorner: {
    borderBottom: "1px solid black",
  },
  tableHeadCell: {
    padding: 10,
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const Matrix3Description = ({matrix3_3}:any) => {
  const classes = useStyles();
  const [matrix, setMatrix] = useState(matrix3_3);

  const riskMatrix3_3 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_3_3
  );

  useEffect(()=>{
    setMatrix(riskMatrix3_3)
  },[riskMatrix3_3])

  const [likelihoodOne] = matrix?.filter(
    (val: any) => val.Likelihood_key === "1"
  );
  const [likelihoodTwo] = matrix?.filter(
    (val: any) => val.Likelihood_key === "2"
  );
  const [likelihoodThree] = matrix?.filter(
    (val: any) => val.Likelihood_key === "3"
  );
  const [impactOne] = matrix?.filter((val: any) => val.impact_key === "1");
  const [impactTwo] = matrix?.filter((val: any) => val.impact_key === "2");
  const [impactThree] = matrix?.filter((val: any) => val.impact_key === "3");

  return (
    <>
      <table className={classes.matrixTable}>
        <tr>
          <th className={classes.tableCell}>
            <Typography>
              <b>Impact</b>
            </Typography>
          </th>
          <th className={classes.tableCell}>
            <Typography>
              <b>Description</b>
            </Typography>
          </th>
          <th className={classes.tableCell}>
            <Typography>
              <b>Likelihood</b>
            </Typography>
          </th>
          <th className={classes.tableCell}>
            <Typography>
              <b>Description</b>
            </Typography>
          </th>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactOne?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has no or low financial and operational impact on the
              organization’s process. A minor human injury that requires first
              aid only.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodOne?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized a few times in the past 36 months. The
              organization has several vulnerabilities that can materialize the
              risk.
            </Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactTwo?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has a material and operational impact on the
              organization’s processes. A minor human injury that requires
              medical treatment.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodTwo?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized several times in the past 24 months. The
              organization has several vulnerabilities that can materialize the
              threat.
            </Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactThree?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has a high material and operational impact on the
              organization’s processes, putting the organization's future at
              risk. Loss of a human life or severe injuries to a group of
              people.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodThree?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized several times in the past 12 months. The
              organization has several vulnerabilities that can materialize the
              threat.
            </Typography>
          </td>
        </tr>
      </table>
    </>
  );
};

export default Matrix3Description;
