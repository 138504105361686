import { server_url } from "../../types/constants";
import axios from "axios";

export async function newPassword(email: string, password: string) {
  const url = `${server_url}/authentication/forgot_password_confirm`;
  try {
    const response = await axios({
      method: "post",
      url: url,
      data: {
        email: email,
        password: password,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
