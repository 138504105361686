import React, { useState, useEffect } from "react";
import HeaderProfile from "../../layout/HeaderProfile";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button, OutlinedInput, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import search from "../../assets/search.svg";
import RemediationRow from "./RemediationRow";
import AddControl from "./addControl";
import { riskListFetch } from "../../redux/slices/riskSlice";
import { usersListFetch } from "../../redux/slices/usersListSlice";
import { riskGroupFetch } from "../../redux/slices/riskGroupSlice";
import { projectListFetch } from "../../redux/slices/projectListSlice";
import { controlListFetch } from "../../redux/slices/controlListSlice";
// import { risklist as Irisklist } from "../../redux/slices/riskSlice";
import { controlList as IcontrolList } from "../../redux/slices/controlListSlice";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    marginBottom: 100,
  },
  body: {
    marginTop: 60,
    marginBottom: 40,
    marginRight: 100,
    marginLeft: 100,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: 700,
    marginBottom: 30,
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 50,
    alignItems: "center",
  },
  filterDiv: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    // flexWrap: "wrap",
  },
  formControl: {
    padding: theme.spacing(1, 0, 0, 0),
    margin: theme.spacing(1, 6, 1, 2),
    width: 234,
  },
  inputLabel: {
    margin: theme.spacing(0, 0, 0, 0),
    fontSize: 16,
  },
  select: {
    height: 40,
    fontSize: 16,
    borderRadius: 8,
  },
  searchfield: {
    marginTop: 8,
    width: 234,
    height: 40,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  input: {
    height: 40,
  },
  applybtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    height: 40,
    width: 250,
    // marginRight: 10,
    // marginLeft: 10,
    fontWeight: 600,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
    textTransform: "none",
  },
  addbtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    height: 40,
    width: 120,
    // marginRight: 10,
    marginLeft: 25,
    fontWeight: 600,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#fff",
    },
    borderRadius: 8,
    textTransform: "none",
    border: "1px solid #1071BA",
  },
  table: {
    minWidth: 800,
    maxWidth: "100%",
  },
  selectLarge: {
    height: 40,
    fontSize: 16,
    borderRadius: 8,
    width: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid lightgrey",
      padding: theme.spacing(1.5, 3, 1.5, 2),
      fontWeight: "bold",
      fontSize: 18,
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    body: {
      fontSize: 16,
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      padding: theme.spacing(1, 1, 1, 1),
    },
  })
)(TableCell);

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 40,
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const RemediationPlan = () => {
  const classes = useStyles();
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();
  const { riskId }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [filterBy, setFilterBy] = useState("");
  const [subFilter, setSubFilter] = useState("");
  const [addControlState, setAddControlState] = useState(false);
  const [filteredControlList, setFilteredControlList] = useState<
    IcontrolList[]
  >([]);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("risk_name");

  type Order = "asc" | "desc";

  interface Data {
    risk_name: string;
    risk_response: string;
    risk_rating: string;
    control_group_name: string;
    control_name: string;
    mitigation: string;
    Status: string;
    due_date: string;
    control_owner_name: string;
  }

  useEffect(() => {
    const access_token = localStorage.getItem("Access_Token");
    if (access_token) {
      dispatch(controlListFetch({ access_token }));
      dispatch(riskListFetch({ access_token }));
      dispatch(usersListFetch({ access_token }));
      dispatch(riskGroupFetch({ access_token }));
      dispatch(projectListFetch({ access_token }));
    }
    if (riskId) {
      setFilterBy("Risk name");
      setSubFilter(riskId);
    }
  }, []);

  const riskList = useSelector(
    (state: RootState) => state.riskList.allRiskList
  );

  const usersList = useSelector(
    (state: RootState) => state.usersList.allUsersList
  );

  const controlList = useSelector(
    (state: RootState) => state.controlListSlice.allControlList
  );

  const controlAdded = useSelector(
    (state: RootState) => state.addNewControl.id
  );

  const controlUpdated = useSelector((state: RootState) => state.editControl);

  const groupList = useSelector(
    (state: RootState) => state.riskGroup.allRiskGroups
  );

  const userRole = useSelector((state: RootState) => state.user.role);

  const logs = useSelector((state: RootState) => state);

  // console.log(logs, "qqqqqqq");

  const handleChangeFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterBy(event.target.value as string);
  };

  const handleAddControl = () => {
    if (userRole === "Administrator" || userRole === "Risk manager") {
      setAddControlState(true);
    } else {
      enqueueSnackbar("Action denied, please contact Risk Manager", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (riskId) {
      let temp = riskList?.filter((val) => val.risk_uuid == riskId);

      if (temp.length) {
        setSubFilter(temp[0].name);
      }
    }
  }, [riskList]);

  const handleChangeSubFilter = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSubFilter(event.target.value as string);

    if (filterBy === "Risk name" && event.target.value !== "All") {
      const temp = controlList?.filter(
        (value) => value.risk_name === event.target.value
      );
      if (temp) {
        setFilteredControlList(temp);
      }
    } else if (filterBy === "Risk rating" && event.target.value !== "All") {
      const temp = controlList?.filter(
        (value) => value.risk_rating === event.target.value
      );
      if (temp) {
        setFilteredControlList(temp);
      }
    } else if (filterBy === "Response type" && event.target.value !== "All") {
      const temp = controlList?.filter(
        (value) => value.risk_response === event.target.value
      );
      if (temp) {
        setFilteredControlList(temp);
      }
    } else if (filterBy === "Control owner" && event.target.value !== "All") {
      const temp = controlList?.filter(
        (value) => value.control_owner === event.target.value
      );
      if (temp) {
        setFilteredControlList(temp);
      }
    } else if (
      filterBy === "Control group name" &&
      event.target.value !== "All"
    ) {
      const temp = controlList?.filter(
        (value) => value.control_group_name === event.target.value
      );
      if (temp) {
        setFilteredControlList(temp);
      }
    } else {
      setFilteredControlList(controlList);
    }
  };

  useEffect(() => {
    if (filterBy === "All") {
      setFilteredControlList(controlList);
    }
  }, [filterBy]);

  useEffect(() => {
    if (subFilter === "" || subFilter === "All") {
      setFilteredControlList(controlList);
    }

    if (filterBy == "Risk name" && subFilter && controlList) {
      let temp = controlList?.filter((value) => value.risk_name === subFilter);
      setFilteredControlList(temp);
    }
  }, [controlList]);

  useEffect(() => {
    dispatch(controlListFetch({ access_token }));
  }, [controlAdded]);

  useEffect(() => {
    dispatch(controlListFetch({ access_token }));
  }, [controlUpdated]);

  // console.log(riskList,controlList,"vvvvv")

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    order: Order;
    orderBy: string;
  }

  interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    { id: "risk_name", numeric: false, label: "Risk name" },
    { id: "risk_response", numeric: false, label: "Risk response" },
    { id: "risk_rating", numeric: false, label: "Risk rating" },
    { id: "control_group_name", numeric: false, label: "Control group name" },
    { id: "control_name", numeric: false, label: "Control name" },
    { id: "mitigation", numeric: false, label: "Mitigation" },
    { id: "Status", numeric: false, label: "Status" },
    { id: "due_date", numeric: false, label: "Due date" },
    {
      id: "control_owner_name",
      numeric: false,
      label: "Control owner",
    },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              align={"left"}
              // padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <HeaderProfile />
          <div className={classes.body}>
            <Typography className={classes.profileTitle}>
              Remediation Plan
            </Typography>
            <div className={classes.filterContainer}>
              <div className={classes.filterDiv}>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    marginRight: 20,
                    marginTop: 8,
                  }}
                >
                  Filter
                </Typography>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.inputLabel}>
                    Choose option
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-le-select-outlined"
                    value={filterBy}
                    onChange={handleChangeFilter}
                    // label=""
                    className={classes.select}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Risk name"}>Risk name</MenuItem>
                    <MenuItem value={"Risk rating"}>Risk rating</MenuItem>
                    <MenuItem value={"Control owner"}>Control Owner</MenuItem>
                    <MenuItem value={"Control group name"}>
                      Control group name
                    </MenuItem>
                  </Select>
                </FormControl>

                {filterBy === "Risk name" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {riskList &&
                        riskList.length &&
                        riskList.map((value, key) => (
                          <MenuItem value={value?.name}>{value?.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Risk rating" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"Low"}>Low</MenuItem>
                      <MenuItem value={"Medium"}>Medium</MenuItem>
                      <MenuItem value={"High"}>High</MenuItem>
                      <MenuItem value={"Critical"}>Critical</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Control owner" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {usersList
                        ?.filter(
                          (val) => val.is_active === true
                          // && val.role === "Administrator" ||
                          // val.role === "Risk owner" ||
                          // val.role === "Risk manager"
                        )
                        ?.map((user, key) => (
                          <MenuItem value={user.id}>{user.email}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {filterBy === "Control group name" && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.inputLabel}>
                      Choose {filterBy}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-le-select-outlined"
                      value={subFilter}
                      onChange={handleChangeSubFilter}
                      // label="Role"
                      className={classes.selectLarge}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {groupList &&
                        groupList.length &&
                        groupList.map((group, key) => (
                          <MenuItem value={group.name}>{group.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.inputLabel}>Search</InputLabel>
                  <OutlinedInput
                    className={classes.select}
                    id="outlined-adornment-weight"
                    // value={}
                    // onChange={}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={search} alt="Link" />
                      </InputAdornment>
                    }
                  />
                </FormControl> */}
              </div>
              {/* <Button className={classes.applybtn}>Apply</Button> */}
              <Button className={classes.applybtn} onClick={handleAddControl}>
                Add new control
              </Button>
            </div>

            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {filteredControlList && filteredControlList.length
                    ? stableSort(
                        filteredControlList,
                        getComparator(order, orderBy)
                      ).map((row, key) => (
                        <RemediationRow row={row} key={key} />
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: 60 }}>
              <Button className={classes.applybtn} onClick={handleAddControl}>
                Add new control
              </Button>
            </div>
            <AddControl
              addControlState={addControlState}
              setAddControlState={setAddControlState}
            />
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default RemediationPlan;
