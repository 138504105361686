import { server_url } from "../../types/constants";
import axios from "axios";

export async function statusUpdate(
  access_token: string,
  question_id :string,
  status : string,
) {
  const url = `${server_url}/rm/get/doc/?status=${status}&question_id=${question_id}`;
   try {
    const response = await axios({
        method: "patch",
        url: url,
        data: {
            status:status,
            question_id:question_id,
        },
        headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}