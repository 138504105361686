import React, { useEffect, useState } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import HeaderProfile from "../../layout/HeaderProfile";
import { useAppDispatch } from "../../redux/store";
import {
  getReportThunk,
  ReportType,
  resetReport,
} from "../../redux/slices/reportSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useParams } from "react-router-dom";
import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TotalInfoTable from "./TotalInfoTable/TotalInfoTable";
import Barchart from "./BarChart/BarChart";
import clsx from "clsx";
import Loading from "../../layout/Loading";
import parse from "html-react-parser";
import { ParamTypes } from "../../types/survey";
import sanitizeHtml from "sanitize-html";
import Pagination from "@material-ui/lab/Pagination";
import Card from "@material-ui/core/Card";
import arrowRightLight from "../../assets/arrowRightLight.svg";
import arrowRight from "../../assets/arrowRight.svg";
import Drawer from "@material-ui/core/Drawer";
import WhiteSidebarLogo from "../Core Assessment/Sidebar/WhiteSidebarLogo";
import Button from "@material-ui/core/Button";

const drawerWidth = 297;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1071BA",
    // marginTop: 120,
    // borderTop: "1px solid #1071BA",
  },
  tableContainer: {},
  tableContainer1: {
    // marginTop: theme.spacing(3),
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
  },
  description: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(4),
  },
  tableBody: {},
  tableDomainName: {
    // marginTop: theme.spacing(5),
    color: theme.palette.primary.main,
  },
  tableCell: {
    borderBottom: 0,
  },
  alignToBaseline: {
    verticalAlign: "baseline",
  },
  gridItemContainer: {
    marginBottom: theme.spacing(3),
  },
  paginationDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 100,
  },
  reportDetailsCard: {
    background: "#FFF",
    padding: 50,
    borderRadius: 8,
    maxHeight: 400,
    overflowY: "auto",
  },
  reportIdContainer: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: 30,
  },
  reportId: {
    fontSize: 30,
    fontWeight: 700,
  },
  reportCount: {
    fontSize: 16,
    fontWeight: 500,
  },
  headerDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 100,
  },
  reportName: {
    fontsize: 18,
    marginLeft: 70,
  },
  reportReponse: {
    fontsize: 18,
    marginLeft: 150,
  },
  reportReponseVal: {
    fontsize: 18,
    marginLeft: 50,
  },
  reportListCard: {
    width: 250,
    maxHeight: 500,
    overflowY: "auto",
  },
  reportListContainer: {
    height: 70,
    width: 220,
    background: "#1071BA",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #1071BA",
    },
  },
  reportListContainerLight: {
    height: 70,
    width: 220,
    background: "#fff",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #1071BA",
    },
  },
  reportCountDiv: {
    height: 47,
    width: 47,
    background: "#C2E5FF",
    borderRadius: 6,
    marginLeft: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportCountDigit: {
    fontSize: 18,
    fontWeight: 800,
  },
  reportListId: {
    fontSize: 15,
    color: "#fff",
  },
  reportListIdDark: {
    fontSize: 15,
    color: "#1071BA",
  },
  reportListDescription: {
    fontSize: 12,
    color: "#fff",
  },
  reportListDescriptionDark: {
    fontSize: 12,
    color: "#1071BA",
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const Report = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [updatedReport, setUpdatedReport] = useState<any[]>([]);

  const reports = useSelector((state: RootState) => state.report.reports);
  const category_name = useSelector(
    (state: RootState) => state.report.category_name
  );
  const { uuid } = useParams<ParamTypes>();
  useEffect(() => {
    dispatch(resetReport());
    dispatch(getReportThunk({ uuid }));
  }, []);

  useEffect(() => {
    if (reports.length > 0) {
      const filteredReports = reports.filter(
        (val) => val.response === "PI" || val.response === "NI"
      );
      const temp = chunk(filteredReports, 1) || [];
      setUpdatedReport([...temp]);
    }
  }, [reports, currentPage]);

  const handleChangePage = (index: any) => {
    setCurrentPage(index);
  };

  const handleDownloadDocs = (report:any) =>{
    console.log("functionCall__________________")
    var newWin = window.open(
      report.recommendations[0].documents_list[0].document
    );

    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      //POPUP BLOCKED
      console.log("popUpBlocked__________________");
    }
    report.recommendations.map(
      (recommendation: any) =>
        recommendation.documents_list &&
        recommendation.documents_list.map((document: any) => {
          if (
            !document ||
            document.closed ||
            typeof document.closed == "undefined"
          ) {
            //POPUP BLOCKED
            console.log("popUpBlocked__________________");
          }
          window.open(document, "_blank");
          window.focus();
          let popup = window.open(document, "_blank");
          setTimeout(function () {
            if (!popup || popup.outerHeight === 0) {
              //First Checking Condition Works For IE & Firefox
              //Second Checking Condition Works For Chrome
              // window.location.href = "warning.html";
              alert(
                "Popup Blocker is enabled!! Please add this site to your exception list."
                );
              } else {
              //Popup Blocker Is Disabled
              window.open("", "_self");
              window.close();
            }
          }, 1000);
        })
    );
  }


  function chunk(array: any, length: number) {
    var chunkarr = [],
      i = 0,
      n = array.length;
    while (i < n) {
      chunkarr.push(array.slice(i, (i += length)));
    }
    return chunkarr;
  }

  const domainAndQuestionCount = new Map<string, number>();
  useEffect(() => {
    reports.forEach((report: ReportType) => {
      if (domainAndQuestionCount.get(report.domain)) {
        if (domainAndQuestionCount.get(report.domain)) {
          const numberOfQuestions =
            (domainAndQuestionCount.get(report.domain) || 1) + 1;
          domainAndQuestionCount.set(report.domain, numberOfQuestions);
        }
      } else {
        domainAndQuestionCount.set(report.domain, 1);
      }
    });
  }, [reports]);
  if (!reports || reports?.length < 1) {
    return <Loading />;
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <HeaderProfile />
        <div style={{ display: "flex" }}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <WhiteSidebarLogo />
          </Drawer>
          <Container component="main">
            <Typography variant="body2" className={classes.description}>
              We identified{" "}
              <strong>
                {reports.filter((report) => report.response === "PI").length +
                  reports.filter((report) => report.response === "NI").length}
              </strong>{" "}
              controls partially or not implemented according to{" "}
              {category_name && category_name === "Core Assessment"
                ? "ISO 27001"
                : "ISO 27002"}{" "}
              standard requirements based on self-assessment results. Below you
              can find those controls along with our recommendation and
              mandatory documents list.
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.gridItemContainer}>
                <Card style={{borderRadius: 8}}>
                  <TotalInfoTable
                    domainAndQuestionCount={domainAndQuestionCount}
                    reports={reports}
                  />
                </Card>
              </Grid>
              <Grid item xs={6} className={classes.gridItemContainer}>
                <Card style={{height: "100%", width: "100%", borderRadius: 8}}>
                <Barchart
                  domainAndQuestionCount={domainAndQuestionCount}
                  reports={reports}
                />
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              style={{ display: "flex", marginBottom: 50 }}
            >
              <Grid item md={3}>
                <Card className={classes.reportListCard}>
                  {reports
                    .filter(
                      (val) => val.response === "PI" || val.response === "NI"
                    )
                    .map((report: any, index: any) => (
                      <div
                        className={
                          currentPage === index
                            ? classes.reportListContainer
                            : classes.reportListContainerLight
                        }
                        onClick={() => handleChangePage(index)}
                      >
                        <div className={classes.reportCountDiv}>
                          <Typography className={classes.reportCountDigit}>
                            {index + 1}
                          </Typography>
                        </div>
                        <div style={{ marginLeft: 18, flex: 1 }}>
                          <Typography
                            className={
                              currentPage === index
                                ? classes.reportListId
                                : classes.reportListIdDark
                            }
                          >
                            ID {report.question_id}
                          </Typography>
                          <Typography
                            className={
                              currentPage === index
                                ? classes.reportListDescription
                                : classes.reportListDescriptionDark
                            }
                          >
                            {report?.domain?.split(" ")[0]}...
                          </Typography>
                        </div>
                        <img
                          style={{ marginRight: 20 }}
                          src={
                            currentPage === index ? arrowRightLight : arrowRight
                          }
                          alt="arrow"
                        />
                      </div>
                    ))}
                </Card>
              </Grid>

              <Grid item md={9}>
                <Card className={classes.reportDetailsCard}>
                  {updatedReport[currentPage]?.map(
                    (report: ReportType, index: any) => (
                      <div style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
                        <div className={classes.headerDiv}>
                          <div className={classes.reportIdContainer}>
                            <Typography className={classes.reportId}>
                              {report.question_id}
                            </Typography>
                            <Typography className={classes.reportCount}>
                              /{updatedReport.length}
                            </Typography>
                          </div>

                          <Typography className={classes.reportName}>
                            {report.domain}
                          </Typography>

                          <Typography className={classes.reportReponse}>
                            User Response:
                          </Typography>
                          <Typography className={classes.reportReponseVal}>
                            <b>
                              {report.response === "PI"
                                ? `Partially implemented : ${report.response_explanation}`
                                : `Not implemented : ${report.response_explanation}`}
                            </b>
                          </Typography>
                        </div>

                        <TableContainer
                          key={`${report.question_id}${index}`}
                          className={classes.tableContainer1}
                        >
                          <Table className={classes.tableContainer}>
                            <TableHead></TableHead>
                            <TableBody className={classes.tableBody}>
                              <TableRow
                                style={{ marginBottom: 35 }}
                                key={`${report.question_id}${index}row0`}
                              >
                                <TableCell
                                  className={clsx(
                                    classes.alignToBaseline,
                                    classes.tableCell
                                  )}
                                >
                                  {"Control objectives"}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {report.icoControl &&
                                    parse(sanitizeHtml(report.icoControl))}
                                </TableCell>
                              </TableRow>

                              <TableRow
                                style={{ marginBottom: 35 }}
                                key={`${report.question_id}${index}recommendation`}
                              >
                                <TableCell
                                  className={clsx(
                                    classes.alignToBaseline,
                                    classes.tableCell
                                  )}
                                >
                                  {`Recommendations`}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {report.recommendations.map(
                                    (recommendation) => (
                                      <>
                                        <span>
                                          {parse(
                                            sanitizeHtml(
                                              recommendation.recommendation
                                            )
                                          )}
                                        </span>
                                        <br />
                                        <br />
                                      </>
                                    )
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow
                                style={{ marginBottom: 35 }}
                                key={`${report.question_id}${index}document`}
                              >
                                <TableCell
                                  className={clsx(
                                    classes.alignToBaseline,
                                    classes.tableCell
                                  )}
                                >
                                  {`Documents`}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {report.recommendations.map(
                                    (recommendation) => (
                                      <>
                                        {recommendation.documents &&
                                          recommendation.documents.map(
                                            (document) => (
                                              <>
                                                <span>{document}</span>
                                                <br />
                                              </>
                                            )
                                          )}
                                      </>
                                    )
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button style={{background:"#1071BA",color:"#fff",width:200}} onClick={()=>handleDownloadDocs(report)}>Download Docs</Button>
                      </div>
                    )
                  )}
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};
export default Report;
