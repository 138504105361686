import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { forgotPasswordLinkValid } from "../../api/auth/forgotPasswordvalid";

export const forgotPasswordVerify = createAsyncThunk(
  "forgotPasswordLinkValid",
  async ({ uuid }: any, thunkAPI) => {
    try {
      return await forgotPasswordLinkValid(uuid);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialState = {
    status: boolean;
  };
  
  const initialState: initialState = {
    status: true,
  };
  
  const forgotPasswordLinkVerification = createSlice({
    name: "forgotPasswordLinkVerify",
    initialState,
    reducers: {
      resetforgotPasswordLinkVerify: () => initialState,
    },
    extraReducers: (builder) => {
      builder.addCase(forgotPasswordVerify.fulfilled, (state, { payload }) => {
        state.status = payload.status;
      });
    },
  });
  
  export const { resetforgotPasswordLinkVerify } = forgotPasswordLinkVerification.actions;
  
  export default forgotPasswordLinkVerification.reducer;