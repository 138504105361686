import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainPage from "./views/MainPage";
import SignIn from "./views/SignIn";
import WelcomePage from "./views/WelcomePage";
import CookieConsent from "react-cookie-consent";
import Link from "@material-ui/core/Link";
import PrivateRoute from "./components/PrivateRoute";
import Feedback from "./views/CoreFeedback/Feedback";
import Assessment from "./views/Core Assessment/Assessment";
import Report from "./views/Report/Report";
import FilterAssessment from "./views/FilterAssessment/FilterAssessment";
import VerifyLink from "./components/VerifyLink";
import UsersProfile from "./views/UsersProfile/UsersProfile";
import MySettings from "./views/MySettings/MySettings";
import SignUp from "./views/SignUp";
import ChangePassword from "./views/ChangePassword/ChangePassword";
import RiskManagement from "./views/RiskManagement/RiskManagement";
import RemediationPlan from "./views/Remediation/RemediationPlan";
import EmailSubmit from "./views/ForgotPassword/EmailSubmit";
import NewPassword from "./views/ForgotPassword/NewPassword";
import HumanResourceSecurity from "./views/SOA/HumanResourceSecurity";
import DocumentManagementFeatures from "./views/Document Management/DocumentManaementFeatures";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/forgotpassword" component={EmailSubmit} />
        <Route exact path="/passwordreset" component={NewPassword} />
        <VerifyLink
          exact
          path="/:registration_token/signup"
          component={SignUp}
        />
        <PrivateRoute exact path="/welcome-page/" component={WelcomePage} />
        <PrivateRoute exact path="/core/:uuid/" component={Assessment} />
        <PrivateRoute exact path="/feedback/:uuid/" component={Feedback} />
        <PrivateRoute exact path="/report/:uuid/" component={Report} />
        <PrivateRoute
          exact
          path="/filter/:uuid/"
          component={FilterAssessment}
        />
        <PrivateRoute exact path="/technical/:uuid/" component={Assessment} />
        <PrivateRoute exact path="/usersprofile/" component={UsersProfile} />
        <PrivateRoute exact path="/settings/" component={MySettings} />
        <PrivateRoute exact path="/changepassword" component={ChangePassword} />
        <PrivateRoute exact path="/riskmanagement" component={RiskManagement} />
        <PrivateRoute exact path= "/soa" component={HumanResourceSecurity} />
        <PrivateRoute exact path= "/docmanagement" component={DocumentManagementFeatures}/>
        <PrivateRoute
          exact
          path="/remediationplan/:riskId"
          component={RemediationPlan}
        />
        <PrivateRoute
          exact
          path="/remediationplan"
          component={RemediationPlan}
        />
        <Redirect from="*" to="/" />
      </Switch>
      <CookieConsent
        location="bottom"
        buttonText="Close"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#3C403D", borderRadius: "4px" }}
        buttonStyle={{
          background: "#E2E5E0",
          color: "#000000",
          fontSize: "15px",
        }}
        expires={150}
      >
        <>
          <span>
            We use cookies and similar technologies to enable the services and
            functionality of the web application. By using it, you agree with
            our terms to use of such technologies for marketing and analytics
            need. See
          </span>
          <span> </span>
          <Link
            color="inherit"
            href="https://app.termly.io/document/privacy-notice/1ec8686e-e9cc-46a6-ba1d-bc85093f8241"
          >
            <ins>Privacy Policy</ins>
          </Link>
          <span> and </span>
          <Link
            color="inherit"
            href="https://app.termly.io/document/terms-of-use-for-website/29273952-deaf-4bb4-abb8-ab55eebe2322"
          >
            <ins>Terms and conditions.</ins>
          </Link>
        </>
      </CookieConsent>
    </div>
  );
}

export default App;
