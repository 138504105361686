import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import Card from "@material-ui/core/Card";
import { riskMatrixPush } from "../../redux/slices/riskMatrixSetSlice";
import { riskMatrixFetch } from "../../redux/slices/riskMatrixGetSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Matrix3by3 from "./Matrix3by3";
import Matrix4by4 from "./Matrix4by4";
import Matrix5by5 from "./Matrix5by5";
import ConfirmChangeMatrix from "./ConfirmChangeMatrix";
import Matrix3Description from "./Matrix3Description";
import Matrix4Description from "./Matrix4Description";
import Matrix5Description from "./Matrix5Description";
import { allRiskMatrixFetch } from "../../redux/slices/allRiskMatrixSlice";
import { matrixUpdateField } from "../../redux/slices/updateMatrixSlice";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root:{
    marginLeft: 123,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 60,
  },
  matrixInput: {
    width: 200,
    marginBottom: 20,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  matrixCard: {
    width: 1000,
    height: 2000,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },
  radioButtons: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  radio: {
    // marginLeft: 50,
    marginRight: 50,
  },
  radioBtn: {
    '&$checked': {
      color: '#1071BA'
    }
  },
  checked: {},
  matrixTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  tableHead: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  tableCell: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  verticalBorderNone: {
    borderBottom: "none",
  },
  topCorner: {
    borderBottom: "1px solid black",
  },
  // tableDiv: {
  //   marginTop: 30,
  //   marginBottom: 40,
  // },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 59,
    width: 237,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const Matrix = () => {
  const classes = useStyles();
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedMatrix, setSelectedMatrix] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [updatedMatrix, setUpdatedMatrix] = useState();
  const [loader, setLoader] = useState(false);

  const matrixSelected = useSelector(
    (state: RootState) => state.user.risk_matrix
  );

  const profileUpdated = useSelector(
    (state: RootState) => state.updatemyProfile
  );

  const riskMatrix3_3 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_3_3
  );

  const riskMatrix4_4 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_4_4
  );

  const riskMatrix5_5 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_5_5
  );

  // console.log(riskMatrix3_3,"yyyyyyyy")

  useEffect(() => {
    matrixSelected ? setLoader(true) : setLoader(false);
    dispatch(riskMatrixFetch({ access_token }))
      .then(unwrapResult)
      .then((response: any) => {
        const currentMatrix = response.matrix;
        setSelectedMatrix(currentMatrix);
        setLoader(false);
      })
      .catch((error) => {});

    dispatch(allRiskMatrixFetch({ access_token }));
  }, []);

  useEffect(() => {
    dispatch(allRiskMatrixFetch({ access_token }));
  }, [selectedMatrix]);

  const handleChangeMatrix = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMatrix((event.target as HTMLInputElement).value);
  };

  const handleConfirm = () => {
    setOpenModal(true);
  };

  const handleUpdateMatrix = () => {
    const payload = {
      access_token: access_token,
      type: selectedMatrix,
      matrix: updatedMatrix,
    };
    if (payload) {
      dispatch(matrixUpdateField(payload))
        .then(unwrapResult)
        .then((response: any) => {
          enqueueSnackbar("Risk matrix criteria has been updated.", {
            autoHideDuration: 2000,
            variant: "success",
          });
          dispatch(allRiskMatrixFetch({ access_token }));
        })
        .catch((error) => {
          enqueueSnackbar(
            "Unable to update matrix criteria. Please try again",
            {
              autoHideDuration: 2000,
              variant: "error",
            }
          );
        });
    }
  };

  const handleSubmitMatrix = () => {
    const payload = {
      access_token: access_token,
      matrix: selectedMatrix,
    };
    if (selectedMatrix && payload) {
      // console.log(payload,"jjjjj")
      dispatch(riskMatrixPush(payload))
        .then(unwrapResult)
        .then((response: any) => {
          enqueueSnackbar("Risk matrix has been updated.", {
            autoHideDuration: 2000,
            variant: "success",
          });
          dispatch(riskMatrixFetch({ access_token }));
        })
        .catch((error) => {
          enqueueSnackbar("Unable to update matrix. Please try again", {
            autoHideDuration: 2000,
            variant: "error",
          });
        });
    }
  };

  return (
    <>
      <>
        {loader ? (
          <CircularProgress />
        ) : (
          <div className={classes.root}>
            <Typography className={classes.profileTitle}>
              Choose Matrix
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="matrix"
                name="matrix"
                value={selectedMatrix}
                className={classes.radioButtons}
                onChange={handleChangeMatrix}
              >
                <FormControlLabel
                  value="json_3_3"
                  control={<Radio classes={{root: classes.radioBtn, checked: classes.checked}}/>}
                  label="3 x 3"
                  className={classes.radio}
                />
                <FormControlLabel
                  value="json_4_4"
                  control={<Radio classes={{root: classes.radioBtn, checked: classes.checked}}/>}
                  label="4 x 4"
                  className={classes.radio}
                />
                <FormControlLabel
                  value="json_5_5"
                  control={<Radio classes={{root: classes.radioBtn, checked: classes.checked}}/>}
                  label="5 x 5"
                  className={classes.radio}
                />
              </RadioGroup>
            </FormControl>
            <>
              {selectedMatrix === "json_3_3" && (
                <Matrix3by3
                  matrix3_3={riskMatrix3_3}
                  setUpdatedMatrix={setUpdatedMatrix}
                />
              )}
              {selectedMatrix === "json_4_4" && (
                <Matrix4by4
                  matrix4_4={riskMatrix4_4}
                  setUpdatedMatrix={setUpdatedMatrix}
                />
              )}
              {selectedMatrix === "json_5_5" && (
                <Matrix5by5
                  matrix5_5={riskMatrix5_5}
                  setUpdatedMatrix={setUpdatedMatrix}
                />
              )}
            </>
            <ColorButton
              variant="contained"
              color="primary"
              disabled={selectedMatrix ? false : true}
              onClick={handleConfirm}
            >
              SUBMIT
            </ColorButton>
            <>
              {selectedMatrix === "json_3_3" && (
                <Matrix3Description matrix3_3={riskMatrix3_3} />
              )}
              {selectedMatrix === "json_4_4" && (
                <Matrix4Description matrix4_4={riskMatrix4_4} />
              )}
              {selectedMatrix === "json_5_5" && (
                <Matrix5Description matrix5_5={riskMatrix5_5} />
              )}
            </>
          </div>
        )}
      </>
      <ConfirmChangeMatrix
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleSubmitMatrix={handleSubmitMatrix}
        handleUpdateMatrix={handleUpdateMatrix}
      />
    </>
  );
};

export default Matrix;
