import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRiskGroup } from "../../api/risk/riskGroup";

export const riskGroupFetch = createAsyncThunk(
  "risk/riskGroup",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getRiskGroup(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface groupList {
  id: number;
  name: string;
}

export type initialStateGroupList = {
  allRiskGroups: Array<groupList>;
};

const initialState: initialStateGroupList = {
  allRiskGroups: [],
};

const riskGroupList = createSlice({
  name: "riskGroup",
  initialState,
  reducers: {
    resetRiskGroupList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(riskGroupFetch.fulfilled, (state, { payload }) => {
      state.allRiskGroups = [];
      // for (let i = 0; i < payload.length; i++) {
      //   state.allRiskGroups.push({
      //     id: payload[i].id,
      //     name: payload[i].name,
      //   });
      // }
      payload.forEach((value: any) => {
        state.allRiskGroups.push({
          id: value.id,
          name: value.name,
        });
      });
    });
  },
});

export const { resetRiskGroupList } = riskGroupList.actions;

export default riskGroupList.reducer;
