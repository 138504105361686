import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllDomains } from "../../api/statement/getAllDomains";

export const getAllDomainsRequest = createAsyncThunk(
  "/rm/get/user_domain/",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getAllDomains(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateDomains = {
  get_all_domains: Array<Object>;
};

const initialState: initialStateDomains = {
  get_all_domains: [],
};

const getDomainsData = createSlice({
  name: "allDomains",
  initialState,
  reducers: {
    resetStatementData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDomainsRequest.fulfilled, (state, { payload }) => {
        state.get_all_domains = payload;
    });
  },
});

export const { resetStatementData } = getDomainsData.actions;

export default getDomainsData.reducer;