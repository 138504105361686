import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { oneTimeResetPassword } from "../../api/auth/oneTimeResetPassword";

export const otResetPassword = createAsyncThunk(
  "oneTimeresetPassword",
  async ({ uuid }: any, thunkAPI) => {
    try {
      return await oneTimeResetPassword(uuid);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialState = {
    status: string;
  };
  
  const initialState: initialState = {
    status: "",
  };
  
  const resetPasswordOT = createSlice({
    name: "oneTimeResetPassword",
    initialState,
    reducers: {
      resetoneTimeResetPassword: () => initialState,
    },
    extraReducers: (builder) => {
      builder.addCase(otResetPassword.fulfilled, (state, { payload }) => {
        state.status = payload.status;
      });
    },
  });
  
  export const { resetoneTimeResetPassword } = resetPasswordOT.actions;
  
  export default resetPasswordOT.reducer;